import MyAvatar from "@components/admin/MyAvatar";
import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ShowMore from "./ShowMore";
import SearchItem from "./SearchItem.";

export default function CommunitiesList({ communities, page, totalPages, onShowMore }) {
  const navigate = useNavigate();
  const communityInfo = (id) => {
    navigate(`/community/${id}`);
  };
  return (
    <div>
      {communities?.length === 0 && (
        <Typography className="mt-2 text-center py-8">
          No communities found
        </Typography>
      )}
      {communities?.length > 0 && (
        <div className="pt-2 pb-8">
          {communities?.map((community, key) => (
            <SearchItem
              key={key}
              onClick={() => communityInfo(community.id)}
            >
              <MyAvatar
                key={key}
                name={community.name}
                src={community?.icon?.avatarUrl}
              />
            </SearchItem>
          ))}
          <ShowMore page={page} totalPages={totalPages} onClick={onShowMore} />
        </div>
      )}
    </div>
  );
}
