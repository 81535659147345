import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddMediaResource from "../../components/resource/AddMediaResource";
import Layout from "@Layout";
import Title from "@components/Title";
import Loader from "@components/Loader";
import axios from "@MyAxios";
import AddTextResource from "@components/resource/AddTextResource";

export default function EditResource() {
  const { id } = useParams();
  const [resource, setResource] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`/resource/${id}`)
      .then((res) => {
        setResource(res.data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  if (loading) return <Loader />;
  return (
    <Layout>
      <Title>Edit Resource</Title>
      {resource?.isMedia ? (
        <AddMediaResource
          isUpdate
          resource={resource}
          setResource={setResource}
        />
      ) : (
        <AddTextResource
          isUpdate
          resource={resource}
          setResource={setResource}
        />
      )}
    </Layout>
  );
}
