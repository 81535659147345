import React, { useEffect, useState } from 'react'
import { Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalComAdminAccessLevels, setGlobalCommunityAdminAccessLevels, updateGlobalComAdminAccessLevels } from '@redux/communitySlice';
import Loader from '@components/Loader';

function CommunityAdminAccessForm() {
  const dispatch = useDispatch();

  const { globalCommunityAdminAccessLevels } = useSelector(state => state.community);
  
  const [loading, setLoading] = useState(true);
  const [accessLevels, setAccessLevels] = useState();

  const handleSubmit = () => {
    dispatch(updateGlobalComAdminAccessLevels({
      accessLevels
    }));
  }

  const handleAccessLevelUpdate = (field, checked) => {
    setAccessLevels({
      ...accessLevels,
      [field]: checked,
    });
  }

  const fetchData = () => {
    setLoading(true);
    dispatch(getGlobalComAdminAccessLevels())
      .then(() => setLoading(false));
  }

  useEffect(() => {
    if(globalCommunityAdminAccessLevels)
      setAccessLevels({
        canDeleteCommunityPost: globalCommunityAdminAccessLevels?.canDeleteCommunityPost,
        canDeleteCommunityGroup: globalCommunityAdminAccessLevels?.canDeleteCommunityGroup,
        canRemoveCommunityUser: globalCommunityAdminAccessLevels?.canRemoveCommunityUser
      });
  }, [globalCommunityAdminAccessLevels])

  useEffect(() => {
    fetchData();

    return () => {
      dispatch(setGlobalCommunityAdminAccessLevels());
    }
  }, [])

  return (
    <div className="grow px-12 pt-6 overflow-x-auto">
      <Typography className="font-bold mb-6" variant="h6">
        Community Admin Access Levels
      </Typography>

      {(() => {
        if(loading)
          return <Loader />

        return (
          <>
            <FormGroup>
              <FormControlLabel
                label="Allowed to delete community groups' posts"
                control={
                  <Checkbox
                    checked={accessLevels?.canDeleteCommunityPost}
                    onChange={e => handleAccessLevelUpdate('canDeleteCommunityPost', e.target.checked)}
                  />
                }
              />
              <FormControlLabel
                label="Allowed to remove community members"
                control={
                  <Checkbox
                    checked={accessLevels?.canRemoveCommunityUser}
                    onChange={e => handleAccessLevelUpdate('canRemoveCommunityUser', e.target.checked)}
                  />
                }
              />
              <FormControlLabel
                label="Allowed to delete community groups"
                control={
                  <Checkbox
                    checked={accessLevels?.canDeleteCommunityGroup}
                    onChange={e => handleAccessLevelUpdate('canDeleteCommunityGroup', e.target.checked)}
                  />
                }
              />
            </FormGroup>

            {/* <pre>
              {JSON.stringify(accessLevels, null, 2)}
            </pre> */}

            <div className='mt-6'>
              <Button variant='contained' color='primary' onClick={handleSubmit}>
                Update
              </Button>
            </div>
          </>
        )
      })()}

    </div>
  )
}

export default CommunityAdminAccessForm;