import { Typography } from "@mui/material";

export default function PopperList({ items }) {
  return (
    <div className="z-50 py-4 pl-2 pr-4 flex flex-col gap-1 rounded-lg border-b bg-slate-50 shadow-md">
      {items.map((item, key) => (
        !item.hidden && (
          <div
            key={key}
            onClick={item.onClick}
            className="z-50 cursor-pointer hover:bg-slate-200"
          >
            <Typography fontSize={14} className="py-1 pl-2 pr-6">
              {item.label}
            </Typography>
          </div>
        )
      ))}
    </div>
  );
}
