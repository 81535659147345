import { styled } from "@mui/system";
import { Button as MUIButton } from "@mui/material";

const Button = styled(MUIButton)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(32, 3, 3, 0.25)",
  width: "125px",
  height: "40px",
  marginTop: 30,
  lineHeight: "13px",
}));

export default Button;
