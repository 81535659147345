import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: "30px",
  padding: 60,
  backgroundColor: theme.palette.background.paper,
}));
