import { Modal } from "@mui/material";
import { ModalBox } from "@components/admin/StyledComponents";
import MyAvatar from "@components/admin/MyAvatar";
import { formatDate } from "@helpers";
import DetailRow from "@components/admin/DetailRow";
import { useEffect, useState } from "react";
import { Button } from "@hooks/useActionFilters";

export default function FeedbackDetail({
  feedback,
  setFeedback,
  actionButtons,
}) {
  const [open, setOpen] = useState(false);
  const user = feedback?.user;
  useEffect(() => {
    if (feedback) setOpen(true);
  }, [feedback]);
  if (!feedback) return <div></div>;
  return (
    <Modal
      open={open}
      onClose={() => {
        setFeedback(null);
        setOpen(false);
      }}
    >
      <ModalBox className="shadow-md overflow-auto">
        <div className="flex gap-6">
          <MyAvatar
            src={user?.media?.avatarUrl}
            name={user?.firstName + " " + user?.lastName}
            subtext={`@${user?.userName}`}
            textProps={{ className: "font-bold text-gray-600" }}
          />
        </div>
        <div>
          <table className="w-full text-left text-sm mt-5">
            <tbody>
              <DetailRow label="Email:" value={user?.emailAddress} />
              <DetailRow label="Signin Option:" value={user?.provider || "Email"} />
              <DetailRow
                label="Registration Date:"
                value={formatDate(user?.createdAt)}
              />

              <DetailRow
                label="Feedback Date:"
                value={formatDate(feedback?.createdAt)}
              />
              <DetailRow label="Status:" value={feedback.status} />
              <DetailRow label="Feedback Message:" value={feedback?.detail} />
              {feedback.response && (
                <DetailRow label="Feedback Reply:" value={feedback?.response} />
              )}
            </tbody>
          </table>
          <div className="flex gap-2 mt-8 justify-end">
            {actionButtons?.map((button, index) => (
              <Button variant="contained" key={index} {...button} />
            ))}
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
}
