import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import apiClient from "../../apiClient";
import Ellipses from "@images/ellipses.svg";
import AdminLoginImage from "@images/adminLoginImg.svg";
import MenteeIcon from "@images/logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoggedIn } from "../../redux/userSlice";
import { setUserRecord } from "@redux/userSlice";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
  borderTop: {
    borderTop: `2px solid #000`,
    width: "30%",
  },
}));

const AdminLogin = (res) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [, setHeight] = useState(window.innerHeight);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Form submission handler
  const onSubmit = (data) => {
    apiClient
      .login(data)
      .then((data) => {
        const token = data.token;
        localStorage.setItem("token", token);
        dispatch(setIsLoggedIn(true));
        dispatch(setUserRecord(data.user));

        navigate("/admin/users");
      })
      .catch(() => {});
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
    });
  }, []);

  return (
    <div className="mr-4 flex w-full flex-row gap-4">
      <div className="relative hidden xl:block">
        <img
          className="h-screen object-contain"
          src={Ellipses}
          alt="ellipses"
        />
        <img
          style={{ right: "-30%" }}
          className="absolute top-1/2  -translate-y-1/2 transform"
          src={AdminLoginImage}
          alt="Admin Login"
        />
      </div>
      <div className="m-auto flex flex-col gap-4 xl:w-3/12">
        <div className="mt-20">
          <img
            src={MenteeIcon}
            style={{ height: "40px" }}
            className="mx-auto mb-7"
            alt="mentee"
          />
          <Typography
            variant="h6"
            className={clsx(classes.textColor, "text-center")}
          >
            Your values
          </Typography>
          <Typography
            variant="h6"
            className={clsx(classes.textColor, "text-center")}
          >
            Your people
          </Typography>
          <Typography
            variant="h6"
            className={clsx(classes.textColor, "text-center")}
          >
            Your community
          </Typography>{" "}
          <Typography
            variant="h6"
            fontWeight={"bold"}
            className={clsx(classes.textColor, "text-center")}
          >
            Your Serkohls
          </Typography>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col mt-5 gap-4"
        >
          <div className="input-wrapper flex flex-col">
            <input
              className={clsx(classes.border, "rounded-md p-3")}
              placeholder="Username or Email"
              type="text"
              {...register("userName", {
                required: "Username is required",
                minLength: {
                  value: 3,
                  message: "Username must be at least 3 characters",
                },
              })}
            />
            {errors.userName && (
              <p className="text-xs italic text-red-500">
                {errors.userName.message}
              </p>
            )}
          </div>

          <div className="input-wrapper flex flex-col">
            <input
              placeholder="Password"
              className={clsx(classes.border, "rounded-md p-3")}
              type="password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              })}
            />
            {errors.password && (
              <p className="text-xs italic text-red-500">
                {errors.password.message}
              </p>
            )}
          </div>
          {res.message && (
            <p className="text-xs italic text-red-500">{res.message}</p>
          )}

          <div className="input-wrapper mt-4">
            <button
              type="submit"
              style={{ borderRadius: "10px" }}
              className={clsx(classes.button, "mt-4 w-full p-2")}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
