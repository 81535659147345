import { Modal, Button, Checkbox } from "@mui/material";
import { ModalBox } from "@components/admin/StyledComponents";
import { useForm } from "react-hook-form";
import { Avatar, Typography } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

import camera from "@images/camera.svg";
import { useEffect, useRef, useState } from "react";
import api from "@apiClient";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
  },
}));

export default function CommunityForm({ open, setOpen, setReload }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const classes = useStyles();
  const fileInput = useRef(null);

  const clearFormData = () => {
    setValue("name", "");
    setValue("description", "");
    setImage(null);
    setFile(null);
  };

  useEffect(() => {
    clearFormData();
  }, [open]);

  const onSubmit = (data) => {
    setOpen(false);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("isPrivate", data.isPrivate);
    formData.append("file", file);
    api
      .addCommunity(formData)
      .then(() => {
        toast.success("Community added successfully");
        setReload((r) => r + 1);
      })
      .catch(() => {});
  };

  const onImageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalBox sx={{ p: 4 }} className="rounded-lg shadow-md">
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="flex flex-col gap-2"
        >
          <div className="flex items-center mb-6">
            <div
              className=" relative"
              onClick={() => fileInput.current.click()}
            >
              <Avatar
                className=" relative h-20 w-20 object-contain"
                src={image}
              />
              <img
                className=" absolute bottom-0 right-0"
                src={camera}
                alt="Camera"
              />
            </div>
            <Typography className="ml-4">Community icon (Optional)</Typography>
          </div>
          <input
            onChange={onImageChange}
            ref={fileInput}
            type="file"
            className="hidden"
          />
          <div className="input-wrapper flex flex-col">
            <label className="mb-2 font-medium">Community Name:</label>
            <input
              className={clsx(classes.border, "rounded-md p-3")}
              placeholder="Name"
              type="text"
              {...register("name", {
                required: "Name is required",
              })}
            />
            {errors.title && (
              <p className="text-xs italic text-red-500">
                {errors.title.message}
              </p>
            )}
          </div>

          <div className="input-wrapper flex flex-col">
            <label className="mb-2 font-medium">Community Description:</label>
            <textarea
              className={clsx(classes.border, "h-32 rounded-md p-3")}
              placeholder="Description"
              {...register("description", {
                required: "Description is required",
              })}
            />
            {errors.description && (
              <p className="text-xs italic text-red-500">
                {errors.description.message}
              </p>
            )}
          </div>

          <div className="flex items-center">
            <Checkbox
              color="success"
              {...register('isPrivate')}
            />
            <Typography className={clsx(classes.textGrey)}>
              Make private
            </Typography>
          </div>

          <div className="flex w-full justify-end gap-2 mt-6">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="submit" variant="contained">
              Add
            </Button>
          </div>
        </form>
      </ModalBox>
    </Modal>
  );
}
