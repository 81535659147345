import React, { useEffect, useState } from "react";
import { Avatar, Typography } from "@mui/material";
import Post from "./Post";
import { useNavigate } from "react-router-dom";
import addPost from "../assets/img/addPost2.svg";
import { CREATE_POST, GET_GROUP_POSTS } from "@constants/api";
import axios from "@MyAxios";
import { useDispatch, useSelector } from "react-redux";
import {
  addToGroups,
  resetPost,
  setIsGroupOnlyPost,
} from "@redux/postSlice";
import ShowMore from "@components/ShowMore";
import { removeCommunityGroupPost } from "@redux/communitySlice";
import { toast } from "react-toastify";

function GroupStreams({ group }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const groupId = group?.id;

  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  const { userRecord } = useSelector((state) => state.user);
  const post = useSelector((state) => state.post);

  const getPosts = async () => {
    try {
      if (!groupId) return;
      const res = await axios.get(`${GET_GROUP_POSTS}/${groupId}`, {
        params: { page, limit: 15 },
      });
      setPosts([ ...posts, ...res.data.posts ]);
      setTotalPages(res.data.totalPages);
    } catch (error) {}
  };

  useEffect(() => {
    getPosts();
  }, [groupId, page]);

  const onCreateGroupPost = () => {
    if(group?.disabledAt > 0)
      return toast.error('Group is disabled');

    if(group?.community?.disabledAt > 0)
      return toast.error('Group community is disabled');

    dispatch(resetPost());
    dispatch(setIsGroupOnlyPost(true));
    dispatch(addToGroups(groupId));
    navigate(CREATE_POST);
  };

  const handleRemoveCommunityGroupPost = (postId) => {
    if(window.confirm('Are you sure you want to remove this post from group?')) {
      dispatch(removeCommunityGroupPost({
        data: {
          postId,
          groupId,
          communityId: group?.community?.id
        },
        onSuccess: () => {
          setPosts([]);
          getPosts();
        }
      }))
    }
  }

  const userCanRemovePostsAsCommunityAdmin = group?.community?.adminAccessLevels?.canDeleteCommunityPost && group?.community?.admin === userRecord?.id;
  const communityOrGroupDisabled = group?.disabledAt > 0 || group?.community?.disabledAt > 0;

  return (
    <div>
      {posts.length === 0 && (
        <Typography className="mt-4">No streams yet...</Typography>
      )}
      {posts?.map((item) => (
        <div key={item.id}>
          <Post
            comment
            post={item}
            readOnly={communityOrGroupDisabled}
            canRemoveCommunityGroupPost={userCanRemovePostsAsCommunityAdmin}
            handleRemoveCommunityGroupPost={handleRemoveCommunityGroupPost}
          />
        </div>
      ))}

      {page < totalPages && (
        <div
          onClick={() => {
            setPage(page + 1);
          }}
          className="cursor-pointer my-2 text-center mx-4"
        >
          <ShowMore>Show More</ShowMore>
        </div>
      )}
      {(group?.members?.map((x) => x.id).includes(userRecord.id) ||
        group?.admin?.id === userRecord.id) && (
        <div
          onClick={onCreateGroupPost}
          className="fixed bottom-10 right-5 md:right-1/4 cursor-pointer"
        >
          <Avatar className="h-12 w-12 md:h-20 md:w-20" src={addPost} />
        </div>
      )}
    </div>
  );
}

export default GroupStreams;
