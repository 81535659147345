import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import linkSent from "../../assets/img/linkSent.svg";
import wave from "../../assets/img/wave.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "@MyAxios";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const LoginVerification = (res) => {
  const classes = useStyles();
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  console.log(searchParams.get("userName"));

  const resendEmail = () => {
    axios
      .post("/resend-verification-email", {
        userName: searchParams.get("userName"),
      })
      .then(() => toast.success("Email resent successfully"))
      .catch(() => {});
  };
  return (
    <div>
      <div className="m-auto flex w-5/6 flex-col gap-4 md:w-3/12">
        <div className="mt-20">
          <Typography
            variant="h4"
            className={clsx(classes.textColor, " text-center font-extrabold")}
          >
            Verify Your Email
          </Typography>
        </div>
        <div className="mx-auto">
          <img src={linkSent} alt="Link Sent" />
        </div>
        <Typography variant="p" className=" mt-3 text-sm text-center">
          Kindly verify your account by clicking the link sent to your email.
          You have not completed the verification process yet. We have resent
          you the verification link.
        </Typography>
        <div
          className="mt-32 text-center"
          onClick={() => navigation("/login-details")}
        >
          Didn't get link?{" "}
          <Typography
            onClick={resendEmail}
            className="cursor-pointer inline"
            color="primary"
          >
            Resend
          </Typography>
        </div>
        <div className={clsx(classes.bottomImg, "block sm:hidden")}>
          <img src={wave} alt="Wave" className="z-0 w-full" />
        </div>
      </div>
    </div>
  );
};

export default LoginVerification;
