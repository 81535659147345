import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import { getCommunityGroups, removeCommunityGroup, setCommunityGroups } from '@redux/communitySlice';
import GroupListingItem from './GroupListingItem';
import RemoveIcon from "@images/delete.svg";
import { Dialog, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import JoinGroupIcon from "@images/joinGroup.svg";
import axios from '@MyAxios';
import { REQUEST_GROUP_JOIN } from '@constants/api';

function CommunityGroups({ communityId }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState();

  const { community, communityGroups, maxPage } = useSelector(state => state.community);
  const { userRecord } = useSelector((state) => state.user);

  const fetchMoreDivCallback = useCallback((fetchMoreDiv) => {
    if(fetchMoreDiv) {
      let observer = new IntersectionObserver((entries, observer) => {
        if(entries[0].isIntersecting && !loading) {
          setPage(page + 1);
          observer.unobserve(fetchMoreDiv);
        }
      }, { threshold: 1 });
      observer.observe(fetchMoreDiv);
    }
  }, [loading])

  const handleCloseDialog = () => {
    setSelectedGroup();
  }

  const handleRemoveCommunityGroup = () => {
    if(!selectedGroup)
      return toast.error('No group selected');

    dispatch(removeCommunityGroup({
      data: {
        groupId: selectedGroup?.id,
        communityId: community?.id
      },
      onSuccess: () => {
        dispatch(setCommunityGroups());
        setPage(0);
        handleCloseDialog();
      },
    }));
  }

  const handleJoinGroup = () => {
    if(!selectedGroup)
      return toast.error('No group selected');

    axios
      .post(REQUEST_GROUP_JOIN, { fromGroup: selectedGroup?.id })
      .then((res) => {
        toast.success("Request sent successfully");
        dispatch(setCommunityGroups());
        setPage(0);
      })
      .catch(() => {})
      .finally(() => {
        handleCloseDialog()
      });
  }

  const fetchData = () => {
    setLoading(true);
    dispatch(getCommunityGroups({ communityId, page }))  
      .then(() => setLoading(false));
  }

  useEffect(() => {
    if(communityId && page == 0)
      fetchData();

    if(page > 0 && page + 1 <= maxPage)
      fetchData();
  }, [communityId, page])

  useEffect(() => {
    dispatch(setCommunityGroups());
  }, [])

  const memberActions = [
    {
      text: 'Join group',
      icon: JoinGroupIcon,
      onClick: handleJoinGroup,
      show: !userRecord?.groups?.map((x) => x.id).includes(selectedGroup?.id) && selectedGroup?.admin !== userRecord?.id,
    },
    {
      text: 'Remove from community',
      icon: RemoveIcon,
      onClick: handleRemoveCommunityGroup,
      show: community?.adminAccessLevels?.canDeleteCommunityGroup && community?.admin === userRecord?.id,
    }
  ];

  const showActions = (group) => 
    (!userRecord?.groups?.map((x) => x.id).includes(group?.id) && group?.admin !== userRecord?.id)
    || (community?.adminAccessLevels?.canDeleteCommunityGroup && community?.admin === userRecord?.id);


  if(!communityId)
    return;

  return (
    <div className='max-h-[65vh] overflow-y-auto pb-1 scrollbar-hidden'>
      {communityGroups?.map(group => (
        <GroupListingItem
          group={group}
          setSelectedGroup={setSelectedGroup}
          showOptions={showActions(group)}
          isAdmin={community?.admin === userRecord?.id}
        />
      ))}
      {loading && (<Loader />)}
      <div ref={fetchMoreDivCallback} />

      <Dialog
        open={Boolean(selectedGroup)}
        onClose={handleCloseDialog}
        PaperProps={{
          className: 'w-3/4 sm:w-1/2 max-w-[350px]'
        }}
      >
        <div className='p-2'>
          {!Boolean(memberActions.filter(action => action.show).length) && (
            <Typography>
              No actions available
            </Typography>
          )}
          {memberActions.map((action, key) => (
            action.show && (
              <div
                key={key}
                onClick={action.onClick}
                className="flex py-2 text-sm cursor-pointer"
              >
                <img className="mr-4 w-5 h-5" src={action.icon} alt={action.text} />
                <Typography>{action.text}</Typography>
              </div>
            )
          ))}
        </div>
      </Dialog>
    </div>
  )
}

export default CommunityGroups;