import NavBar from "@components/admin/NavBar";
import "./Admin.css";
export default function AdminLayout({ children }) {
  return (
    <div className="flex h-screen flex-col">
      <div>
        <NavBar />
      </div>
      <div className="grow">{children}</div>
    </div>
  );
}
