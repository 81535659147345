import axiosCreator from "axios";
import { toast } from "react-toastify";

export let axios = axiosCreator.create({
  baseURL: process.env.REACT_APP_URL ?? "http://localhost:1337",
});
let navigator = null;
export const setNavigator = (_navigator) => {
  navigator = _navigator;
};
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("Error occurred in apiClient.js", error);
    const data = error.response.data;
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      if (navigator) navigator("/admin/login", { replace: true });
    }
    if (data.type === "info") toast.info(`${data.message}`);
    else toast.error(`${data.message}`);
    return Promise.reject(error);
  }
);

class APIFactory {
  static getModel(url) {
    return async ({ page, limit, query, sortOptions, filters, ...params }) => {
      if (page < 1)
        page = 1;

      const res = await axios.get(url, {
        params: { page, limit, query, sortOptions, filters, ...params },
      });
      return res.data;
    };
  }

  static postToModel(url) {
    return async (data) => {
      const res = await axios.post(url, data);
      return res.data;
    };
  }
}

const api = {
  //AUTH
  login: APIFactory.postToModel("/login"),
  // USER FUNCTIONS
  getUsers: APIFactory.getModel("/admin/users/all"),
  deleteUsers: APIFactory.postToModel("/admin/users/delete"),
  disableUsers: APIFactory.postToModel("/admin/users/disable"),
  reinstateUsers: APIFactory.postToModel("/admin/users/reinstate"),
  // COMMUNITY FUNCTIONS
  getCommunities: APIFactory.getModel("/admin/communities/all"),
  getCommunityUsers: APIFactory.getModel("/admin/community/paginated-users"),
  addCommunity: APIFactory.postToModel("/admin/communities"),
  updateCommunity: APIFactory.postToModel("/admin/communities/update"),
  deleteCommunities: APIFactory.postToModel("/admin/communities/delete"),
  disableCommunities: APIFactory.postToModel("/admin/communities/disable"),
  reinstateCommunities: APIFactory.postToModel("/admin/communities/reinstate"),
  assignAdminToCommunity: APIFactory.postToModel("/admin/communities/assign-admin"),
  inviteUserToCommunity: APIFactory.postToModel("/admin/communities/invite-user"),
  removeMemberFromCommunity: APIFactory.postToModel("/admin/communities/remove-member"),
  getCommunityDetail: async (id) => {
    const res = await axios.get(`/admin/communities/${id}`);
    return res.data;
  },
  // GROUP FUNCTIONS
  getGroups: APIFactory.getModel("/admin/groups/all"),
  deleteGroups: APIFactory.postToModel("/admin/groups/delete"),
  disableGroups: APIFactory.postToModel("/admin/groups/disable"),
  reinstateGroups: APIFactory.postToModel("/admin/groups/reinstate"),

  // REPORT FUCNTIONS
  getPostReports: APIFactory.getModel("/admin/reports/post"),
  getGroupReports: APIFactory.getModel("/admin/reports/group"),
  getUserReports: APIFactory.getModel("/admin/reports/user"),
  getCommentReports: APIFactory.getModel("/admin/reports/comment"),
  getMessageReports: APIFactory.getModel("/admin/reports/message"),
  resolveReports: APIFactory.postToModel("/admin/reports/resolve"),
  sendWarning: APIFactory.postToModel("/admin/reports/warn"),
  // POST FUNCTIONS
  deletePosts: APIFactory.postToModel("/admin/posts/delete"),

  // COMMENT FUNCTIONS
  deleteComments: APIFactory.postToModel("/admin/comments/delete"),

  // FEEBACK FUNCTIONS
  getFeedbacks: APIFactory.getModel("/admin/feedbacks/all"),
  resolveFeedbacks: APIFactory.postToModel("/admin/feedbacks/resolve"),
  respondToFeedback: APIFactory.postToModel("/admin/feedbacks/respond"),

  // TAG FUNCTIONS
  updateTags: APIFactory.postToModel("/admin/tags/update"),
  getTagsByCommunity: async (communityId) => {
    const res = await axios.get(`/admin/tags?communityId=${communityId}`);
    return res.data;
  },
};
export default api;
