import { CircularProgress } from "@mui/material";
import { set, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import axios from "../../MyAxios";
import { GROUP_ADD_RESOURCES } from "../../constants/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
    borderColor: "#887E7E",
  },
  borderGreen: {
    borderWidth: 1,
    borderColor: "#51CDA0",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

const AddTextResource = ({ groupId, isUpdate, resource, setResource }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("title", isUpdate ? resource?.title : "");
    setValue("description", isUpdate ? resource?.description : "");
    setValue("link", isUpdate ? resource?.link : "");
  }, [setValue, isUpdate, resource]);

  const onLinkSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("link", data.link);
      formData.append("isMedia", false);
      formData.append("groupId", groupId);
      if (isUpdate) {
        formData.append("id", resource?.id);
        await axios.post("/resource/edit", formData);
        toast.success("Updated Successfully!");
      } else {
        await axios.post(GROUP_ADD_RESOURCES, formData);
        toast.success("Added Successfully!");
      }
      setLoading(false);
      navigate(-1);
    } catch (error) {}
  };

  return (
    <div className="m-auto flex mt-8 w-5/6 flex-col gap-4">
      <form
        onSubmit={handleSubmit(onLinkSubmit)}
        encType="multipart/form-data"
        className="flex flex-col gap-2"
      >
        <div className="input-wrapper flex flex-col">
          <input
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="Title"
            type="text"
            {...register("title", {
              required: "Title is required",
            })}
          />
          {errors.title && (
            <p className="text-xs italic text-red-500">
              {errors.title.message}
            </p>
          )}
        </div>

        <div className="input-wrapper flex flex-col">
          <textarea
            className={clsx(classes.border, "h-32 rounded-md p-3")}
            placeholder="Add Description"
            {...register("description", {
              required: "Description is required",
            })}
          />
          {errors.description && (
            <p className="text-xs italic text-red-500">
              {errors.description.message}
            </p>
          )}
        </div>

        <div className="input-wrapper flex flex-col">
          <textarea
            className={clsx(classes.border, "h-32 rounded-md p-3")}
            placeholder="Url"
            {...register("link", {
              validate: (value) => {
                if (!value && value === "") return true;
                else if (
                  value.startsWith("http") ||
                  value.startsWith("https") ||
                  value.startsWith("www")
                )
                  return true;
                else return "Invalid URL";
              },
            })}
          />
          {errors.link && (
            <p className="text-xs italic text-red-500">{errors.link.message}</p>
          )}
        </div>

        <div className=" flex items-end justify-end">
          <button
            type="submit"
            className={clsx(classes.button, "mt-5 w-24 rounded-3xl p-2 px-6")}
          >
            {loading ? (
              <CircularProgress size={"20px"} color="inherit" />
            ) : isUpdate ? (
              "Update"
            ) : (
              "Add"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTextResource;
