import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CHECK_EMAIL, SIGNUP, USERNAME } from "../../constants/api";
import Button from "../../components/Button";
import BottomText from "../../components/BottomText";
import axios from "../../MyAxios";
import { useNavigate } from "react-router-dom";
import mentee from "@images/logo.png";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E !important",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const SignupDetails = (res) => {
  const classes = useStyles();
  const navigation = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // Retrieve form data from localStorage and set state
    const storedFormData = JSON.parse(localStorage.getItem("formData")) || {};
    setValue("userName", user || storedFormData.userName);
    setValue("emailAddress", storedFormData.email || "");
    setValue("phoneNumber", storedFormData.phone || "");
    setValue("firstName", storedFormData.firstName || "");
    setValue("lastName", storedFormData.lastName || "");
    setValue("aliasOne", storedFormData.aliasOne || "");
    setValue("aliasTwo", storedFormData.aliasTwo || "");
    setValue("age", storedFormData.age || "");
    setValue("dateOfBirth", storedFormData.dateOfBirth || null);
  }, []);

  // Form submission handler
  const onSubmit = async (data) => {
    const formData = {
      userName: watch("userName"),
      phone: watch("phoneNumber"),
      firstName: watch("firstName"),
      lastName: watch("lastName"),
      aliasOne: watch("aliasOne"),
      aliasTwo: watch("aliasTwo"),
      email: watch("emailAddress"),
      age: watch("age"),
      dateOfBirth: watch("dateOfBirth"),
    };
    localStorage.setItem("formData", JSON.stringify(formData));

    try {
      const res = await axios.post(CHECK_EMAIL, data);
      console.log(res);
      navigation(SIGNUP);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
    }
  };
  const navigate = useNavigate();

  const validatePhoneNumber = (value) => {
    if (!value || value === "") return true; // Allow empty input (optional field)
    const phoneNumberDigits = value.replace(/\D/g, ""); // Remove non-digits
    if (!/^\d+$/.test(phoneNumberDigits)) {
      // Check if contains only digits
      return "Phone number must contain only digits";
    }
    if (phoneNumberDigits.length !== 10) {
      // Check if length is 10
      return "Phone number must be 10 digits";
    }
    return true; // Validation passed
  };

  return (
    <div className="m-auto flex w-5/6 flex-col gap-4 md:w-3/12">
      <div>
        <Typography
          variant="h4"
          className={clsx(classes.textColor, " text-center font-extrabold")}
        >
          Sign Up for
        </Typography>
        <img
          src={mentee}
          alt="mentee"
          className="mx-auto mb-2"
          style={{ height: "30px" }}
        />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        className="flex flex-col gap-2"
      >
        <div className=" flex flex-col">
          <label className="ml-1">
            <small>Email *</small>
          </label>
          <input
            className={clsx(
              classes.border,
              "rounded-md p-3",
              res.message ? "border-red-400" : ""
            )}
            placeholder="Email"
            type="email"
            {...register("emailAddress", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i,
                message: "Invalid email address",
              },
            })}
          />
          {errors.emailAddress && (
            <p className="text-xs italic text-red-500">
              {errors.emailAddress.message}
            </p>
          )}
        </div>

        <div className=" flex flex-col">
          <label className="ml-1">
            <small>First Name *</small>
          </label>
          <input
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="First Name"
            type="text"
            {...register("firstName", {
              required: "First Name is required",
              minLength: {
                value: 3,
                message: "First Name must be at least 3 characters",
              },
            })}
          />
          {errors.firstName && (
            <p className="text-xs italic text-red-500">
              {errors.firstName.message}
            </p>
          )}
        </div>

        <div className=" flex flex-col">
          <label className="ml-1">
            <small>Last Name *</small>
          </label>
          <input
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="Last Name"
            type="text"
            {...register("lastName", {
              required: "Last Name is required",
              minLength: {
                value: 3,
                message: "Last Name must be at least 3 characters",
              },
            })}
          />
          {errors.lastName && (
            <p className="text-xs italic text-red-500">
              {errors.lastName.message}
            </p>
          )}
        </div>
        <div className="input-wrapper flex flex-col">
          <label className="ml-1">
            <small>Date of Birth *</small>
          </label>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={watch('dateOfBirth')}
              onChange={(newDate) => setValue('dateOfBirth', newDate.format('MM/DD/YYYY'))}
              slotProps={{
                textField: {
                  InputProps: {
                    classes: {
                      notchedOutline: clsx(classes.border, "rounded-md p-3"),
                    },
                  }
                }
              }}
            />
          </LocalizationProvider>
          {errors.dateOfBirth && (
            <p className="text-xs italic text-red-500">{errors.dateOfBirth.message}</p>
          )}
        </div>

        <div className="input-wrapper flex flex-col">
          <label className="ml-1">
            <small>Phone Number</small>
          </label>
          <input
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="(XXX) XXX-XXXX"
            type="text"
            {...register("phoneNumber", {
              validate: validatePhoneNumber,
              required: false,
            })}
          />
          {errors.phoneNumber && (
            <p className="text-xs italic text-red-500">
              {errors.phoneNumber.message}
            </p>
          )}
        </div>

        <Button>Next</Button>
      </form>
    </div>
  );
};

export default SignupDetails;
