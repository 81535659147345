import React, { useCallback, useEffect, useState } from 'react'
import Layout from '@Layout';
import CommunityGroups from '@components/CommunityGroups';
import CommunityMembers from '@components/CommunityMembers';
import Loader from '@components/Loader';
import TabPane from '@components/TabPane';
import Title from '@components/Title';
import { trunc } from '@helpers';
import { Typography } from '@mui/material';
import { getCommunity, joinCommunity, setCommunityGroups, setCommunityMembers } from '@redux/communitySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { setCommunity } from '@redux/groupSlice';
import ShowMore from '@components/ShowMore';
import { toast } from 'react-toastify';

function CommunityPage() {
  const params = useParams();
  const dispatch = useDispatch();

  const { community } = useSelector(state => state.community);
  const { userRecord } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [showFullDesc, setShowFullDesc] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const tabs = ["Members", "Groups"];

  const activeTab = Number(
    searchParams.get("activeTab") === "null"
      ? "0"
      : searchParams.get("activeTab")
  );

  const setActiveTab = useCallback(
    (activeTab) => setSearchParams(() => ({ activeTab })),
    [setSearchParams]
  );

  const onTabChange = (index) => {
    if (index >= 0 && index < tabs.length)
      setActiveTab(index);
  }

  const handleJoinCommunity = () => {
    if(!community)
      return;

    if(community.disabledAt > 0)
      return toast.error('Community is disabled');

    dispatch(joinCommunity({
      communityId: community.id,
      onSuccess: () => window.location.reload()
    }))
  }

  const fetchData = () => {
    setLoading(true);
    dispatch(getCommunity(params?.id))  
      .then(() => setLoading(false));
  }

  const getCommunityName = () => {
    if(community) {
      if(community.disabledAt > 0)
        return `${community?.name} Community (Disabled)`;
      
      return `${community?.name} Community`;
    }

    return '...';
  }

  useEffect(() => {
    if(params?.id)
      fetchData();
  }, [params?.id])

  useEffect(() => {
    return () => {
      dispatch(setCommunity());
      dispatch(setCommunityMembers());
      dispatch(setCommunityGroups());
    }
  }, [])

  const userIsCommunityMember = userRecord?.joinedCommunities?.some(com => com.id == community?.id) || community?.admin === userRecord?.id;

  return (
    <Layout>
      <Title>{getCommunityName()}</Title>

      {(() => {
        if(loading)
          return <Loader />

        return (
          <>
            <div className='p-5'>
              {(userRecord && community && !userIsCommunityMember) && (
                <div className='flex justify-end'>
                  <div
                    onClick={handleJoinCommunity}
                    className="cursor-pointer text-center"
                  >
                    <ShowMore>Join</ShowMore>
                  </div>
                </div>
              )}

              <Typography className='font-bold mb-1'>
                Description
              </Typography>
              <Typography>
                {showFullDesc ? community?.description : trunc(community?.description, 200)}
              </Typography>

              {community?.description !== trunc(community?.description, 200) && (
                <>
                  {!showFullDesc && (
                    <Typography
                      color='primary.main'
                      onClick={() => setShowFullDesc(true)}
                      className='mt-3 cursor-pointer text-center'
                    >
                      Show More
                    </Typography>
                  )}
                  {showFullDesc && (
                    <Typography
                      color='primary.main'
                      onClick={() => setShowFullDesc(false)}
                      className='mt-3 cursor-pointer text-center'
                    >
                      Show Less
                    </Typography>
                  )}
                </>
              )}
            </div>

            <TabPane
              unfixed
              tabs={tabs}
              activeTab={activeTab}
              onTabChange={onTabChange}
            >
              <CommunityMembers communityId={community?.id} />
              <CommunityGroups communityId={community?.id} />
            </TabPane>
          </>
        )
      })()}
    </Layout>
  )
}

export default CommunityPage;