import Layout from "../../Layout";
import Title from "../../components/Title";
import TabPane from "../../components/TabPane";
import useLoggedIn from "../../hooks/useLoggedIn";
import SavedPosts from "@components/Saved/SavedPosts";
import SavedMessages from "@components/Saved/SavedMessages";
import SavedResources from "@components/Saved/SavedResources";

export default function Save() {
  useLoggedIn();

  const tabs = ["Posts", "Messages", "Resources"];
  return (
    <Layout>
      <div className=" fixed w-full md:w-1/2 mt-14 z-50">
        <Title back={"/"}>Saved Content</Title>
      </div>
      <div className=" mt-14 ">
        <TabPane tabs={tabs}>
          <div>
            <SavedPosts />
          </div>
          <div>
            <SavedMessages />
          </div>
          <div>
            <SavedResources />
          </div>
        </TabPane>
      </div>
    </Layout>
  );
}
