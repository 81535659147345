import React, { useEffect, useState } from 'react'
import LayoutEntrance from "../../LayoutEntrance";
import { Checkbox, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import mentee from "@images/logo.png";
import axios from '@MyAxios';
import { LINK_SENT, SIGNUP } from '@constants/api';
import { toast } from 'react-toastify';
import MyAvatar from '@components/admin/MyAvatar';
import Button from "../../components/Button";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const SelectSignupCommunities = () => {
  const classes = useStyles();
  const navigation = useNavigate();

  const [communities, setCommunities] = useState([]);
  const [communityInvitations, setCommunityInvitations] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);

  const handleSubmitCommunities = async() => {
    const parsedFormData = JSON.parse(localStorage.formData);

    let profileData = {
      userName: parsedFormData.userName,
      phoneNumber: parsedFormData.phone,
      firstName: parsedFormData.firstName,
      lastName: parsedFormData.lastName,
      aliasOne: parsedFormData.aliasOne,
      aliasTwo: parsedFormData.aliasTwo,
      emailAddress: parsedFormData.email,
      age: parsedFormData.age,
      dateOfBirth: parsedFormData.dateOfBirth,
      password: parsedFormData.password,
      selectedCommunities,
    }

    try {
      const res = await axios.post(SIGNUP, profileData);
      localStorage.removeItem("formData");
      navigation(LINK_SENT);

    } catch (error) {
      console.log(error);
    }
  }

  const handleSelectCommunity = (community) => {
    if(Boolean(selectedCommunities.find(comId => comId === community.id))) {

      if(Boolean(communityInvitations.find(comInv => comInv.community.id === community.id)) && !window.confirm('You have been invited to join this community. Are you sure you want to reject?'))
        return;
        
      setSelectedCommunities(selectedCommunities.filter(comId => comId !== community.id));

    } else {
      setSelectedCommunities([ ...selectedCommunities, community.id ]);
    }
  }

  const fetchCommunities = async() => {
    const parsedFormData = JSON.parse(localStorage.formData);

    axios.get('/get/all-communities', { params: { email: parsedFormData.email } })
      .then((res) => {
        setCommunities(res.data.communities);
        setCommunityInvitations(res.data.communityInvitations);

        setSelectedCommunities(
          res.data.communities
            .filter(com => com.name === 'General' || Boolean(res.data.communityInvitations.find(comInv => comInv.community.id === com.id)))
            .map(com => com.id)
        );
      })
      .catch((e) => {
        console.log(e);
        toast.error('Error fetching communities. Please try refreshing the page');
      });
  }

  useEffect(() => {
    if(!localStorage.formData) {
      navigation('/username');
      return;
    }

    fetchCommunities();
  }, [])

  return (
    <LayoutEntrance>
      <Typography
        variant="h4"
        className={clsx(classes.textColor, "text-center font-extrabold")}
      >
        Sign Up for
      </Typography>
      <img
        src={mentee}
        alt="mentee"
        className="mx-auto mb-5"
        style={{ height: "30px" }}
      />

      <Typography variant="inherit" className="mb-5">
        Select the communities that interest you!
      </Typography>

      {Boolean(communityInvitations?.length) && (
        <Typography variant="inherit" className="mb-5">
          <strong>Note:</strong> You have been invited to join {communityInvitations.length === 1 ? 'community' : 'communities'} {' '}
          {communityInvitations?.map(comInv => comInv.community.name).join(', ')}
        </Typography>
      )}

      <div className='overflow-y-auto' style={{ maxHeight: Boolean(communityInvitations?.length) ? '55vh' : '65vh' }}>
        {communities?.map((com, key) => (
          <div className='flex justify-between items-center mb-3'>
            <MyAvatar
              key={key}
              src={com?.icon?.avatarUrl}
              name={com?.name}
            />
            <Checkbox
              checked={Boolean(selectedCommunities.find(comId => comId === com.id))}
              onChange={() => handleSelectCommunity(com)}
            />
          </div>
        ))}
      </div>

      <div className="mt-8">
        <Button onClick={handleSubmitCommunities}>Start your Serkohls</Button>
      </div>
    </LayoutEntrance>
  )
}

export default SelectSignupCommunities;