import axios from "@MyAxios";
import Loader from "@components/Loader";
import { GET_ALL_USERS } from "@constants/api";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function RedirectToProfile() {
  const navigate = useNavigate();
  const { userName } = useParams();
  useEffect(() => {
    axios.get(`${GET_ALL_USERS}/${1}/${userName}`).then((res) => {
      console.log(res.data);
      if (res.data.users?.length > 0)
        navigate(`/profile/${res.data.users[0].id}`, { replace: true });
      else {
        toast.error("User not found");
        navigate("/");
      }
    });
  }, [navigate, userName]);
  return <Loader />;
}
