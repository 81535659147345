import React from "react";
import { useEffect, useState } from "react";
import axios from "../../MyAxios";
import { GET_SAVED_MESSAGES } from "@constants/api";
import ChatBox from "@components/ChatBox";
import ChatOptions from "@components/ChatOptions";
import clsx from "clsx";
import { Avatar, Typography } from "@mui/material";

const SavedMessages = () => {
  const [savedItems, setSavedItems] = useState([]);
  useEffect(() => {
    const getSavedItems = async () => {
      try {
        await axios.get(GET_SAVED_MESSAGES).then((res) => {
          setSavedItems(res.data);
          console.log(res.data);
        });
      } catch (error) {}
    };
    getSavedItems();
  }, []);
  return (
    <div>
      {savedItems.map((msg, index) => (
        <div className="mt-2">
          <div className="flex items-center pl-3">
            <Avatar
              src={msg?.user?.media?.avatarUrl}
              className="w-10 h-10 mr-2"
            />
            <div>
              <Typography>{msg?.user?.userName}</Typography>
              <Typography color={"text.dark"} className=" text-sm">
                {msg?.user?.firstName} {msg?.user?.lastName}
              </Typography>
            </div>
          </div>
          <div
            key={msg?.id}
            className={clsx("flex-row pl-3", "flex items-center")}
          >
            <ChatBox key={index} sender={false} img={msg?.media?.avatarUrl}>
              {msg.message}
            </ChatBox>
            <ChatOptions msg={msg} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SavedMessages;
