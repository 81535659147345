import React from "react";
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import backImg from "../assets/img/back.svg";

export default function PrivacyPolicy() {
  const content = [
    {
      title: "Information We Collect",
      text: "We do not collect any personally identifiable information from visitors to our website unless it is voluntarily provided to us. Any information you provide to us will be used solely for the purpose for which it was provided.",
    },
    {
      title: "Use of Information",
      text: "Any information collected from visitors to our website is used solely for internal purposes, such as improving the quality of our website, analyzing trends, and enhancing user experience. We do not share, sell, or disclose any visitor information to third parties without user consent.",
    },
    {
      title: "Cookies",
      text: "We may use cookies on our website to enhance user experience. Cookies are small text files that are stored on your device when you visit certain websites. You can choose to disable cookies through your browser settings, but please note that doing so may affect the functionality of our website.",
    },
    {
      title: "Third-Party Links",
      text: "Our website may contain links to third-party websites. Please note that we are not responsible for the privacy practices or content of these third-party websites. We encourage you to review the privacy policies of these websites before providing any personal information.",
    },
    {
      title: "Children's Privacy",
      text: "Our website is not directed at children under the age of 13. We do not knowingly collect or solicit personal information from children under the age of 13. If you believe that we may have collected personal information from a child under the age of 13, please contact us immediately.",
    },
    {
      title: "Data Security",
      text: "We take reasonable measures to protect the security of visitor information on our website. However, please note that no method of transmission over the internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee the absolute security of visitor information.",
    },
    {
      title: "Changes to Privacy Policy",
      text: "We reserve the right to modify or amend this Privacy Policy at any time. Any changes to this Privacy Policy will be posted on our website, and the effective date will be updated accordingly. Your continued use of our website after any changes to this Privacy Policy constitutes your acceptance of such changes.",
    },
    {
      title: "Contact Us",
      text: "If you have any questions or concerns about this Privacy Policy, please contact us at serkohls@serkohls.com.",
    },
  ];

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div
        style={{ backgroundColor: "#e5f8f1" }}
        className="p-3 mb-4 shadow-black-100 shadow-md flex items-center"
      >
        <div className="cursor-pointer">
          <img onClick={goBack} src={backImg} alt="Back" />
        </div>
        <Typography className="grow text-center" color="primary" variant="h6">
          Privacy Policy
        </Typography>
      </div>
      <div className="px-6">
        <Typography>
          Welcome to{" "}
          <Link style={{ color: "#51CDA0" }} to={"/"}>
            {" "}
            serkohls.com
          </Link>{" "}
          ! We are committed to protecting the privacy of our users. This
          Privacy Policy outlines the types of information we collect from
          visitors to our website and how we use, disclose, and safeguard that
          information. By using our website, you agree to the terms and
          conditions of this Privacy Policy.
        </Typography>

        {content.map((item, index) => (
          <div key={index}>
            <Typography className="mt-4" fontWeight={500}>
              {index + 1}. {item.title}:
            </Typography>
            <Typography>{item.text}</Typography>
          </div>
        ))}

        <Typography className="mt-4">
          Thank you for visiting{" "}
          <Link style={{ color: "#51CDA0" }} to={"/"}>
            {" "}
            serkohls.com
          </Link>{" "}
          ! We appreciate your trust in us and are committed to protecting your
          privacy.
        </Typography>
      </div>
    </div>
  );
}
