import { useState } from "react";
import { IconButton, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

export default function SearchBar({ setQuery, query }) {
  const [search, setSearch] = useState(query || "");
  return (
    <div className="flex justify-end items-center">
      <TextField
        sx={{
          width: "250px",
          borderRadius: "10px",
          "& fieldset": { border: "none" },
        }}
        className="bg-slate-100"
        size="small"
        type="search"
        value={search}
        focused={Boolean(search)}
        onKeyUp={(e) => e.key === "Enter" && setQuery(search)}
        onChange={(e) => setSearch(e.target.value)}
        InputLabelProps={{ style: { fontSize: "12px" } }}
        InputProps={{
          style: { fontSize: "12px" },
          placeholder: "Search",
        }}
      />
      <IconButton
        onClick={() => {
          setQuery(search);
        }}
      >
        <Search color="primary" />
      </IconButton>
    </div>
  );
}
