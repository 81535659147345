import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import clsx from "clsx";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabPane({ tabs, children, activeTab, onTabChange, unfixed = false }) {
  const [value, setValue] = React.useState(0);
  React.useEffect(() => {
    if (activeTab === 0 || activeTab) setValue(activeTab);
  }, [activeTab]);

  const handleChange = (_, newValue) => {
    if (onTabChange) onTabChange(newValue);
    setValue(newValue);
  };

  return (
    <Box className='h-full w-full'>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs
          className={clsx(!unfixed && 'fixed md:w-1/2', "z-50 bg-white w-full")}
          sx={{
            "& button": {
              textTransform: "none",
              fontWeight: "600",
              color: "#A1A1A1",
            },
          }}
          value={value}
          onChange={handleChange}
        >
          {tabs.map((tab, index) => (
            <Tab
              sx={{
                fontSize: "14px",
              }}
              className="grow"
              key={index}
              label={tab}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      <div className={clsx("z-0", !unfixed && 'mt-10')}>
        {children.map((child, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {child}
          </CustomTabPanel>
        ))}
      </div>
    </Box>
  );
}
