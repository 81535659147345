import { IconButton, CircularProgress, Typography } from "@mui/material";
import { Button } from "@hooks/useActionFilters";
import { Key as KeyIcon, MoreHoriz } from "@mui/icons-material";
import Table from "@components/admin/Table";
import SearchBar from "@components/admin/SearchBar";
import apiClient from "@apiClient";
import AdminLayout from "./AdminLayout";
import useSetActiveLink from "@hooks/useSetActiveLink";
import useTable from "@hooks/useTable";
import DateFilter from "@components/admin/DateFilter";
import PopperWrapper from "@components/admin/PopperWrapper";
import PopperList from "@components/admin/PopperList";
import useAdminLoggedIn from "@hooks/useAdminLoggedIn";
import CommunitiesIcon from "@images/communitiesIcon.svg";

import useDateFilters from "@hooks/useDateFilters";
import useActionFilters, { ActionFilters } from "@hooks/useActionFilters";
import { createDateColumn, getSelectedItems } from "@helpers";
import MyAvatar from "@components/admin/MyAvatar";
import SideOptions from "@components/admin/SideOptions";
import { useState } from "react";
import CommunityForm from "@components/admin/CommunityForm";
import clsx from "clsx";
import PageNavigator from "@components/admin/PageNavigator";
import CommunityDetail from "./CommunityDetail";
import CommunityAssignAdmin from "@components/admin/CommunityAssignAdmin";
import CommunityInviteUser from "@components/admin/CommunityInviteUser";
import CommunityAdminAccessForm from "./CommunityAdminAccessForm";

function Communities() {
  const [openCommunityForm, setOpenCommunityForm] = useState(false);
  const [openCommunityAssignAdmin, setOpenCommunityAssignAdmin] = useState(false);
  const [openCommunityInvite, setOpenCommunityInvite] = useState(false);

  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [adminAssignCommunity, setAdminAssignCommunity] = useState(null);
  const [invitationCommunity, setInvitationCommunity] = useState(null);

  useAdminLoggedIn();
  useSetActiveLink(2);
  const {
    setReload,
    setPage,
    page,
    totalPages,
    count,
    query,
    setQuery,
    data,
    checked,
    setChecked,
    setLimit,
    limit,
    setSortColumns,
    sortColumns,
    loading,
    setDateFilters,
    setFilters,
  } = useTable({
    getData: apiClient.getCommunities,
    pageSize: 10,
  });
  const {
    activeFilter,
    getActions,
    getActionLinks,
    getActionButtons,
    getConfirmationModals,
    setActiveFilter,
  } = useActionFilters({
    apiDelete: apiClient.deleteCommunities,
    apiDisable: apiClient.disableCommunities,
    apiReinstate: apiClient.reinstateCommunities,
    setFilters,
    getSelectedItems: () => getSelectedItems(checked),
    setReload,
    onLinkClick: () => {
      setSelectedCommunity(null);
    },
    customActions: [
      {
        label: "View Details",
        generateClickHandler: (item) => {
          return () => {
            setSelectedCommunity(item.id);
          };
        },
      },
      {
        label: "Assign Admin",
        generateClickHandler: (item) => {
          return () => {
            setAdminAssignCommunity(item);
            setOpenCommunityAssignAdmin(true);
          };
        },
      },
      {
        label: "Invite User",
        generateClickHandler: (item) => {
          return () => {
            setInvitationCommunity(item);
            setOpenCommunityInvite(true);
          };
        },
      },
    ],
  });

  const { dateFilters, activeDateFilter } = useDateFilters({
    setDateFilters,
    prefix: "Created",
    activeFilter: activeFilter,
  });

  // PROPS FOR TABLE
  const fields = [
    {
      columnName: "Name",
      field: "name",
      render: (item) => (
        <MyAvatar name={item.name} src={item?.icon?.avatarUrl} />
      ),
    },
    {
      columnName: "Groups",
      render: (item) => item.groups?.length,
    },
    {
      columnName: "Admin",
      render: (item) => (
        item.admin
          ? (
            <>
              {item.admin.userName}
              <br />
              {item.admin.emailAddress}
            </>
          )
          : 'N/A'
      ),
    },
    createDateColumn(activeFilter),
    {
      columnName: "Actions",
      render: (item) => (
        <PopperWrapper id={item.id}>
          <IconButton>
            <MoreHoriz />
          </IconButton>
          <PopperList items={getActions(item)} />
        </PopperWrapper>
      ),
    },
  ];

  // Side Options Links
  const links = [
    ...getActionLinks(),
    {
      text: 'Access Levels',
      icon: <KeyIcon color="primary" alt='Community admin access levels' />,
      onClick: () => {
        setSelectedCommunity(null);
        setActiveFilter("Access Levels")
      }
    }
  ];

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <CircularProgress />
      </div>
    );
  }
  return (
    <AdminLayout>
      <div className="h-full">
        <div className="flex h-full">
          <SideOptions
            headerText={"Communities"}
            headerIcon={<img src={CommunitiesIcon} alt="Communities" />}
            links={links}
            activeFilter={activeFilter}
          />
          {activeFilter === 'Access Levels' && (
            <CommunityAdminAccessForm />
          )}
          {activeFilter !== 'Access Levels' && (
            <>
              <div
                className={clsx(selectedCommunity && "hidden", "grow px-12 pt-6 overflow-x-auto")}
              >
                <div className="flex items-center gap-2">
                  <DateFilter
                    count={count}
                    dateFilters={dateFilters}
                    activeDateFilter={activeDateFilter}
                  />
                  <SearchBar setQuery={setQuery} query={query} />

                  <div
                    className={clsx(
                      activeFilter !== ActionFilters.ALL && "hidden",
                      "ml-auto"
                    )}
                  >
                    <Button
                      variant="contained"
                      onClick={() => setOpenCommunityForm(true)}
                    >
                      Add
                    </Button>
                  </div>
                  <div
                    className={clsx(
                      Object.values(checked).filter((val) => val).length === 0 &&
                        "hidden",
                      "ml-2 flex items-center gap-2"
                    )}
                  >
                    {getActionButtons()}
                  </div>
                </div>
                <Table
                  data={data}
                  fields={fields}
                  checked={checked}
                  setChecked={setChecked}
                  setSortColumns={setSortColumns}
                  sortColumns={sortColumns}
                />
                {data.length > 0 && (
                  <PageNavigator
                    totalPages={totalPages}
                    setPage={setPage}
                    page={page}
                    setLimit={setLimit}
                    limit={limit}
                  />
                )}
              </div>
              {selectedCommunity && (
                <div className="grow overflow-x-auto">
                  <CommunityDetail
                    id={selectedCommunity}
                    setId={setSelectedCommunity}
                    setCommunitiesReload={setReload}
                  />
                </div>
              )}
            </>
          )}
        </div>

        {/* Confirmation Modals */}
        {getConfirmationModals()}
        <CommunityForm
          open={openCommunityForm}
          setOpen={setOpenCommunityForm}
          setReload={setReload}
        />  

        <CommunityAssignAdmin
          open={openCommunityAssignAdmin}
          setOpen={setOpenCommunityAssignAdmin}
          setReload={setReload}
          adminAssignCommunity={adminAssignCommunity}
        />

        <CommunityInviteUser
          open={openCommunityInvite}
          setOpen={setOpenCommunityInvite}
          invitationCommunity={invitationCommunity}
        />
      </div>
    </AdminLayout>
  );
}

export default Communities;
