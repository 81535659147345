import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function MentionedTextWrapper({ children, setChildrenLoaded }) {
  const [segments, setSegments] = useState([]);
  const navigate = useNavigate();

  const onMentionClick = useCallback(
    (e, userName) => {
      e.stopPropagation();
      navigate(`/profile/redirect/${userName}`);
    },
    [navigate]
  );

  useEffect(() => {
    const regex = /@\[\w+\]\((\w+)\)|([^@]+)/g;
    // Loop through all matches and create segments
    let match;
    let segments = [];
    while ((match = regex.exec(children)) !== null) {
      const segment = match[0];
      if (segment.startsWith("@")) {
        const userName = segment.match(/\((\w+)\)/)[1];
        segments.push(
          <span
            style={{ color: "#51CDA0" }}
            key={segment}
            onClick={(e) => onMentionClick(e, userName)}
            className="inline font-semibold cursor-pointer"
          >
            {userName}
          </span>
        );
      } else {
        segments.push(segment);
      }
    }
    setSegments(segments);
    if (setChildrenLoaded) setChildrenLoaded(true);
  }, [children, onMentionClick, setChildrenLoaded]);

  return (
    <>
      {segments.map((segment, index) => (
        <span key={index}>{segment}</span>
      ))}
    </>
  );
}
