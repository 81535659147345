import { useEffect, useState } from "react";
import axios from "@MyAxios";
import { GET_NOTIFICATIONS } from "@constants/api";
import MyAvatar from "@components/admin/MyAvatar";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

export default function BlockedNotifications() {
  const [groupedNotifications, setGroupedNotifications] = useState([]);

  const unBlockNotificationGroup = (id) => {
    axios
      .post(`/notification/group/unblock/${id}`)
      .then(() => {
        toast.success("Unblocked notifications");
        setGroupedNotifications(
          groupedNotifications.filter((g) => g.id !== id)
        );
      })
      .catch(() => {});
  };
  useEffect(() => {
    axios
      .get(GET_NOTIFICATIONS, { params: { isBlocked: true } })
      .then((res) => {
        setGroupedNotifications(res.data);
      })
      .catch(() => {});
  }, []);

  return (
    <div className="mt-2 flex flex-col gap-2">
      {groupedNotifications.map((group, key) => {
        const fromUser = group?.fromUser;
        const fromGroup = group?.fromGroup;
        return (
          <div className="border-b-2 border-gray-100  p-4 cursor-pointer">
            <div className="flex justify-between">
              {fromUser && (
                <MyAvatar
                  name={fromUser?.userName}
                  src={fromUser?.media?.avatarUrl}
                />
              )}
              {fromGroup && (
                <MyAvatar
                  name={fromGroup?.name}
                  src={fromGroup?.icon?.avatarUrl}
                />
              )}
              <div className="self-center">
                <Button
                  sx={{ textTransform: "none" }}
                  onClick={() => unBlockNotificationGroup(group.id)}
                  color="error"
                >
                  Unblock
                </Button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
