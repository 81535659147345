import api from "@apiClient";
import useTable from "@hooks/useTable";
import React, { useState } from "react";
import Table from "@components/admin/Table";
import MyAvatar from "@components/admin/MyAvatar";
import PageNavigator from "@components/admin/PageNavigator";
import ReportFilter from "@components/admin/ReportFilter";
import { ReportFilters } from "@helpers";
import PopperList from "@components/admin/PopperList";
import PopperWrapper from "@components/admin/PopperWrapper";
import { Avatar, CircularProgress, IconButton } from "@mui/material";
import Button from "./Button";

import { MoreHoriz } from "@mui/icons-material";
import PostReportDetail from "@components/admin/ReportDetails/PostReportDetail";
import clsx from "clsx";
import DeletedPost from "@images/deletedPost.svg";
import useReportActions from "@hooks/useReportActions";
import BanUserCommunityDetails from "../BanUserCommunityDetails";
export default function PostReports() {
  const [activeReportFilter, setActiveReportFilter] = useState(
    ReportFilters.UNRESOLVED
  );
  const [selectedPostReport, setSelectedPostReport] = useState(null);
  const [userToCommunityBan, setUserToCommunityBan] = useState();
  const [communityForBan, setCommunityForBan] = useState();

  const handleCloseUserBanDetailsModel = () => {
    setUserToCommunityBan();
    setCommunityForBan();
  }

  const {
    setPage,
    page,
    totalPages,
    count,
    data,
    checked,
    setChecked,
    setLimit,
    limit,
    setSortColumns,
    sortColumns,
    loading,
    setReload,
    setFilters,
  } = useTable({
    getData: api.getPostReports,
    pageSize: 10,
    initialFilters: {
      status: activeReportFilter,
    },
  });
  const { actionButtons, selectedActionButtons, getActionLinks, warningModal, postsDeleteModal } =
    useReportActions({
      reloadPage: () => setReload((reload) => reload + 1),
      hideModal: () => setSelectedPostReport(null),
      getUserFromReport: (report) => report.postReported?.user,
      getPostFromReport: (report) => report.postReported,
      selectedReport: selectedPostReport,
      checked,
      data,
      activeReportFilter,
      extraReportModalActions: [
        {
          label: "Ban User from Community",
          color: 'error',
          onClick: () => {
            setSelectedPostReport(null);
            setUserToCommunityBan(selectedPostReport.postReported?.user);
            setCommunityForBan(selectedPostReport.postReported?.communities?.[0]);
          },
        },
      ],
    });

  const fields = [
    {
      columnName: "Name",
      render: (item) => (
        <MyAvatar
          src={item.postReported?.user?.media?.avatarUrl}
          name={(() => {
            if(item.postReported)
              return `${item.postReported?.user?.firstName} ${item.postReported?.user?.lastName}`;
            
            return '[Deleted Post]';
          })()}
        />
      ),
    },
    {
      columnName: "username",
      render: (item) => item.postReported?.user?.userName,
    },
    {
      columnName: "Email",
      render: (item) => item.postReported?.user?.emailAddress,
    },
    {
      columnName: "Reporter",
      render: (item) =>
        item.reportedBy?.emailAddress,
    },
    {
      columnName: "Description",
      field: "reason",
      render: (item) => item.reason,
    },
    {
      columnName: "Stream",
      render: (item) => {
        if (item.postReported?.deletedAt > 0) {
          return (
            <Avatar
              className="mr-auto"
              src={DeletedPost}
              variant="square"
              sx={{ width: 24, height: 24 }}
            />
          );
        }
        return item.postReported?.media ? (
          <Avatar src={item.postReported?.media?.avatarUrl} variant="square" />
        ) : (
          item.postReported?.description
        );
      },
    },
    {
      columnName: "Status",
      field: "status",
      render: (item) => item.status,
    },
    {
      columnName: "Date",
      field: "createdAt",
      render: (item) => new Date(item.createdAt).toLocaleDateString(),
    },
    {
      columnName: "Actions",
      render: (item) => (
        <PopperWrapper id={item.id}>
          <IconButton>
            <MoreHoriz />
          </IconButton>
          <PopperList
            items={[
              {
                label: "View Details",
                onClick: () => setSelectedPostReport(item),
              },
              {
                label: "Ban User from Community",
                onClick: () => {
                  setUserToCommunityBan(item.postReported?.user);
                  setCommunityForBan(item.postReported?.communities?.[0]);
                },
              },
              ...getActionLinks(item),
            ]}
          />
        </PopperWrapper>
      ),
    },
  ];

  const reportFilterButtons = Object.values(ReportFilters).map((filter) => {
    return {
      label: filter,
      onClick: () => {
        setActiveReportFilter(filter);
        setFilters({ status: filter });
      },
    };
  });

  if (loading)
    return <CircularProgress />;

  return (
    <div>
      <div className="flex items-center gap-2">
        <ReportFilter
          count={count}
          activeReportFilter={activeReportFilter}
          reportFilterButtons={reportFilterButtons}
        />
        <div
          className={clsx(
            Object.values(checked).filter((val) => val).length === 0 &&
              "hidden",
            "ml-auto flex items-center gap-2"
          )}
        >
          {selectedActionButtons.map((button, index) => (
            <Button variant="contained" key={index} {...button} />
          ))}
        </div>
      </div>
      <Table
        data={data}
        fields={fields}
        checked={checked}
        setChecked={setChecked}
        setSortColumns={setSortColumns}
        sortColumns={sortColumns}
      />
      {data.length > 0 && (
        <PageNavigator
          totalPages={totalPages}
          setPage={setPage}
          page={page}
          setLimit={setLimit}
          limit={limit}
        />
      )}
      {selectedPostReport && (
        <PostReportDetail
          postReport={selectedPostReport}
          setPostReport={setSelectedPostReport}
          actionButtons={actionButtons}
        />
      )}
      {warningModal()}
      {postsDeleteModal()}

      <BanUserCommunityDetails
        user={userToCommunityBan}
        community={communityForBan}
        open={Boolean(userToCommunityBan) && Boolean(communityForBan)}
        onClose={handleCloseUserBanDetailsModel}
        onSuccess={() => {
          handleCloseUserBanDetailsModel();
          setReload(r => r + 1);
        }}
      />
    </div>
  );
}
