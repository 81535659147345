import { CardMedia, CircularProgress, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import Title from "../../components/Title";
import axios from "../../MyAxios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MentionsInput, Mention } from "react-mentions";
import mentionStyle from "./mentionStyles";
import mentionsInputStyle from "./mentionsInputStyles";
import {
  ADD_COMMUNITY_CONNECTION,
  GET_ALL_USERS,
  ROOT,
  UPLOAD_FILE,
} from "../../constants/api";
import {
  addAllToConnections,
  addAllToGroups,
  resetPost,
  setDescription,
  setFile,
  setFileName,
  setMedia,
  setTags,
  setTitle,
} from "../../redux/postSlice";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../Layout";
import upload from "../../assets/img/upload.svg";
import useLoggedIn from "../../hooks/useLoggedIn";
import { setTotalPages, updatePost } from "@redux/prevPostsSlice";
import MyAvatar from "@components/admin/MyAvatar";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
    borderColor: "#887E7E",
  },
  borderGreen: {
    borderWidth: 1,
    borderColor: "#51CDA0",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

const CreatePost = (res) => {
  useLoggedIn();

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const { id } = useParams();
  const fileInput = useRef(null);
  const editPostFormData = useRef(new FormData());

  const { title, description, file, fileName, media, isGroupOnlyPost } = useSelector((state) => state.post);

  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [mentionSuggestionsLoading, setMentionSuggestionsLoading] = useState(false);
  const [prevFile, setPrevFile] = useState();
  const [prevFileType, setPrevFileType] = useState();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("title", title || "");
    setValue("description", description || "");
    setValue("mediaId", media || "");
  }, [title, description, setValue, file, media]);


  useEffect(() => {
    if(file) {
      setPrevFile(URL.createObjectURL(file));
      setPrevFileType(file?.type?.split("/")?.[0]);
    }
  }, [file]);

  // useEffect(() => {
  //   return () => {
  //     if (id) {
  //       dispatch(resetPost());
  //     }
  //   };
  // }, [dispatch, id]);

  useEffect(() => {
    if(description) {
      let mentionStrings = description.match(/\B@[a-z0-9_-]+/gi);

      setMentionSuggestions([]);

      if(mentionStrings?.length) {
        mentionStrings = mentionStrings.map(str => str.slice(1));

        setMentionSuggestionsLoading(true);

        axios.get('/user/get-user-by-usernames', { params: { usernames: mentionStrings } })
          .then(response => {
            setMentionSuggestions(response.data);
          })
          .finally(() => setMentionSuggestionsLoading(false));
      }
    }
  }, [description])

  useEffect(() => {
    if (id) {
      axios.get(`/post/${id}`).then((res) => {
        const post = res.data;
        dispatch(setTitle(post.title));
        dispatch(setDescription(post.description ?? ""));
        dispatch(setTags(post.tags ?? []));
        dispatch(addAllToConnections(post.connections?.map((x) => x.id) ?? []));
        dispatch(addAllToGroups(post.groups?.map((x) => x.id) ?? []));
        if (post?.media?.avatarUrl) {
          dispatch(setMedia(post.media.id));
          setPrevFileType(post.media.fileType);
          setPrevFile(post.media.avatarUrl);
          editPostFormData.current.append("mediaId", post?.media?.id);
        } else {
          dispatch(setMedia(""));
          dispatch(setFile(null));
          dispatch(setFileName(""));
          setPrevFile("");
          setPrevFileType("");
        }
      });
    }

    return () => {
      if(id) {
        resetFile();
        dispatch(resetPost());
      }
    }
  }, []);

  // const getAllUsers = useCallback(() => {
  //   axios
  //   .get(`${GET_ALL_USERS}/1/${search}`).then((res) => {
  //     const data = res.data.users.filter(
  //       (u) => u.verification?.emailStatus === "confirmed"
  //     );
  //     setConnections(data);
  //   });
  // }, [search]);

  // Form submission handler
  const onSubmit = async (data) => {
    if (id) {
      try {
        const formData = editPostFormData.current;
        if (!media && file) {
          setLoading(true);
          formData.append("file", file);
          await axios.post(UPLOAD_FILE, formData).then((res) => {
            if (res.data) {
              setLoading(false);
              formData.set("mediaId", res.data);
            } else {
              toast.error("Something went wrong while uploading media");
            }
          });
        }

        formData.append("title", title || "");
        formData.append("description", description || "");
        const res = await axios.post(`/post/edit/${id}`, formData);
        dispatch(resetPost());
        dispatch(updatePost(res.data));
        navigation(ROOT);

        // if (isGroupOnlyPost) {
        //   navigation("/select-tags/" + id);
        // } else {
        //   navigation(ADD_COMMUNITY_CONNECTION + "/" + id);
        // }
      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        if (!media && file) {
          const formData = new FormData();
          setLoading(true);
          formData.append("file", file);
          await axios.post(UPLOAD_FILE, formData).then((res) => {
            if (res.data) {
              setLoading(false);
              dispatch(setMedia(res.data));
            } else {
              toast.error("Something went wrong while uploading media");
            }
          });
        }

        if (isGroupOnlyPost) {
          navigation("/select-tags");
        } else {
          navigation(ADD_COMMUNITY_CONNECTION);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 45 * 1024 * 1024) {
        toast.error("File size should be less than 45MB");
        return;
      }
      setValue("file", e.target.files[0]);
      dispatch(setFileName(e.target.files[0].name));
      dispatch(setFile(e.target.files[0]));
      setPrevFile(URL.createObjectURL(e.target.files[0]));
      setPrevFileType(e.target.files[0].type.split("/")[0]);
    }
    if (e.target.value) e.target.value = null;
  };

  const resetFile = () => {
    setPrevFile("");
    setPrevFileType("");
    setValue("file", "");
    dispatch(setFileName(""));
    dispatch(setFile(""));
    dispatch(setMedia(""));
  };

  return (
    <Layout>
      <Title back={ROOT}>Post Stream</Title>
      <div className="m-auto flex w-5/6 flex-col gap-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="flex flex-col gap-2"
        >
          <div
            className={clsx(
              classes.colorGreen,
              classes.borderGreen,
              " my-5 flex relative flex-col items-center justify-center rounded-md border-dashed p-14"
            )}
            onClick={() => fileInput.current.click()}
          >
            {prevFile && (
              <Typography
                color={"red"}
                onClick={(e) => {
                  e.stopPropagation();
                  editPostFormData.current.set("mediaId", "");
                  resetFile();
                }}
                className="absolute cursor-pointer top-5 left-5"
              >
                X
              </Typography>
            )}

            <div>
              <CardMedia
                crossOrigin="anonymous"
                component={"video"}
                controls
                className={clsx("mb-2 h-40", (prevFile && prevFileType === 'video') ? '' : 'hidden')}
                image={prevFile}
                alt="Stream Media"
                muted
              />
              <img
                src={prevFile}
                alt="Upload"
                className={clsx("mb-2 h-40 object-contain", (prevFile && prevFileType === 'image') ? '' : 'hidden')}
              />
            </div>

            {!Boolean(prevFile) && (
              <img src={upload} alt="Upload" className="mb-2 object-contain" />
            )}
            <Typography>{fileName || "Upload File"}</Typography>

            <Typography fontSize={10} className="text-center">
              {fileName ||
                "(For optimal performance, we currently limit each file upload to 45MBs or less)"}
            </Typography>
            <input
              className="hidden"
              multiple={true}
              type="file"
              accept="image/*,video/*"
              ref={fileInput}
              onChange={onFileChange}
            />
          </div>
          <div className="input-wrapper flex flex-col">
            <input
              className={clsx(classes.border, "p-3")}
              style={{ borderRadius: 4 }}
              placeholder="Title (Required)"
              type="text"
              {...register("title", {
                required: "Title is required",
              })}
              onChange={(e) => dispatch(setTitle(e.target.value))}
            />
            {errors.title && (
              <p className="text-xs italic text-red-500">
                {errors.title.message}
              </p>
            )}
          </div>

          {/* <div className="input-wrapper flex flex-col">
            <textarea
              className={clsx(classes.border, "h-32 rounded-md p-3")}
              placeholder="Add Description"
              {...register("description", {})}
              onChange={(e) => dispatch(setDescription(e.target.value))}
            />
            {errors.description && (
              <p className="text-xs italic text-red-500">
                {errors.description.message}
              </p>
            )}
          </div> */}

          <div className="input-wrapper flex flex-col CreatePost">
            <MentionsInput
              value={description}
              style={mentionsInputStyle}
              className={clsx("h-32 rounded-md overflow-y-hidden")}
              placeholder="Add Description"
              onChange={(e) => {
                dispatch(setDescription(e.target.value));
              }}
            >
              {/* TODO: Link api call to Mentions library for accurate matching */}
              <Mention
                trigger="@"
                style={mentionStyle}
                key={mentionSuggestionsLoading}
                isLoading={mentionSuggestionsLoading}
                data={mentionSuggestions?.map(user => ({ id: user.userName, display: user.userName }))}
                appendSpaceOnAdd={true}
                renderSuggestion={(
                  suggestion,
                  search,
                  value,
                  index,
                  focused
                ) => (
                  <MyAvatar
                    name={value}
                    src={mentionSuggestions?.find(user => user.userName === suggestion.id)?.media?.avatarUrl}
                  />
                )}
              />
            </MentionsInput>
          </div>

          <div className="flex items-end justify-end">
            <button
              type="submit"
              className={clsx(classes.button, "mt-5 w-24 rounded-3xl p-2 px-6")}
            >
              {loading ? (
                <CircularProgress size={"20px"} color="inherit" />
              ) : (
                <Typography color={"white"}>{id ? "Next" : "Next"}</Typography>
              )}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default CreatePost;
