import api from "@apiClient";
import useTable from "@hooks/useTable";
import React, { useState } from "react";
import Table from "@components/admin/Table";
import MyAvatar from "@components/admin/MyAvatar";
import PageNavigator from "@components/admin/PageNavigator";
import ReportFilter from "@components/admin/ReportFilter";
import { ReportFilters } from "@helpers";
import PopperList from "@components/admin/PopperList";
import PopperWrapper from "@components/admin/PopperWrapper";
import { CircularProgress, IconButton } from "@mui/material";
import Button from "./Button";
import { MoreHoriz } from "@mui/icons-material";
import clsx from "clsx";
import useReportActions from "@hooks/useReportActions";
import CommentReportDetail from "@components/admin/ReportDetails/CommentReportDetail";
import BanUserCommunityDetails from "../BanUserCommunityDetails";
export default function CommentReports() {
  const [activeReportFilter, setActiveReportFilter] = useState(
    ReportFilters.UNRESOLVED
  );
  const [selectedCommentReport, setSelectedCommentReport] = useState(null);
  const [userToCommunityBan, setUserToCommunityBan] = useState();
  const [communityForBan, setCommunityForBan] = useState();

  const handleCloseUserBanDetailsModel = () => {
    setUserToCommunityBan();
    setCommunityForBan();
  }

  const {
    setPage,
    page,
    totalPages,
    count,
    data,
    checked,
    setChecked,
    setLimit,
    limit,
    setSortColumns,
    sortColumns,
    loading,
    setReload,
    setFilters,
  } = useTable({
    getData: api.getCommentReports,
    pageSize: 10,
    initialFilters: {
      status: activeReportFilter,
    },
  });
  const getUserFromCommentReport = (report) => report.commentReported?.user;
  const getCommentFromCommentReport = (report) => report.commentReported;
  const { actionButtons, selectedActionButtons, getActionLinks, warningModal } =
    useReportActions({
      reloadPage: () => setReload((reload) => reload + 1),
      hideModal: () => setSelectedCommentReport(null),
      getUserFromReport: getUserFromCommentReport,
      getCommentFromReport: getCommentFromCommentReport,
      selectedReport: selectedCommentReport,
      checked,
      data,
      activeReportFilter,
      extraReportModalActions: [
        {
          label: "Ban User from Community",
          color: 'error',
          onClick: () => {
            setSelectedCommentReport(null);
            setUserToCommunityBan(selectedCommentReport.commentReported?.user);
            setCommunityForBan(selectedCommentReport.commentReported?.post?.communities?.[0]);
          },
        },
      ],
    });

  const fields = [
    {
      columnName: "Name",
      render: (item) => (
        <MyAvatar
          src={getUserFromCommentReport(item)?.media?.avatarUrl}
          name={
            getUserFromCommentReport(item)?.firstName +
            " " +
            getUserFromCommentReport(item)?.lastName
          }
        />
      ),
    },
    {
      columnName: "username",
      render: (item) => getUserFromCommentReport(item)?.userName,
    },
    {
      columnName: "email",
      render: (item) => getUserFromCommentReport(item)?.emailAddress,
    },

    {
      columnName: "Reporter",
      render: (item) =>
        item.reportedBy.emailAddress,
    },
    {
      columnName: "Comment",
      render: (item) => item?.commentReported?.comment,
    },
    {
      columnName: "Description",
      field: "reason",
      render: (item) => item.reason,
    },
    {
      columnName: "Status",
      field: "status",
      render: (item) => item.status,
    },
    {
      columnName: "Date",
      field: "createdAt",
      render: (item) => new Date(item.createdAt).toLocaleDateString(),
    },
    {
      columnName: "Actions",
      render: (item) => (
        <PopperWrapper id={item.id}>
          <IconButton>
            <MoreHoriz />
          </IconButton>
          <PopperList
            items={[
              {
                label: "View Details",
                onClick: () => setSelectedCommentReport(item),
              },
              {
                label: "Ban User from Community",
                onClick: () => {
                  setUserToCommunityBan(item.commentReported?.user);
                  setCommunityForBan(item.commentReported?.post?.communities?.[0]);
                },
              },
              ...getActionLinks(item),
            ]}
          />
        </PopperWrapper>
      ),
    },
  ];

  const reportFilterButtons = Object.values(ReportFilters).map((filter) => {
    return {
      label: filter,
      onClick: () => {
        setActiveReportFilter(filter);
        setFilters({ status: filter });
      },
    };
  });

  if (loading) return <CircularProgress />;
  return (
    <div>
      <div className="flex items-center gap-2">
        <ReportFilter
          count={count}
          activeReportFilter={activeReportFilter}
          reportFilterButtons={reportFilterButtons}
        />
        <div
          className={clsx(
            Object.values(checked).filter((val) => val).length === 0 &&
              "hidden",
            "ml-auto flex items-center gap-2"
          )}
        >
          {selectedActionButtons.map((button, index) => (
            <Button variant="contained" key={index} {...button} />
          ))}
        </div>
      </div>
      <Table
        data={data}
        fields={fields}
        checked={checked}
        setChecked={setChecked}
        setSortColumns={setSortColumns}
        sortColumns={sortColumns}
      />
      {data.length > 0 && (
        <PageNavigator
          totalPages={totalPages}
          setPage={setPage}
          page={page}
          setLimit={setLimit}
          limit={limit}
        />
      )}
      {selectedCommentReport && (
        <CommentReportDetail
          commentReport={selectedCommentReport}
          actionButtons={actionButtons}
          setCommentReport={setSelectedCommentReport}
        />
      )}
      {warningModal()}

      <BanUserCommunityDetails
        user={userToCommunityBan}
        community={communityForBan}
        open={Boolean(userToCommunityBan) && Boolean(communityForBan)}
        onClose={handleCloseUserBanDetailsModel}
        onSuccess={() => {
          handleCloseUserBanDetailsModel();
          setReload(r => r + 1);
        }}
      />
    </div>
  );
}
