import { Avatar } from "@mui/material";
import CommentText from "./CommentText";
import { useState } from "react";
import { calTime } from "../helpers";
import { useNavigate } from "react-router-dom";
import { REPORT_FORM } from "@constants/api";
import { useSelector } from "react-redux";
function CommentOptions({ children, onClick }) {
  return (
    <span
      onClick={onClick}
      style={{ fontSize: "12px" }}
      className="font-normal cursor-pointer mr-1 text-gray-500"
    >
      {children}
    </span>
  );
}

export default function Comment({
  readOnly = false,
  comment,
  setCommentToReply,
  setCommentToEdit,
  setComment,
  onDelete,
  setPrevFile,
}) {
  const [showReply, setShowReply] = useState(true);
  const navigate = useNavigate();
  const { userRecord } = useSelector((state) => state.user);

  const onCommentEdit = (comment) => {
    setCommentToEdit(comment);

    setComment(comment?.comment);
    setPrevFile(comment?.media?.avatarUrl);
  };
  return (
    <div className="flex flex-col">
      <div className="flex">
        <Avatar
          src={comment?.user?.media?.avatarUrl}
          className="z-0 mr-2 mt-3 self-start"
        />
        <div className="flex flex-col grow mr-12">
          <div className="flex items-center">
            <CommentText
              readOnly={readOnly}
              user={comment.user}
              media={comment.media}
              time={calTime(comment?.createdAt)}
              onEdit={() => onCommentEdit(comment)}
            >
              {comment.comment}
            </CommentText>
          </div>
          
          {readOnly && <div className="mb-6" />}

          <div className={readOnly ? 'hidden' : ''}>
            <CommentOptions
              onClick={() => {
                setComment(
                  `@[${comment?.user?.userName}](${comment?.user?.userName}) `
                );

                setCommentToReply(comment);
              }}
            >
              Reply
            </CommentOptions>
            {userRecord?.id === comment?.user?.id && (
              <CommentOptions>|</CommentOptions>
            )}

            {userRecord?.id === comment?.user?.id && (
              <CommentOptions
                onClick={() => {
                  onDelete(comment?.id);
                }}
              >
                Delete
              </CommentOptions>
            )}
            <CommentOptions>|</CommentOptions>
            <CommentOptions
              onClick={() =>
                navigate(REPORT_FORM, {
                  state: { id: comment?.id, report: "comment" },
                })
              }
            >
              Report
            </CommentOptions>
          </div>
          {/* {!showReply && comment?.replies?.length > 0 && (
            <CommentOptions
              className=" font-normal cursor-pointer text-gray-500"
              onClick={() => setShowReply(true)}
            >
              ____View Replies____
            </CommentOptions>
          )}

          {showReply && comment?.replies?.length > 0 && (
            <CommentOptions
              className=" font-normal cursor-pointer text-gray-500"
              onClick={() => setShowReply(false)}
            >
              ____Collapse____
            </CommentOptions>
          )} */}
        </div>
      </div>

      {showReply &&
        comment?.replies?.map((reply, key) => (
          <div key={key} className="ml-10 mt-2 flex">
            <Avatar
              src={reply?.user?.media?.avatarUrl}
              className="z-0 mr-2 mt-3"
            />
            <div className="flex flex-col grow mr-6">
              <div className="flex items-center">
                <CommentText
                  media={reply.media}
                  user={reply.user}
                  time={calTime(reply?.createdAt)}
                  onEdit={() => onCommentEdit(reply)}
                >
                  {reply?.comment}
                </CommentText>
              </div>
              <div>
                <CommentOptions
                  onClick={() => {
                    setComment(`@${reply?.user?.userName} `);
                    setCommentToReply(comment);
                  }}
                >
                  Reply
                </CommentOptions>

                {userRecord?.id === reply?.user?.id && (
                  <CommentOptions>|</CommentOptions>
                )}

                {userRecord?.id === reply?.user?.id && (
                  <CommentOptions
                    onClick={() => {
                      onDelete(reply?.id);
                    }}
                  >
                    Delete
                  </CommentOptions>
                )}

                <CommentOptions>|</CommentOptions>

                <CommentOptions
                  onClick={() =>
                    navigate(REPORT_FORM, {
                      state: { id: comment?.id, report: "comment" },
                    })
                  }
                >
                  Report
                </CommentOptions>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
