import clsx from "clsx";
import Layout from "../../Layout";
import axios from "../../MyAxios";
import { GET_ALL_USERS, GET_USER_CHAT, ROOT } from "../../constants/api";
import { styled, alpha } from "@mui/material/styles";
import { Avatar, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import Title from "@components/Title";
import InputBase from "@mui/material/InputBase";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ShowMore from "@components/ShowMore";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },

  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  border: "2px solid",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: 15,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const NewChat = () => {
  const navigation = useNavigate();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const classes = useStyles();
  const [name, setName] = useState("");
  const getUsers = async (page) => {
    try {
      const res = await axios.get(`${GET_ALL_USERS}/${page}/${name}`);
      if (page === 1) setUsers(res.data.users);
      else setUsers([...res.data.users, ...users]);
      setTotalPages(res.data.totalPages);
    } catch (error) {}
  };

  const getSearchedUsers = () => {
    setPage(1);
    getUsers(1);
  };

  useEffect(() => {
    getUsers(page);
  }, [page]);
  const userChat = (user) => {
    try {
      console.log(user);
      navigation(`${GET_USER_CHAT}/${user.id}`);
    } catch (error) {}
  };
  return (
    <Layout>
      <Title back={ROOT}>New Chat</Title>
      <div className="m-4">
        <Search className="flex mb-5 justify-between items-center">
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setName(e.target.value)}
          />
          <PersonSearchIcon
            onClick={getSearchedUsers}
            color="primary"
            className="mr-4 cursor-pointer"
          />
        </Search>

        {users?.map((user) => (
          <div
            key={user?.id}
            onClick={() => userChat(user)}
            className=" flex w-full cursor-pointer flex-row items-center border-b-2 p-2"
          >
            <div>
              <Avatar
                className=" h-10 w-10 rounded-full"
                src={user?.media?.avatarUrl}
              />
            </div>
            <div className="flex w-full justify-between p-2 pl-4">
              <div>
                <Typography
                  className={clsx(classes.textGrey, "text-xs font-bold")}
                >
                  {user?.firstName} {user?.lastName}
                </Typography>
                <Typography className={clsx(classes.textGrey, " leading-none")}>
                  <small>{user?.userName}</small>
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </div>
      {page < totalPages && (
        <div
          onClick={() => setPage(page + 1)}
          className=" cursor-pointer text-center"
        >
          <ShowMore>Show More</ShowMore>
        </div>
      )}
    </Layout>
  );
};

export default NewChat;
