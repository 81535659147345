import Header from "./components/Header";

function Layout({ children, communitySelector = false, handleCommunityChange }) {
  return (
    <div className="mx-auto flex w-full flex-col md:w-1/2">
      <Header
        communitySelector={communitySelector}
        handleCommunityChange={handleCommunityChange}
      />
      <div className={communitySelector ? "mt-28" : "mt-14"} />
      {children}
    </div>
  );
}

export default Layout;
