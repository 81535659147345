import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { CardMedia } from "@mui/material";
import ImageModal from "./ImageModal";
import EditCommentIcon from "@images/EditComment.svg";
import { useSelector } from "react-redux";
import MentionedTextWrapper from "./MentionedTextWrapper";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  bgGreen: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  bgGrey: {
    backgroundColor: "#efefef",
    color: "#000",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function CommentText({ children, media, user, time, onEdit, readOnly = false }) {
  const [openImageModal, setOpenImageModal] = useState(false);
  const classes = useStyles();
  const { userRecord } = useSelector((state) => state.user);
  return (
    <div className="grow">
      <div className={clsx("flex justify-start")}>
        <div className="flex flex-col grow">
          <div
            className={clsx(
              `leading-1.5 mt-2 flex flex-col rounded-e-xl  rounded-ss-xl py-2 px-2`,
              classes.bgGrey
            )}
          >
            {media?.avatarUrl &&
              (media?.fileType === "video" ? (
                <CardMedia
                  controlsList="nodownload"
                  crossOrigin="anonymous"
                  component={"video"}
                  controls
                  className="mx-auto h-52 w-11/12 rounded-2xl object-contain"
                  image={media?.avatarUrl}
                  alt="Stream Media"
                  autoPlay
                  muted
                />
              ) : (
                <img
                  onClick={() => setOpenImageModal(true)}
                  className=" mb-3 h-40 bg-white object-contain cursor-pointer"
                  src={media?.avatarUrl}
                  alt="Media"
                />
              ))}
            <div style={{ fontSize: "12px" }}>
              <p className="flex">
                <span className="font-semibold">{user?.userName}</span>
                <span className="pl-2">{time}</span>

                {userRecord?.id === user?.id && !readOnly && (
                  <span
                    className="ml-auto mr-1 self-center cursor-pointer"
                    onClick={onEdit}
                  >
                    <img src={EditCommentIcon} alt="edit" />
                  </span>
                )}
              </p>
              <p className="font-normal">
                <MentionedTextWrapper>{children}</MentionedTextWrapper>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ImageModal
        url={media?.avatarUrl}
        open={openImageModal}
        setOpen={setOpenImageModal}
      />
    </div>
  );
}

export default CommentText;
