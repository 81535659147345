import { MoreHoriz } from "@mui/icons-material";
import { CardMedia, Typography } from "@mui/material";
import React, { useState } from "react";
import ImageModal from "../ImageModal";
import ResourceDate from "./ResourceDate";

export default function MediaResource({ resource, onMoreClick }) {
  const [openImageModal, setOpenImageModal] = useState(false);

  return (
    <div>
      <div className="flex justify-between">
        <div
          className="flex cursor-pointer"
          onClick={() => {
            setOpenImageModal(true);
          }}
        >
          {resource?.media?.fileType === "video" ? (
            <CardMedia
              controlsList="nodownload"
              crossOrigin="anonymous"
              component={"video"}
              className="mx-auto h-20 w-28 rounded-md overflow-hidden"
              image={resource?.media?.avatarUrl}
              alt="Stream Media"
              autoPlay
              muted
            />
          ) : (
            <img
              className="w-28 h-20 rounded-md overflow-hidden"
              src={resource?.media?.avatarUrl}
              alt="Media"
            />
          )}
          <div className="ml-2 ">
            <Typography color={"text.dark"} className="font-semibold pt-1">
              {resource?.title}
            </Typography>
            <ResourceDate timestamp={resource?.createdAt} />
          </div>
        </div>
        <MoreHoriz
          className=" cursor-pointer"
          sx={{ color: "text.dark" }}
          onClick={() => onMoreClick()}
        />
      </div>
      <ImageModal
        url={resource?.media?.avatarUrl}
        open={openImageModal}
        setOpen={setOpenImageModal}
        type={resource?.media?.fileType}
      />
    </div>
  );
}
