import { Avatar } from "@mui/material";

export default function MyAvatar({
  name,
  subtext,
  textProps,
  subtextProps,
  avatarSx,
  ...props
}) {
  if (!subtextProps) subtextProps = {};
  if (!textProps) textProps = {};
  return (
    <div className="flex items-center gap-2">
      <Avatar
        sx={avatarSx}
        {...props}
      >{name?.length > 0 && name[0]}</Avatar>
      <div>
        <div {...textProps}>{name}</div>
        <div className={"text-sm text-gray-500"} {...subtextProps}>
          {subtext}
        </div>
      </div>
    </div>
  );
}
