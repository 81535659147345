import React, { useEffect, useState } from 'react'
import { Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalComAdminAccessLevels } from '@redux/communitySlice';
import Loader from '@components/Loader';
import { getGlobalGroupModeratorAccessLevels, setGlobalGroupModeratorAccessLevels, updateGlobalGroupModeratorAccessLevels } from '@redux/groupSlice';

function GroupModeratorAccessForm() {
  const dispatch = useDispatch();

  const { globalGroupModeratorAccessLevels } = useSelector(state => state.group);
  
  const [loading, setLoading] = useState(true);
  const [accessLevels, setAccessLevels] = useState();

  const handleSubmit = () => {
    dispatch(updateGlobalGroupModeratorAccessLevels({
      accessLevels
    }));
  }

  const handleAccessLevelUpdate = (field, checked) => {
    setAccessLevels({
      ...accessLevels,
      [field]: checked,
    });
  }

  const fetchData = () => {
    setLoading(true);
    dispatch(getGlobalGroupModeratorAccessLevels())
      .then(() => setLoading(false));
  }

  useEffect(() => {
    if(globalGroupModeratorAccessLevels)
      setAccessLevels({
        canAddGroupModerators: globalGroupModeratorAccessLevels?.canAddGroupModerators,
        canRemoveGroupModerators: globalGroupModeratorAccessLevels?.canRemoveGroupModerators,
        canEditGroup: globalGroupModeratorAccessLevels?.canEditGroup,
        canAddMembers: globalGroupModeratorAccessLevels?.canAddMembers,
      });
  }, [globalGroupModeratorAccessLevels])

  useEffect(() => {
    fetchData();

    return () => {
      dispatch(setGlobalGroupModeratorAccessLevels());
    }
  }, [])

  return (
    <div className="grow px-12 pt-6 overflow-x-auto">
      <Typography className="font-bold mb-6" variant="h6">
        Group Moderator Access Levels
      </Typography>

      {(() => {
        if(loading)
          return <Loader />

        return (
          <>
            <FormGroup>
              <FormControlLabel
                label="Allowed to add group moderators"
                control={
                  <Checkbox
                    checked={accessLevels?.canAddGroupModerators}
                    onChange={e => handleAccessLevelUpdate('canAddGroupModerators', e.target.checked)}
                  />
                }
              />
              <FormControlLabel
                label="Allowed to remove group moderators"
                control={
                  <Checkbox
                    checked={accessLevels?.canRemoveGroupModerators}
                    onChange={e => handleAccessLevelUpdate('canRemoveGroupModerators', e.target.checked)}
                  />
                }
              />
              <FormControlLabel
                label="Allowed to edit group info"
                control={
                  <Checkbox
                    checked={accessLevels?.canEditGroup}
                    onChange={e => handleAccessLevelUpdate('canEditGroup', e.target.checked)}
                  />
                }
              />
              <FormControlLabel
                label="Allowed to add group members"
                control={
                  <Checkbox
                    checked={accessLevels?.canAddMembers}
                    onChange={e => handleAccessLevelUpdate('canAddMembers', e.target.checked)}
                  />
                }
              />
            </FormGroup>

            {/* <pre>
              {JSON.stringify(accessLevels, null, 2)}
            </pre> */}

            <div className='mt-6'>
              <Button variant='contained' color='primary' onClick={handleSubmit}>
                Update
              </Button>
            </div>
          </>
        )
      })()}

    </div>
  )
}

export default GroupModeratorAccessForm;