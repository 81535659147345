import Layout from "../../Layout";
import Title from "../../components/Title";
import TabPane from "../../components/TabPane";
import useLoggedIn from "../../hooks/useLoggedIn";
import BlockedUsers from "@components/BlockedUsers";
import BlockedNotifications from "@components/BlockedNotifications";

export default function Blocked() {
  useLoggedIn();

  const tabs = ["Users", "Notifications"];
  return (
    <Layout>
      <Title back={"/"}>Blocked Content</Title>
      <TabPane tabs={tabs}>
        <BlockedUsers />
        <BlockedNotifications />
      </TabPane>
    </Layout>
  );
}
