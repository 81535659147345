import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import axios from "../../MyAxios";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_SAVED_RESOURCES,
  SAVE_RESOURCE,
  UNSAVE_RESOURCE,
} from "../../constants/api";
import { useLocation, useNavigate } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";
import TabPane from "@components/TabPane";
import dayjs from "dayjs";
import { MoreHoriz } from "@mui/icons-material";
import save from "@images/saveGreen.svg";
import share from "@images/shareGreen.svg";
import link from "@images/resourceLink.svg";
import clsx from "clsx";
import { toast } from "react-toastify";
import GroupModal from "@components/GroupModal";
import MediaResource from "@components/resource/MediaResource";
import TextResource from "@components/resource/TextResource";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
    borderColor: "#887E7E",
  },
  borderGreen: {
    borderWidth: 1,
    borderColor: "#51CDA0",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  bgGrey: {
    backgroundColor: "#efefef",
    color: "#000",
  },
}));

const SavedResources = () => {
  useLoggedIn();
  const classes = useStyles();
  const navigation = useNavigate();
  const [saved, setSavedItems] = useState([]);
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const options = [
    // {
    //   id: 1,
    //   name: "Share",
    //   img: share,
    // },
    // {
    //   id: 2,
    //   name: "Save",
    //   img: save,
    //   action: async () => {
    //     try {
    //       await axios.post(SAVE_RESOURCE, { id: selected.id });
    //       setOpen(false);
    //       toast.success("Saved Successfully");
    //     } catch (error) {}
    //   },
    // },
    {
      id: 3,
      name: "Unsave",
      img: save,
      action: async () => {
        try {
          await axios.post(UNSAVE_RESOURCE, { id: selected.id });
          setOpen(false);
          toast.success("Unsaved Successfully");
        } catch (error) {}
      },
    },
  ];

  useEffect(() => {
    const getSavedItems = async () => {
      try {
        await axios.get(GET_SAVED_RESOURCES).then((res) => {
          setSavedItems(res.data);
        });
      } catch (error) {}
    };
    getSavedItems();
  }, []);

  return (
    <div>
      <div>
        {saved?.map((resource) => (
          <div className="p-2">
            {resource?.isMedia ? (
              <MediaResource
                resource={resource}
                onMoreClick={() => {
                  setSelected(resource);
                  setOpen(true);
                }}
              />
            ) : (
              <TextResource
                resource={resource}
                onMoreClick={() => {
                  setSelected(resource);
                  setOpen(true);
                }}
              />
            )}
          </div>
        ))}
        <GroupModal open={open} setOpen={setOpen} options={options} />
      </div>
    </div>
  );
};

export default SavedResources;
