import { Modal, Button, Checkbox, Autocomplete, TextField, Typography } from "@mui/material";
import { ModalBox } from "@components/admin/StyledComponents";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import apiClient from "@apiClient";
import { toast } from "react-toastify";
import validator from "validator";

export default function CommunityInviteUser({ open, setOpen, invitationCommunity }) {
  const [email, setEmail] = useState(null);
  const [selectedUser, setSelectedUser] = useState();
  const [usersList, setUsersList] = useState([]);
  const [fetchTimeout, setFetchTimeout] = useState();

  const handleInvite = () => {
    if(!email)
      return toast.error('You need to provide an email');

    if(!validator.isEmail(email))
      return toast.error('Email format is invalid');

    apiClient.inviteUserToCommunity({ communityId: invitationCommunity.id, inviteeEmail: email })
      .then(res => {
        toast.success(res.data?.message ?? 'Invitation sent successfully');
        setOpen(false);
      })
      .catch(error => {
        console.error(error);
      })
  }

  const getUserLabel = (user) => {
    let userName = user.userName;

    let fullName = user.firstName;
    if(user.lastName)
      fullName += ' ' + user.lastName;

    let email = user.emailAddress;

    return `${userName} (${email})`;
  }

  const fetchUsers = () => {
    apiClient.getUsers({ query: email, limit: 99999999 })
      .then(res => {
        setUsersList(res.data);
      })
      .catch(error => {
        console.error(error);
        toast.error(error?.message ?? 'Error fetching users');
      })
  }

  useEffect(() => {
    if(email) {
      clearTimeout(fetchTimeout);
      setFetchTimeout(setTimeout(fetchUsers, 300));
    } else {
      setUsersList([])
    }
  }, [email])

  useEffect(() => {
    if(selectedUser) {
      setEmail(selectedUser.emailAddress);
    }
  }, [selectedUser])

  useEffect(() => {
    return () => {
      setUsersList([]);
    }
  }, [])

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalBox sx={{ p: 4 }} className="rounded-lg shadow-md">
        <div className="flex flex-col gap-2">
          <Autocomplete
            freeSolo
            autoComplete
            disableClearable
            includeInputInList
            className="w-full"
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : getUserLabel(option)
            }
            filterOptions={(x) => x}
            options={usersList}
            value={selectedUser}
            inputValue={email ?? ''}
            clear
            onChange={(event, user) => setSelectedUser(user)}
            onInputChange={(event, newVal, reason) => {
              if(reason === 'input') {
                setEmail(newVal);
                setSelectedUser();
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Enter invitee email" fullWidth />
            )}
            renderOption={(props, option) => (
              <div {...props} key={option.id}>
                {getUserLabel(option)}
              </div>
            )}
          />

          <div className="flex w-full justify-end gap-2 mt-6">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="submit" variant="contained" onClick={handleInvite}>
              Send Invitation
            </Button>
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
}
