import useAdminLoggedIn from "@hooks/useAdminLoggedIn";
import useSetActiveLink from "@hooks/useSetActiveLink";
import React, { useState } from "react";
import AdminLayout from "./AdminLayout";
import SideOptions from "@components/admin/SideOptions";
import ReportsIcon from "@images/reports.svg";
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import axios from "@MyAxios";

export default function Settings() {
  useAdminLoggedIn();
  useSetActiveLink(5);

  const [loading, setLoading] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [seeCurrentPassword, setSeeCurrentPassword] = useState(false);
  const [seeNewPassword, setSeeNewPassword] = useState(false);
  const [seeRepeatPassword, setSeeRepeatPassword] = useState(false);

  const onSubmit = () => {
    if(!currentPassword || !newPassword || !repeatPassword)
      return toast.error("Some fields are missing");

    if(newPassword !== repeatPassword)
      return toast.error("Passwords don't match");

      
    setLoading(true);
    axios.post('/admin/settings/change-password', { currentPassword, newPassword, repeatPassword })
      .then(() => toast.success('Password successfully updated'))
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  }

  return (
    <AdminLayout>
      <div className="h-full">
        <div className="flex h-full">
          <SideOptions
            headerText={"Settings"}
            headerIcon={<SettingsIcon />}
            links={[]}
            activeFilter={'Settings'}
          />
          <div className="grow px-12 pt-6 overflow-x-auto">
            <Typography className="text-xl mb-6">
              Update Password
            </Typography>

            <TextField
              disabled={loading}
              label='Current Password'
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
              type={seeCurrentPassword ? 'text' : 'password'}
              className="mb-4"
              InputProps={{
                endAdornment: seeCurrentPassword
                  ? <VisibilityOffIcon className="cursor-pointer" onClick={() => setSeeCurrentPassword(false)} />
                  : <VisibilityIcon className="cursor-pointer" onClick={() => setSeeCurrentPassword(true)} />
              }}
            />
            <br />

            <TextField
              disabled={loading}
              label='New Password'
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              type={seeNewPassword ? 'text' : 'password'}
              className="mb-4"
              InputProps={{
                endAdornment: seeNewPassword
                  ? <VisibilityOffIcon className="cursor-pointer" onClick={() => setSeeNewPassword(false)} />
                  : <VisibilityIcon className="cursor-pointer" onClick={() => setSeeNewPassword(true)} />
              }}
            />
            <br />

            <TextField
              disabled={loading}
              label='Repeat Password'
              value={repeatPassword}
              onChange={e => setRepeatPassword(e.target.value)}
              type={seeRepeatPassword ? 'text' : 'password'}
              className="mb-4"
              InputProps={{
                endAdornment: seeRepeatPassword
                  ? <VisibilityOffIcon className="cursor-pointer" onClick={() => setSeeRepeatPassword(false)} />
                  : <VisibilityIcon className="cursor-pointer" onClick={() => setSeeRepeatPassword(true)} />
              }}
            />
            <br />

            <Button variant="contained" onClick={onSubmit}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
