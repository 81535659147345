import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CHANGE_PASSWORD, LOGIN } from "../../constants/api";
import wave from "../../assets/img/wave.svg";
import axios from "../../MyAxios";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import Constraints from "../../components/Constraints";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const EnterPassword = (res) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { passwordResetUser, passwordResetOtp } = useSelector(
    (state) => state.user
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    toast.info("Please do not reload or leave page");
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const [disabledSU, setDisabledSU] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const setDisabled = (e) => console.log();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Form submission handler
  const onSubmit = async (data) => {
    // Do something with the form data
    data.user = passwordResetUser;
    data.code = passwordResetOtp;
    try {
      const res = await axios.post(CHANGE_PASSWORD, data);
      toast.success(res.data);
      navigate(LOGIN);
    } catch (error) {}
  };

  return (
    <div>
      <div className="m-auto flex w-5/6 flex-col gap-4 md:w-3/12">
        <div className="mt-20">
          <Typography
            variant="h5"
            className={clsx(classes.textColor, "text-center")}
          >
            Reset Password
          </Typography>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="flex flex-col gap-2"
        >
          <div className="input-wrapper flex flex-col">
            <label className="ml-1">
              <small>Create your password *</small>
            </label>
            <OutlinedInput
              size="small"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              inputProps={{
                ...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                }),
                className: "p-3",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />

            {errors.password && (
              <p className="text-xs italic text-red-500">
                {errors.password.message}
              </p>
            )}
          </div>
          <div className="input-wrapper flex flex-col">
            <label className="ml-1">
              <small>Reenter your password*</small>
            </label>
            <OutlinedInput
              size="small"
              placeholder="Reenter Password"
              type={showConfirmPassword ? "text" : "password"}
              inputProps={{
                ...register("confirmPassword", {
                  required: "Please confirm your password",
                  validate: (value) =>
                    value === watch("password") || `Passwords do not match`,
                }),
                className: "p-3",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />

            {errors.confirmPassword && (
              <p className="text-xs italic text-red-500">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>

          <Constraints
            string={watch("password")}
            setDisabledSU={setDisabledSU}
            setDisabled={setDisabled}
            forPass={true}
          />

          <div className="mt-14">
            <Button>Reset Password</Button>
          </div>
        </form>

        <div className={clsx(classes.bottomImg, "block sm:hidden")}>
          <img src={wave} alt="Wave" className="z-0 w-full" />
        </div>
      </div>
    </div>
  );
};

export default EnterPassword;
