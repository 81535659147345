import React from "react";
import { Checkbox, Radio } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { addToGroups, addToPostCommunities } from "../../redux/postSlice";

import useLoggedIn from "../../hooks/useLoggedIn";
import MyAvatar from "@components/admin/MyAvatar";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  bgGrey: {
    backgroundColor: "#efefef",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function SelectPostCom({ communities }) {
  useLoggedIn();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedPostCommunities } = useSelector((state) => state.post);

  const isSelected = (id) => selectedPostCommunities?.[0] == id;

  return (
    <div>
      <div className="flex flex-col mt-2 mx-6 gap-2">
        {communities.map((community, key) => (
          <div key={key} className="flex justify-between">
            <MyAvatar
              avatarSx={{ width: 30, height: 30, fontSize: 16 }}
              textProps={{ style: {fontSize: 14} }}
              src={community.icon?.avatarUrl}
              name={community.name}
            />
            <label className={clsx(classes.textGrey)}>
              <Radio
                className="py-1"
                sx={{ color: "green" }}
                checked={isSelected(community.id)}
                onChange={(e) => dispatch(addToPostCommunities(community.id))}
              />
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SelectPostCom;
