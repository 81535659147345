import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import postSlice from "./postSlice";
import groupSlice from "./groupSlice";
import adminSlice from "./adminSlice";
import prevPostsSlice from "./prevPostsSlice";
import communitySlice from "./communitySlice";
import notificationsSlice from "./notificationsSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    post: postSlice,
    group: groupSlice,
    admin: adminSlice,
    prevPosts: prevPostsSlice,
    community: communitySlice,
    notifications: notificationsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
