import Layout from "../../Layout";
import Title from "../../components/Title";
import { ROOT } from "../../constants/api";
import contact from "../../assets/img/amico.svg";
import React from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import axios from "../../MyAxios";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  textGrey: {
    color: "#626262",
  },
  bgGrey: {
    backgroundColor: "#efefef",
  },
}));

const ContactForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      await axios.post("/feedback", { detail: data.description });
      toast.success("Feedback sent successfully");
      setValue("description", "");
      navigate(ROOT);
    } catch (error) {}
  };
  return (
    <Layout>
      <Title back={ROOT}>Contact Us</Title>
      <div className="mx-auto mt-24 mb-4">
        <img src={contact} alt="Contact" />
      </div>
      <div className="mx-auto text-center">
        <Typography variant="h5" className=" font-bold" color="primary.main">
          Get In Touch
        </Typography>
        <Typography color={"text.dark"} className="text-sm">
          We are here for you! How can we help?
        </Typography>
      </div>

      <div className="m-auto mt-5 flex w-5/6 flex-col gap-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="flex flex-col gap-2"
        >
          <div className="input-wrapper flex flex-col">
            <textarea
              className={clsx(classes.border, "h-32 rounded-md p-3")}
              placeholder="Your Message"
              {...register("description", {
                required: "Description is required",
              })}
            />
            {errors.description && (
              <p className="text-xs italic text-red-500">
                {errors.description.message}
              </p>
            )}
          </div>

          <div className=" flex items-end justify-end">
            <button
              type="submit"
              className={clsx(classes.button, "mt-5 w-24 rounded-3xl p-2 px-6")}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ContactForm;
