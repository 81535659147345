import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import axios from "../../MyAxios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CREATE_GROUP,
  GROUP_INFO,
  GROUP_SELECT_COMMUNITY,
} from "../../constants/api";
import Layout from "../../Layout";
import Title from "../../components/Title";
import camera from "@images/camera.svg";
import useLoggedIn from "../../hooks/useLoggedIn";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
    borderColor: "#887E7E",
  },
  borderGreen: {
    borderWidth: 1,
    borderColor: "#51CDA0",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  textGrey: {
    color: "#626262",
  },
  bgGrey: {
    backgroundColor: "#efefef",
  },
}));

const CreateGroupDetails = () => {
  useLoggedIn();
  const classes = useStyles();
  const navigation = useNavigate();
  // const { title, description } = useSelector((state) => state.post);
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [option, setOption] = useState("Public");
  const fileInput = useRef(null);
  const { userRecord } = useSelector((state) => state.user);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setOption(value);
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // useEffect(() => {
  //   setValue("title", title || "");
  //   setValue("description", description || "");
  // });

  const options = ["Public", "Private"];

  // Form submission handler
  const onSubmit = async (data) => {
    try {
      let comm = "";
      // if (userRecord.role === "admin") {
        comm = localStorage.getItem("community");
        localStorage.removeItem("community");
      // }
      const formData = new FormData();
      formData.append("community", comm);
      formData.append("visibility", option);
      formData.append("admin", userRecord.id);
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("rules", data.rules);
      formData.append("file", file);

      try {
        const res = await axios.post(CREATE_GROUP, formData);
        toast.success(`Group created successfully`);
        navigation(`${GROUP_INFO}/${res.data.id}`);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {}
  };

  const onChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <Layout>
      <Title back={GROUP_SELECT_COMMUNITY}>Create Group</Title>
      <div className="m-auto mt-10 flex w-5/6 flex-col gap-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="flex flex-col gap-2"
        >
          <div className="flex items-center">
            <div
              className=" relative"
              onClick={() => fileInput.current.click()}
            >
              <Avatar
                className=" relative h-20 w-20 object-contain"
                src={image}
              />
              <img
                className=" absolute bottom-0 right-0"
                src={camera}
                alt="Camera"
              />
            </div>
            <Typography className="ml-4">Add group icon (Optional)</Typography>
          </div>
          <input
            onChange={onChange}
            ref={fileInput}
            type="file"
            className="hidden"
          />
          <div className="input-wrapper flex flex-col">
            <input
              className={clsx(classes.border, "rounded-md p-3")}
              placeholder="Name (Required)"
              type="text"
              {...register("name", {
                required: "Name is required",
              })}
            />
            {errors.name && (
              <p className="text-xs italic text-red-500">
                {errors.name.message}
              </p>
            )}
          </div>

          <div className="input-wrapper flex flex-col">
            <textarea
              className={clsx(classes.border, "h-32 rounded-md p-3")}
              placeholder="Add Description"
              {...register("description", {
                required: "Description is required",
              })}
            />
            {errors.description && (
              <p className="text-xs italic text-red-500">
                {errors.description.message}
              </p>
            )}
          </div>

          <div className="input-wrapper flex flex-col">
            <textarea
              className={clsx(classes.border, "h-32 rounded-md p-3")}
              placeholder="Add Group Rules"
              {...register("rules", {})}
            />
            {errors.rules && (
              <p className="text-xs italic text-red-500">
                {errors.rules.message}
              </p>
            )}
          </div>

          <FormControl className={clsx(classes.bgGrey)} sx={{ marginTop: 2 }}>
            <InputLabel id="demo-multiple-name-label"></InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={option}
              onChange={handleChange}
            >
              {options.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className=" flex items-end justify-end">
            <button
              type="submit"
              className={clsx(classes.button, "mt-5 w-24 rounded-3xl p-2 px-6")}
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default CreateGroupDetails;
