import { Close } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";

function Tag({ text, onClick }) {
  return (
    <div
      className="px-4 py-2 rounded-full cursor-pointer"
      style={{ backgroundColor: "rgba(98, 98, 98, 0.1)" }}
      onClick={onClick}
    >
      {text}
    </div>
  );
}

export default function Keywords({
  keywords,
  tags,
  setSearch,
  onDeleteKeywords,
}) {
  const onTagClick = (subcategory) => {
    setSearch(`#${subcategory}`);
  };
  return (
    <div>
      {keywords.length > 0 && (
        <div
          className="pb-6"
          style={{ borderBottom: `1px solid rgba(98, 98, 98, 0.1)` }}
        >
          <div className="flex justify-between my-4">
            <Typography className="font-semibold">Recent</Typography>
            <Typography
              color="primary"
              className="cursor-pointer font-semibold"
              onClick={() => {
                onDeleteKeywords(keywords.map((k) => k.id));
              }}
            >
              Clear All
            </Typography>
          </div>
          <div className="flex flex-col gap-3">
            {keywords.map((keyword) => (
              <div className="flex justify-between items-center">
                <Typography
                  key={keyword._id}
                  className="grow cursor-pointer"
                  onClick={() => setSearch(keyword.text)}
                >
                  {keyword.text}
                </Typography>
                <Close
                  onClick={() => {
                    onDeleteKeywords([keyword.id]);
                  }}
                  className="cursor-pointer"
                  fontSize="8px"
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex mt-6 flex-wrap gap-2">
        {Object.values(tags)
          .flat()
          .map((tag) => {
            return (
              <Tag
                text={tag.subcategory}
                onClick={() => onTagClick(tag.subcategory)}
              ></Tag>
            );
          })}
      </div>
    </div>
  );
}
