import { CardMedia, CircularProgress, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import axios from "../../MyAxios";
import { useDispatch } from "react-redux";
import { GROUP_ADD_RESOURCES, UPLOAD_FILE } from "../../constants/api";
import { resetPost } from "../../redux/postSlice";
import { useNavigate } from "react-router-dom";
import upload from "../../assets/img/upload.svg";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
    borderColor: "#887E7E",
  },
  borderGreen: {
    borderWidth: 1,
    borderColor: "#51CDA0",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

const AddMediaResource = ({ groupId, isUpdate, resource, setResource }) => {
  const classes = useStyles();

  const fileInput = useRef(null);
  const [fileName, setFileName] = useState(null);
  const [prevFile, setPrevFile] = useState(
    isUpdate ? resource?.media?.avatarUrl : null
  );
  const [prevFileType, setPrevFileType] = useState(
    isUpdate ? resource?.media?.fileType : null
  );

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("title", isUpdate ? resource?.title : "");
    setValue("description", "");
  }, [setValue, isUpdate, resource?.title]);

  // Form submission handler
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      setLoading(true);
      if (data.file) {
        setLoading(true);
        formData.append("file", data.file);
        await axios
          .post(UPLOAD_FILE, formData)
          .then((res) => {
            if (res.data) {
              setLoading(false);
              formData.append("mediaId", res.data);
            } else {
              toast.error("Something went wrong while uploading media");
              return;
            }
          })
          .catch(() => {
            toast.error("Something went wrong while uploading media");
            return;
          });
      } else if (!isUpdate) {
        toast.error("Please upload a file");
        return;
      }

      formData.append("title", data.title);
      formData.append("isMedia", true);
      formData.append("groupId", groupId);
      if (isUpdate) {
        formData.append("id", resource?.id);
        await axios.post("/resource/edit", formData);
        toast.success("Updated Successfully!");
      } else {
        await axios.post(GROUP_ADD_RESOURCES, formData);
        toast.success("Added Successfully!");
      }
      setLoading(false);
      dispatch(resetPost());
      navigate(-1);
    } catch (error) {}
  };

  const onChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 3 * 1024 * 1024) {
        toast.error("File size should be less than 3MBs");
        return;
      }
      setValue("file", e.target.files[0]);
      setFileName(e.target.files[0].name);
      setPrevFile(URL.createObjectURL(e.target.files[0]));
      setPrevFileType(e.target.files[0].type.split("/")[0]);
    }
  };

  return (
    <div className="m-auto flex mt-2 w-5/6 flex-col gap-4">
      <form
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        className="flex flex-col gap-2"
      >
        <div
          className={clsx(
            classes.colorGreen,
            classes.borderGreen,
            " my-5 flex flex-col items-center justify-center rounded-md border-dashed p-14"
          )}
          onClick={() => fileInput.current.click()}
        >
          {prevFileType === "video" ? (
            <CardMedia
              controlsList="nodownload"
              crossOrigin="anonymous"
              component={"video"}
              controls
              className="mb-2 h-40"
              image={prevFile}
              alt="Stream Media"
              autoPlay
              muted
            />
          ) : (
            <img
              src={prevFile ?? upload}
              alt="Upload"
              className="mb-2 max-h-40"
            />
          )}
          <Typography>{fileName || "Upload File"}</Typography>
          <Typography className=" text-xs text-center mb-2">
            {"Click to upload a video or image file."}
          </Typography>
          <Typography fontSize={10} className="text-center">
            {fileName ||
              "(For optimal performance, we currently limit each file upload to 3MBs or less)"}
          </Typography>
          <input
            className={clsx("hidden")}
            multiple={true}
            type="file"
            accept="image/*,video/*"
            ref={fileInput}
            onChange={onChange}
          />
        </div>
        <div className="input-wrapper flex flex-col">
          <input
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="Title"
            type="text"
            {...register("title", {
              required: "Title is required",
            })}
          />
          {errors.title && (
            <p className="text-xs italic text-red-500">
              {errors.title.message}
            </p>
          )}
        </div>

        <div className=" flex items-end justify-end">
          <button
            type="submit"
            className={clsx(classes.button, "mt-5 w-24 rounded-full p-2")}
          >
            {loading ? (
              <CircularProgress size={"20px"} color="inherit" />
            ) : isUpdate ? (
              "Update"
            ) : (
              "Add"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddMediaResource;
