import { createSlice } from "@reduxjs/toolkit";

export const PrevPostsSlice = createSlice({
  name: "community",
  initialState: {
    posts: [],
    page: 1,
    totalPages: 1,
    clickedPostId: "",
  },
  reducers: {
    setPosts: (state, action) => {
      state.posts = [...state.posts, ...action.payload];
    },
    resetPosts: (state, action) => {
      state.posts = [];
    },
    removeFromPosts: (state, action) => {
      state.posts = state.posts.filter((post) => post.id !== action.payload);
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setClickedPostId: (state, action) => {
      state.clickedPostId = action.payload;
    },
    updatePost: (state, action) => {
      state.posts = state.posts.map((post) => {
        if (post.id === action.payload.id) {
          post.title = action.payload.title;
          post.description = action.payload.description;
          post.media = action.payload.media;
          post.comments = action.payload.comments;
        }
        return post;
      });
    },
    setPostSubscribeStatus: (state, action) => {
      state.posts = state.posts.map((post) => {
        if (post.id === action.payload.postId) {
          post.isSubscribed = action.payload.isSubscribed;
        }
        return post;
      });
    },
    addToPosts: (state, action) => {
      state.posts = [
        action.payload,
        ...state.posts.filter((post) => post.id !== action.payload.id),
      ];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPosts,
  resetPosts,
  setPage,
  removeFromPosts,
  setTotalPages,
  setClickedPostId,
  updatePost,
  setPostSubscribeStatus,
  addToPosts,
} = PrevPostsSlice.actions;

export default PrevPostsSlice.reducer;
