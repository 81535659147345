import { IconButton, Modal } from "@mui/material";
import { ModalBox } from "@components/admin/StyledComponents";
import MyAvatar from "./MyAvatar";
import { getModelStatus, getModelActionDate, formatDate } from "@helpers";
import api from "@apiClient";
import { toast } from "react-toastify";
import DetailRow from "./DetailRow";
import { Button } from "@hooks/useActionFilters";
import { Close as CloseIcon } from "@mui/icons-material";

export default function GroupDetailModal({ open, setOpen, group, setReload }) {
  // ACTION HANDLERS
  const deleteGroup = () => {
    api
      .deleteGroups({ ids: [group.id] })
      .then((deleteUsers) => {
        toast.success(`${deleteUsers.length} Item(s) Deleted Successfully`);
        setReload((reload) => reload + 1);
        setOpen(false);
      })
      .catch(() => {});
  };
  const disableGroup = () => {
    api
      .disableGroups({ ids: [group.id] })
      .then((disabledUsers) => {
        toast.success(`${disabledUsers.length} Item(s) Disabled Successfully`);
        setReload((reload) => reload + 1);
        setOpen(false);
      })
      .catch(() => {});
  };
  const reinstateGroup = () => {
    api
      .reinstateGroups({ ids: [group.id] })
      .then((reinstatedUsers) => {
        toast.success(
          `${reinstatedUsers.length} Item(s) Reinstated Successfully`
        );
        setReload((reload) => reload + 1);
        setOpen(false);
      })
      .catch(() => {});
  };

  const hanldeDialogClose = () => {
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={hanldeDialogClose}
    >
      <ModalBox className="shadow-md">
        <div className="flex justify-between items-start">
          <MyAvatar
            src={group.icon?.avatarUrl}
            name={group.name}
            subtext={`${group?.members?.length} group members`}
          />

          <IconButton onClick={hanldeDialogClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <table className="w-full text-left text-sm mt-5">
            <tbody>
              <DetailRow label="Admin:" value={group.admin?.firstName} />
              <DetailRow
                label="Admin Username:"
                value={group.admin?.userName}
              />
              <DetailRow
                label="Admin Email:"
                value={group.admin?.emailAddress}
              />
              <DetailRow label="Community:" value={group.community?.name} />
              <DetailRow label="Status:" value={getModelStatus(group)} />
              {getModelActionDate(group) && (
                <DetailRow
                  label="Action Date:"
                  value={getModelActionDate(group)}
                />
              )}
              <DetailRow
                label="Created At:"
                value={formatDate(group.createdAt)}
              />
              {group.moderators?.length > 0 && (
                <DetailRow
                  label="Moderators:"
                  value={group.moderators.map((mod) => (
                    <div className="grid grid-cols-2">
                      <div>{mod.firstName + " " + mod.lastName}</div>
                      <div>@{mod.userName}</div>
                    </div>
                  ))}
                />
              )}
            </tbody>
          </table>
          <div className="mt-12">
            {getModelStatus(group) === "Active" ? (
              <div className="flex justify-end gap-4">
                <Button
                  variant="contained"
                  color="warning"
                  onClick={disableGroup}
                >
                  Disable
                </Button>
                <Button variant="contained" color="error" onClick={deleteGroup}>
                  Delete
                </Button>
              </div>
            ) : (
              <div className="flex justify-end gap-4">
                <Button variant="contained" onClick={reinstateGroup}>
                  Reinstate
                </Button>
              </div>
            )}
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
}
