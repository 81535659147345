import { createSlice } from "@reduxjs/toolkit";

export const PostSlice = createSlice({
  name: "title",
  initialState: {
    title: "",
    description: "",
    media: "",
    isGroupOnlyPost: false,
    communities: [],
    connections: [],
    selectedGroups: [],
    selectedPostCommunities: [],
    tags: [],
    fileName: "",
    file: null,
  },
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setMedia: (state, action) => {
      state.media = action.payload;
    },
    setFile: (state, action) => {
      state.file = action.payload;
    },
    addToGroups: (state, action) => {
      // NOTE: Written for multiple groups, but used for singular selection
      state.selectedGroups = [ action.payload ];
      // if (state.selectedGroups.includes(action.payload)) {
      //   state.selectedGroups.splice(
      //     state.selectedGroups.indexOf(action.payload),
      //     1
      //   );
      // } else {
      //   state.selectedGroups.push(action.payload);
      // }
    },
    addToPostCommunities: (state, action) => {
      // NOTE: Written for multiple communities, but used for singular selection
      state.selectedPostCommunities = [ action.payload ];
      // if (state.selectedPostCommunities.includes(action.payload)) {
      //   state.selectedPostCommunities = state.selectedPostCommunities.filter(comId => comId != action.payload);
      // } else {
      //   state.selectedPostCommunities.push(action.payload);
      // }
    },
    addAllToPostCommunities: (state, action) => {
      state.selectedPostCommunities = action.payload;
    },
    addToCommunities: (state, action) => {
      if (state.communities.includes(action.payload)) {
        state.communities.splice(state.communities.indexOf(action.payload), 1);
      } else {
        state.communities.push(action.payload);
      }
    },
    addToConnections: (state, action) => {
      if (state.connections.includes(action.payload)) {
        state.connections.splice(state.connections.indexOf(action.payload), 1);
      } else {
        state.connections.push(action.payload);
      }
    },

    setIsGroupOnlyPost: (state, action) => {
      state.isGroupOnlyPost = action.payload;
    },
    addAllToGroups: (state, action) => {
      const newSet = action.payload;
      state.selectedGroups = newSet;
    },
    addAllToCommunities: (state, action) => {
      const newSet = action.payload;
      state.communities = newSet;
    },
    addAllToConnections: (state, action) => {
      const newSet = action.payload;
      state.connections = newSet;
    },
    clearPostCommunities: (state, action) => {
      state.selectedPostCommunities = [];
    },
    clearGroups: (state, action) => {
      state.selectedGroups = [];
    },
    clearCommunities: (state, action) => {
      state.communities = [];
    },
    clearConnections: (state, action) => {
      state.connections = [];
    },
    setFileName: (state, action) => {
      state.fileName = action.payload;
    },
    setTags(state, action) {
      state.tags = action.payload;
    },
    resetPost: (state, action) => {
      state.title = "";
      state.description = "";
      state.media = "";
      state.communities = [];
      state.connections = [];
      state.selectedGroups = [];
      state.selectedPostCommunities = [];
      state.tags = [];
      state.fileName = "";
      state.file = null;
      state.isGroupOnlyPost = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTitle,
  setDescription,
  setMedia,
  setFileName,
  addToCommunities,
  addToConnections,
  clearCommunities,
  clearConnections,
  clearGroups,
  clearPostCommunities,
  addAllToCommunities,
  addAllToConnections,
  resetPost,
  addAllToGroups,
  addToPostCommunities,
  addAllToPostCommunities,
  addToGroups,
  setFile,
  setTags,
  setIsGroupOnlyPost,
} = PostSlice.actions;

export default PostSlice.reducer;
