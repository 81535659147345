import Layout from "@Layout";
import React from "react";
import Title from "@components/Title";
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import backImg from "../assets/img/back.svg";

export default function UserAgreement() {
  const content = [
    {
      title: "Use of the Website",
      text: "You agree to use the Website solely for lawful purposes and in accordance with these Terms and Conditions. You may not use the Website in any manner that could damage, disable, overburden, or impair the Website or interfere with any other party's use of the Website.",
    },
    {
      title: "Intellectual Property Rights",
      text: "The content, trademarks, logos, and other materials displayed on the Website are owned by or licensed to us and are protected by copyright and other intellectual property laws. By uploading any content to the Website, you agree to transfer ownership of such content to the Website. You also agree not to upload any copyrighted material unless you have the necessary rights or permissions to do so.",
    },
    {
      title: "Privacy",
      text: "We respect the privacy of our visitors and do not share or disclose any visitor information to third parties. Please review our Privacy Policy for more information on how we collect, use, and safeguard visitor information.",
    },
    {
      title: "Third-Party Links",
      text: "The Website may contain links to third-party websites. We are not responsible for the content or privacy practices of these third-party websites. Your use of third-party websites is at your own risk, and you should review the terms and conditions and privacy policies of these websites before using them.",
    },
    {
      title: "Disclaimer of Warranties",
      text: `The Website is provided on an "as is" and "as available" basis without any warranties of any kind, either express or implied. We do not warrant that the Website will be uninterrupted or error-free, or that any defects will be corrected. Your use of the Website is at your own risk.`,
    },
    {
      title: "Limitation of Liability",
      text: "In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Website, whether based on warranty, contract, tort (including negligence), or any other legal theory, even if we have been advised of the possibility of such damages.",
    },
    {
      title: "Agreement Not to Upload Copyrighted Material",
      text: "By using the Website, you agree not to upload, post, or otherwise transmit any content that infringes upon the intellectual property rights of others, including copyrighted material, without the necessary rights or permissions to do so.",
    },
    {
      title: "Governing Law",
      text: "These Terms and Conditions shall be governed by and construed in accordance with the state and federal laws of the state of Michigan without regard to principles of conflict of laws, will govern these Conditions of Use and any dispute of any sort that might arise between you and hserkohls.com . Any dispute arising out of or relating to these Terms and Conditions or use of serkohls.com  shall be subject to the exclusive jurisdiction of the state and Federal courts in Wayne County, Michigan and you consent to exclusive jurisdiction and venue in these courts. We each waive any right to a jury trial.",
    },
    {
      title: "Changes to Terms and Conditions",
      text: "We reserve the right to modify or amend these Terms and Conditions at any time without prior notice. Any changes to these Terms and Conditions will be effective immediately upon posting on the Website. Your continued use of the Website after any changes to these Terms and Conditions constitutes your acceptance of such changes.",
    },
    {
      title: "Contact Us",
      text: "If you have any questions or concerns about these Terms and Conditions, please contact us at serkohls@serkohls.com.",
    },
  ];

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div
        style={{ backgroundColor: "#e5f8f1" }}
        className="p-3 mb-4 shadow-black-100 shadow-md flex items-center"
      >
        <div className="cursor-pointer">
          <img onClick={goBack} src={backImg} alt="Back" />
        </div>
        <Typography className="grow text-center" color="primary" variant="h6">
          Terms and Conditions{" "}
        </Typography>
      </div>
      <div className="px-6">
        <Typography>
          These Terms and Conditions govern your use of
          <Link style={{ color: "#51CDA0" }} to={"/"}>
            {" "}
            serkohls.com
          </Link>{" "}
          (the "Website"). By accessing or using the Website, you agree to be
          bound by these Terms and Conditions. If you do not agree with these
          Terms and Conditions, please do not use the Website. ‌
        </Typography>

        {content.map((item, index) => (
          <div key={index}>
            <Typography className="mt-4" fontWeight={500}>
              {index + 1}. {item.title}:
            </Typography>
            <Typography>{item.text}</Typography>
          </div>
        ))}
        <Typography className="mt-4">
          Thank you for using{" "}
          <Link style={{ color: "#51CDA0" }} to={"/"}>
            {" "}
            serkohls.com
          </Link>{" "}
          ! We hope you enjoy your experience on our Website.
        </Typography>
      </div>
    </div>
  );
}
