import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box, Modal, TextField } from "@mui/material";
import axios from "../../MyAxios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import clsx from "clsx";
import {
  GET_COMMENTS,
  POST,
  POST_COMMENT,
  POST_DELETE,
  ROOT,
  UPLOAD_FILE,
} from "../../constants/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../Layout";
import send from "../../assets/img/send.svg";
import Post from "../../components/Post";
import Comment from "../../components/Comment";
import useLoggedIn from "../../hooks/useLoggedIn";
import ShowMore from "@components/ShowMore";
import CommentInput from "@components/CommentInput/CommentInput";
import { setPosts, updatePost } from "@redux/prevPostsSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export default function ViewPost() {
  useLoggedIn();
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [comments, setComments] = React.useState([]);
  const [commentToReply, setCommentToReply] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [comment, setComment] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const navigation = useNavigate();
  const { id } = useParams();
  const [post, setPost] = React.useState();
  const { userRecord } = useSelector((state) => state.user);
  const [file, setFile] = React.useState("");
  const [prevFile, setPrevFile] = React.useState();
  const [commentUploading, setCommentUploading] = React.useState(false);
  const [commentToEdit, setCommentToEdit] = React.useState(null);
  const dispatch = useDispatch();
  const prevPosts = useSelector((state) => state.prevPosts);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  // React.useEffect(() => {
  //   if (!commentToReply?.user?.userName) return;
  //   setComment(`@${commentToReply?.user?.userName} `);
  // }, [commentToReply]);
  const options = [
    {
      id: 1,
      name: "Open",
    },
    {
      id: 2,
      name: "Connect",
    },
    {
      id: 3,
      name: "Copy Link",
    },
    {
      id: 4,
      name: "Save",
    },
    {
      id: 5,
      name: "Report",
    },
  ];

  const onComment = async (e) => {
    e.preventDefault();
    if (!comment && !file) return;
    const formData = new FormData();
    let mediaId = "";
    if (commentToEdit?.media?.avatarUrl) {
      mediaId = commentToEdit?.media?.id;
    }
    if (file) {
      setCommentUploading(true);
      formData.append("file", file);
      await axios
        .post(UPLOAD_FILE, formData)
        .then((res) => {
          if (res.data) {
            // setLoading(false);
            mediaId = res.data;
          } else {
            toast.error("Something went wrong while uploading media");
          }
        })
        .catch(() => {})
        .finally(() => {
          setPrevFile(null);
          setCommentUploading(false);
        });
    }
    let newPost = prevPosts.posts.filter(
      (prevPost) => prevPost.id === post.id
    )[0];
    if (newPost) {
      newPost = { ...newPost, comments: newPost.comments + 1 };
    }
    // COMMENT IS  A EDITED COMMENT
    if (commentToEdit) {
      try {
        await axios.post(`/edit/comment/${commentToEdit.id}`, {
          comment,
          mediaId: prevFile ? mediaId : null,
        });
        setComments([]);
        getComments();
      } catch (e) {
      } finally {
        setCommentToEdit(null);
        setComment("");
      }
      // COMMENT IS A REPLY
    } else if (commentToReply) {
      // const userNameToReply = comment.split("@")[1].split(" ")[0];
      // if (userNameToReply !== commentToReply?.user?.userName) {
      //   // USERNAME AFTER @ IS NOT COMPLETE
      //   toast.error("Use complete username");
      //   return;
      // }
      const replyTo = commentToReply?.id;

      try {
        setCommentUploading(true);
        const payload = {
          userId: userRecord.id,
          postId: post.id,
          comment,
          replyTo,
          mediaId,
        };
        const res = await axios.post(POST_COMMENT, payload);
        await axios.post("/notification/create/reply", {
          commentId: commentToReply?.id,
        });
        const newComments = [...comments];
        const index = newComments.findIndex((c) => c.id === replyTo);
        if (!newComments[index].replies) newComments[index].replies = [];
        newComments[index].replies = [res.data, ...newComments[index].replies];
        setComments(newComments);
        setComment("");
        if (newPost) dispatch(updatePost(newPost));
      } catch (error) {
      } finally {
        setCommentUploading(false);
        setCommentToReply(null);
      }
    } else {
      try {
        setCommentUploading(true);
        // COMMENT IS A NORMAL COMMENT
        const payload = {
          userId: userRecord.id,
          postId: post.id,
          comment,
          mediaId,
        };
        const res = await axios.post(POST_COMMENT, payload);
        await axios.post("/notification/create/comment", {
          postId: post?.id,
        });
        setComments([res.data, ...comments]);

        setComment("");
        if (newPost) dispatch(updatePost(newPost));
      } catch (error) {
      } finally {
        setCommentToReply(null);
        setCommentUploading(false);
      }
    }
  };

  const isPostGroupCommunityDisabled = (post) => {
    // We check if all groups are from disabled community.
    // This works because of group restriction to selected community on new posts
    let isDisabled = true;

    post?.groups.forEach(group => {
      if(group.community?.disabledAt === 0)
        isDisabled = false;
    });

    return isDisabled;
  }

  const isPostCommunityDisabled = (post) => {
    // Community shared to should only be 1 in number. But backend stores as array due to legacy schema, so we check from loop
    let isDisabled = true;

    post?.communities.forEach(com => {
      if(com?.disabledAt === 0)
        isDisabled = false;
    });

    return isDisabled;
  }

  const isReadOnly = () => {
    return Boolean(post?.groups?.length) && isPostGroupCommunityDisabled(post)
      || Boolean(post?.communities?.length) && isPostCommunityDisabled(post)
      || Boolean(post?.groups?.length) && post?.groups?.[0]?.disabledAt > 0;
  }

  const getCommentRestrictionError = () => {
    if(isReadOnly())
      return 'Activity restricted due to disabled group or community';

    return false;
  }

  const getPost = async () => {
    try {
      const res = await axios.get(`${POST}/${id}`);

      // if(Boolean(res.data?.groups?.length) && isPostGroupCommunityDisabled(res.data))
      //   toast.error('Post group community is disabled');

      // if(Boolean(res.data?.communities?.length) && isPostCommunityDisabled(res.data))
      //   toast.error('Post community is disabled');

      setPost(res.data);
    } catch (error) {
      navigation(-1);
    }
  };

  React.useEffect(() => {
    getPost();
  }, []);

  const getComments = async () => {
    const res = await axios.get(`${GET_COMMENTS}/${post.id}/${page}`);
    setComments((comments) => [...comments, ...res.data.comments]);
    setTotalPages(res.data.totalPages);
  };
  React.useEffect(() => {
    if (post) {
      try {
        getComments();
      } catch (e) {
        toast.error(`Error: occurred while fetching comments`);
      }
    }
  }, [page, post]);

  const onCommentDelete = (id) => {
    if (
      window.confirm("Are you sure you want to delete this comment?") === false
    ) {
      return;
    }
    axios
      .post(`/delete/comment/${id}`)
      .then((res) => {
        toast.success("Comment deleted successfully");
        setComments([]);
        getComments();
        let newPost = prevPosts.posts.filter(
          (prevPost) => prevPost.id === post.id
        )[0];
        newPost = { ...newPost, comments: newPost.comments - 1 };
        dispatch(updatePost(newPost));
      })
      .catch((err) => {});
  };

  const onDelete = async (post) => {
    try {
      toast.info("Deleting Post");
      await axios.post(POST_DELETE, { post }).then((res) => {
        toast.success("Post Deleted Successfully");
        navigation(ROOT);
      });
    } catch (error) {
      toast.error(`Error: ${error.response.data.message}`);
    }
  };

  return (
    <Layout>
      <div
        style={{ maxHeight: "80vh" }}
        className="flex flex-col justify-between"
      >
        <div className="z-50 w-full bg-white">
          <Post
            readOnly={isReadOnly()}
            fullDescription
            post={post}
            comment={false}
            back={true}
            setPost={setPost}
          />
        </div>
        <div
          style={{ borderColor: theme.palette.primary.main }}
          className="px-3 flex border-t-2 flex-col justify-between"
        >
          <div
            style={{
              maxHeight: post?.media?.avatarUrl ? "40vh" : "60vh",
              overflowY: "scroll",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
            className="flex flex-col pb-20 pt-3"
          >
            {comments?.map((comment) => (
              <Comment
                readOnly={isReadOnly()}
                key={comment?.id}
                comment={comment}
                setCommentToReply={setCommentToReply}
                setComment={setComment}
                onDelete={onCommentDelete}
                setCommentToEdit={setCommentToEdit}
                setPrevFile={setPrevFile}
              />
            ))}
            <div
              onClick={() => {
                setPage(page + 1);
              }}
              className={clsx(
                totalPages <= page && "hidden",
                "grow cursor-pointer my-2 text-center mx-4 text-sm font-normal"
              )}
            >
              <ShowMore>Show More</ShowMore>
            </div>
          </div>
        </div>
        {getCommentRestrictionError() ? (
          <Typography className="py-3 px-2 text-center fixed bottom-0 w-full md:w-6/12 bg-white">
            {getCommentRestrictionError()}
          </Typography>
        ) : (
          <CommentInput
            value={comment}
            setValue={setComment}
            onSubmit={onComment}
            setFile={setFile}
            prevFile={prevFile}
            setPrevFile={setPrevFile}
            loading={commentUploading}
          />
        )}
        {/* <div
          style={{ borderColor: theme.palette.primary.main }}
          className="fixed bottom-0  flex w-full items-center bg-white justify-center md:w-6/12"
        >
          <form onSubmit={onComment} className="flex items-center p-4">
            <Avatar src={userRecord?.media?.avatarUrl} />
            <TextField
              type="text"
              size="small"
              id="outlined-basic"
              fullWidth
              value={comment}
              placeholder="Add Comment"
              InputProps={{
                sx: {
                  borderRadius: 10,
                  borderColor: theme.palette.primary.main,
                  borderWidth: 1,
                },
              }}
              className="mx-2 w-72"
              onChange={(e) => setComment(e.target.value)}
            />
            <button type="submit">
              <img src={send} alt="Send" />
            </button>
          </form>
        </div> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {options.map((option) => (
              <Link
                to={option.name}
                key={option.id}
                className="block py-2 text-sm"
              >
                {option.name}
              </Link>
            ))}
            {post?.user?.id === userRecord?.id && (
              <Typography
                onClick={() => onDelete(post)}
                className="block py-2 text-sm"
              >
                Delete
              </Typography>
            )}
          </Box>
        </Modal>
      </div>
    </Layout>
  );
}
