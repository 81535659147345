import { getCommunityMembers, removeCommunityUser, setCommunityMembers } from '@redux/communitySlice';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import MemberListingItem from './MemberListingItem';
import { Dialog, Typography } from '@mui/material';
import RemoveIcon from "@images/delete.svg";
import { toast } from 'react-toastify';
import message from "@images/message.svg";
import BlockIcon from "../assets/img/block.svg";
import { useNavigate } from 'react-router-dom';
import { BLOCK_USER, GET_USER_CHAT, REMOVE_CONNECTION, REQUEST_CONNECTION } from '@constants/api';
import axios from '@MyAxios';
import addConnection from "@images/addConnection.svg";
import remove from "@images/remove.svg";
import { refreshUserRecord } from '@helpers';

function CommunityMembers({ communityId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [selectedMember, setSelectedMember] = useState();

  const { community, communityMembers, maxPage } = useSelector(state => state.community);
  const { userRecord } = useSelector((state) => state.user);

  const fetchMoreDivCallback = useCallback((fetchMoreDiv) => {
    if(fetchMoreDiv) {
      let observer = new IntersectionObserver((entries, observer) => {
        if(entries[0].isIntersecting && !loading) {
          setPage(page + 1);
          observer.unobserve(fetchMoreDiv);
        }
      }, { threshold: 1 });
      observer.observe(fetchMoreDiv);
    }
  }, [loading])

  const handleCloseDialog = () => {
    setSelectedMember();
  }

  const handleRemoveCommunityUser = () => {
    if(!selectedMember)
      return toast.error('No member selected');

    if(window.confirm('Are you sure you want to remove this member from community?'))
      dispatch(removeCommunityUser({
        data: {
          userId: selectedMember?.id,
          communityId: community?.id
        },
        onSuccess: () => {
          dispatch(setCommunityMembers());
          setPage(0);
          handleCloseDialog();
        },
      }));
  }

  const fetchData = () => {
    setLoading(true);
    dispatch(getCommunityMembers({ communityId, page }))  
      .then(() => setLoading(false));
  }

  useEffect(() => {
    if(communityId && page == 0)
      fetchData();

    if(page > 0 && page + 1 <= maxPage)
      fetchData();
  }, [communityId, page])

  useEffect(() => {
    dispatch(setCommunityMembers());
  }, [])

  const showMemberConnectOption = () => {
    if(userRecord?.id === selectedMember?.id)
      return false;

    if(userRecord?.connections?.map((x) => x.id).includes(selectedMember?.id))
      return false;

    return true;
  }

  const showMemberDisconnectOption = () => {
    if(userRecord?.id === selectedMember?.id)
      return false;

    if(!userRecord?.connections?.map((x) => x.id).includes(selectedMember?.id))
      return false;

    return true;
  }

  const memberActions = [
    {
      text: "Connect",
      icon: addConnection,
      onClick: () => {
        axios
          .post(REQUEST_CONNECTION, { forUser: selectedMember.id })
          .then((res) => {
            toast.success("Connection request sent successfully");
            handleCloseDialog();
          })
          .catch(() => {});
      },
      show: showMemberConnectOption()
    },
    {
      text: "Remove Connection",
      icon: remove,
      onClick: () => {
        axios
          .post(REMOVE_CONNECTION, { id: selectedMember.id })
          .then((res) => {
            toast.success("Connection removed successfully");
            handleCloseDialog();
            refreshUserRecord(dispatch);
          })
          .catch(() => {});
      },
      show: showMemberDisconnectOption()
    },
    {
      text: "Message",
      icon: message,
      onClick: () => {
        handleCloseDialog();
        navigate(`${GET_USER_CHAT}/${selectedMember.id}`);
      },
      show: true,
    },
    {
      text: "Block",
      icon: BlockIcon,
      onClick: () => {
        axios
          .post(BLOCK_USER, { id: selectedMember.id })
          .then((res) => {
            toast.success("User blocked successfully");
            handleCloseDialog();
          })
          .catch(() => {});
      },
      show: true,
    },
    {
      text: 'Remove from community',
      icon: RemoveIcon,
      onClick: handleRemoveCommunityUser,
      show: community?.adminAccessLevels?.canRemoveCommunityUser && community?.admin === userRecord?.id,
    },
  ];

  if(!communityId)
    return;

  return (
    <div className='max-h-[65vh] overflow-y-auto pb-1 scrollbar-hidden'>
      {communityMembers?.map(user => (
        <MemberListingItem
          member={user}
          setSelectedMember={setSelectedMember}
        />
      ))}
      {loading && (<Loader />)}
      <div ref={fetchMoreDivCallback} />

      <Dialog
        open={Boolean(selectedMember)}
        onClose={handleCloseDialog}
        PaperProps={{
          className: 'w-3/4 sm:w-1/2 max-w-[350px]'
        }}
      >
        <div className='p-2'>
          {!Boolean(memberActions.filter(action => action.show).length) && (
            <Typography>
              No actions available
            </Typography>
          )}
          {memberActions.map((action, key) => (
            action.show && (
              <div
                key={key}
                onClick={action.onClick}
                className="flex p-2 text-sm cursor-pointer"
              >
                <img className="mr-4 w-5 h-5" src={action.icon} alt={action.text} />
                <Typography>{action.text}</Typography>
              </div>
            )
          ))}
        </div>
      </Dialog>
    </div>
  )
}

export default CommunityMembers;