import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
}));

function ShowMore({ children }) {
  const classes = useStyles();
  return (
    <div>
      <button
        type="submit"
        className={clsx(classes.button, "rounded-md p-2 px-10 py-2 text-md")}
      >
        {children}
      </button>
    </div>
  );
}

export default ShowMore;
