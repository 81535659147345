import api from "@apiClient";
import MyAvatar from "@components/admin/MyAvatar";
import { ArrowBack, MoreHoriz } from "@mui/icons-material";
import { CircularProgress, IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommunityGroupsTab from "./community-detail-tabs/CommunityGroupsTab";
import CommunityMembersTab from "./community-detail-tabs/CommunityMembersTab";

export default function CommunityDetail({ id, setId, setCommunitiesReload }) {
  const [community, setCommunity] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newVal) => {
    setTabValue(newVal)
  };

  const fetchData = () => {
    api
      .getCommunityDetail(id)
      .then(setCommunity)
      .catch(() => {});
  }

  useEffect(() => {
    if (id)
      fetchData();
  }, [id]);

  const [openGroupDetails, setOpenGroupDetails] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");

  if (!community)
    return <CircularProgress />;

  // Declaring this and passing to child components to arrange relative to child component
  const PassedTabs = (
    <Tabs
      value={tabValue}
      onChange={handleChangeTab}
    >
      <Tab label="Groups" value={0} />
      <Tab label="Members" value={1} />
    </Tabs>
  )

  return (
    <div className="mx-12">
      {tabValue === 0 && (
        <CommunityGroupsTab
          id={id}
          setId={setId}
          community={community}
          tabs={PassedTabs}
          communityRefresh={fetchData}
        />
      )}
      {tabValue === 1 && (
        <CommunityMembersTab
          id={id}
          setId={setId}
          community={community}
          tabs={PassedTabs}
          communityRefresh={fetchData}
          setCommunitiesReload={setCommunitiesReload}
        />
      )}
    </div>
  );
}
