import React from "react";
import axios from "./MyAxios";
import { GET_LOGGED_USER } from "./constants/api";
import { setUserRecord } from "./redux/userSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const AppUser = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getUser = async () => {
      try {
        await axios.get(GET_LOGGED_USER).then((res) => {
          dispatch(setUserRecord(res.data));
        });
      } catch (error) {
        console.log("No user found. Error in app user");
      }
    };
    const token = localStorage.token;
    if (token) getUser();
  }, [dispatch]);

  return <div>{children}</div>;
};

export default AppUser;
