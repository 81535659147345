import React, { useEffect, useState } from "react";
import { Avatar, Badge, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import axios from "../../MyAxios";
import {
  GET_USER_CHAT,
  ROOT,
  GET_USERS_ROOMS,
  NEW_CHAT,
  DELETE_CHAT,
  ADD_CONNECTION,
  REPORT_FORM,
  REQUEST_CONNECTION,
} from "../../constants/api";
import { MoreHoriz } from "@mui/icons-material";
import MyModal from "../../components/Modal";
import Layout from "../../Layout";
import Title from "../../components/Title";
import newChat from "../../assets/img/newChat.svg";
import { Link, useNavigate } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";
import { useSelector } from "react-redux";
import connect from "@images/connect.svg";
import deleteimg from "@images/delete.svg";
import block from "@images/block.svg";
import reportGreen from "@images/reportGreen.svg";
import GroupModal from "@components/GroupModal";
import { toast } from "react-toastify";
import { trunc } from "@helpers";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function Chats(res) {
  useLoggedIn();
  const classes = useStyles();
  const navigation = useNavigate();
  const [rooms, setRooms] = useState([]);
  const [open, setOpen] = useState(false);
  const { userRecord } = useSelector((state) => state.user);
  const [selectedUser, setSelectedUser] = useState();
  const theme = useTheme();

  const getUsers = async () => {
    console.log("Getting users");
    try {
      await axios.get(GET_USERS_ROOMS).then((res) => {
        setRooms(res.data);
      });
    } catch (error) {}
  };
  useEffect(() => {
    getUsers();
  }, []);

  const userChat = (user) => {
    try {
      console.log(user);
      navigation(`${GET_USER_CHAT}/${user.id}`);
    } catch (error) {}
  };

  const displayReciever = (user) => {
    return userRecord?.id === user.id;
  };

  const openModal = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const options = [
    {
      id: 1,
      name: "Connect",
      img: connect,
      action: async (item) => {
        try {
          const res = await axios.post(REQUEST_CONNECTION, {
            forUser: selectedUser?.id,
          });
          toast.success("Connection request sent successfully");
          console.log(res);
        } catch (error) {
          console.log(error.response.data);
        } finally {
          setOpen(false);
        }
      },
    },
    {
      id: 2,
      name: "Delete",
      img: deleteimg,
      action: async (item) => {
        console.log(selectedUser);
        try {
          const res = await axios.post(DELETE_CHAT, {
            connectionId: selectedUser?.id,
          });
          setRooms(
            rooms.filter(
              (room) =>
                room?.userOne?.id !== selectedUser?.id ||
                room?.userTwo?.id !== selectedUser?.id
            )
          );
          console.log(res);
          toast.success("Chat deleted successfully");
          setOpen(false);
        } catch (error) {
          console.log(error.response.data);
        }
      },
    },
    {
      id: 3,
      name: "Block",
      img: block,
    },
    {
      id: 4,
      name: "Report",
      img: reportGreen,
      action: () => {
        navigation(REPORT_FORM, {
          state: { id: selectedUser?.id, report: "user" },
        });
      },
    },
  ];

  return (
    <Layout>
      <Title back={ROOT}>Messages</Title>
      <div className=" flex flex-col items-center p-2">
        {rooms?.map((room) => (
          <div
            key={room?.id}
            onClick={() =>
              displayReciever(room?.userOne)
                ? userChat(room.userTwo)
                : userChat(room.userOne)
            }
            className=" flex w-full flex-row items-center border-b-2 p-2 cursor-pointer"
          >
            <div>
              <Avatar
                className=" h-10 w-10 rounded-full"
                src={
                  displayReciever(room?.userOne)
                    ? room?.userTwo?.media?.avatarUrl
                    : room?.userOne?.media?.avatarUrl
                }
              />
            </div>
            <div className="flex w-full justify-between p-2 pl-4">
              <div className="relative">
                {room?.unreadCount > 0 && (
                  <Badge
                    style={{ right: -30, top: 10 }}
                    className="absolute"
                    color="primary"
                    badgeContent={`${room?.unreadCount}`}
                  ></Badge>
                )}
                <Typography
                  className={clsx(classes.textGrey, "text-xs font-bold")}
                >
                  {displayReciever(room?.userOne)
                    ? room?.userTwo?.userName
                    : room?.userOne?.userName}{" "}
                </Typography>
                <Typography className={clsx(classes.textGrey, " leading-none")}>
                  {room?.lastMessage && (
                    <div>
                      {room?.lastMessage?.message ? (
                        <small>{trunc(room?.lastMessage?.message, 80)}</small>
                      ) : (
                        <small
                          className={clsx(
                            classes.textColor,
                            " italic font-semibold"
                          )}
                        >
                          Attachment
                        </small>
                      )}
                    </div>
                  )}
                </Typography>
              </div>
              <MoreHoriz
                onClick={(e) => {
                  setSelectedUser(
                    displayReciever(room?.userOne)
                      ? room?.userTwo
                      : room?.userOne
                  );
                  openModal(e, room);
                }}
                sx={{ color: theme.palette.text.dark }}
                fontSize="large"
              />
            </div>
          </div>
        ))}
        {/* Change this to new chat */}
        <Link to={NEW_CHAT} className=" cursor-pointer fixed bottom-10 right-5">
          <Avatar className=" h-12 w-12" src={newChat} />
        </Link>
      </div>
      <GroupModal open={open} setOpen={setOpen} options={options} />
    </Layout>
  );
}

export default Chats;
