import { Box, Modal, Typography } from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  p: 2,
};

function GroupModal({ open, setOpen, options }) {
  const filteredOptions = options.filter((o) => !o.hide);
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={style}>
        {filteredOptions.map((option, key) => (
          <div
            onClick={option.action}
            key={key}
            className="flex py-2 text-sm cursor-pointer"
          >
            <img className="mr-4 w-5 h-5" src={option.img} alt="Connect" />
            <Typography>{option.name}</Typography>
          </div>
        ))}
      </Box>
    </Modal>
  );
}

export default GroupModal;
