import MyAvatar from "@components/admin/MyAvatar";
import { GROUP_INFO } from "@constants/api";
import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ShowMore from "./ShowMore";
import SearchItem from "./SearchItem.";

export default function GroupsList({ groups, page, totalPages, onShowMore }) {
  const navigate = useNavigate();
  const groupInfo = (id) => {
    navigate(`${GROUP_INFO}/${id}`);
  };
  return (
    <div>
      {groups?.length === 0 && (
        <Typography className="mt-2 text-center py-8">
          No groups found
        </Typography>
      )}
      {groups?.length > 0 && (
        <div className="pt-2 pb-8">
          {groups?.map((group, key) => (
            <SearchItem
              key={key}
              onClick={() => {
                groupInfo(group.id);
              }}
            >
              <MyAvatar
                key={key}
                name={group.name}
                src={group?.icon?.avatarUrl}
              />
            </SearchItem>
          ))}
          <ShowMore page={page} totalPages={totalPages} onClick={onShowMore} />
        </div>
      )}
    </div>
  );
}
