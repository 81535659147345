import React from "react";
import ShowMoreCTA from "@components/ShowMore";
export default function ShowMore({ page, totalPages, onClick }) {
  if (!page || !totalPages) return null;
  if (page >= totalPages) return null;
  return (
    <div className="flex justify-center mt-2" onClick={onClick}>
      <ShowMoreCTA>Show More</ShowMoreCTA>
    </div>
  );
}
