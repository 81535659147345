import { Modal, Button, Checkbox, Autocomplete, TextField, Typography } from "@mui/material";
import { ModalBox } from "@components/admin/StyledComponents";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import apiClient from "@apiClient";
import { toast } from "react-toastify";
import axios from "@MyAxios";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
  },
}));

export default function CommunityAssignAdmin({ open, setOpen, setReload, adminAssignCommunity }) {
  const classes = useStyles();

  const [selectedAdmin, setSelectedAdmin] = useState();
  const [usersList, setUsersList] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchTimeout, setSearchTimeout] = useState();

  const getUserLabel = (user) => {
    let userName = user.userName;

    let fullName = user.firstName;
    if(user.lastName)
      fullName += ' ' + user.lastName;

    let email = user.emailAddress;

    return `${userName} (${email})`;
  }

  const fetchUsers = () => {
    axios.get(`/admin/community/users`, { params: { communityId: adminAssignCommunity.id, searchText } })
      .then(res => {
        setUsersList(res.data);
      })
      .catch(error => {
        console.error(error);
        toast.error(error?.message ?? 'Error fetching users');
      })
  }

  const handleAssign = () => {
    if(!selectedAdmin)
      return toast.error('You need to select a user to assign as admin');

    apiClient.assignAdminToCommunity({ id: adminAssignCommunity.id, adminId: selectedAdmin.id })
      .then(res => {
        toast.success(res.data?.message ?? 'Community admin assigned successfully');
        setOpen(false);
        setReload((r) => r + 1);
      })
      .catch(error => {
        console.error(error);
      })
  }

  useEffect(() => {
    if(searchText !== null) {
      clearTimeout(searchTimeout);
      setSearchTimeout(setTimeout(fetchUsers, 300));
    }
  }, [searchText])

  useEffect(() => {
    if(adminAssignCommunity?.admin)
      setSelectedAdmin(adminAssignCommunity.admin);
  }, [adminAssignCommunity])

  const clearData = () => {
    setSelectedAdmin();
    setSearchText(null)
    setUsersList([]);
  }

  useEffect(() => {
    if(!open)
      clearData();
  }, [open])

  useEffect(() => {
    return () => {
      clearData();
    }
  }, [])

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalBox sx={{ p: 4 }} className="rounded-lg shadow-md">
        <div className="flex flex-col gap-2">

          {adminAssignCommunity?.admin && (
            <Typography className="mb-5">
              <strong>Current Admin:</strong> {getUserLabel(adminAssignCommunity?.admin)}
            </Typography>
          )}

          <Autocomplete
            autoComplete
            includeInputInList
            className="w-full"
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : getUserLabel(option)
            }
            filterOptions={(x) => x}
            options={usersList}
            value={selectedAdmin}
            noOptionsText="Please search a user by name or email"
            onChange={(event, user) => setSelectedAdmin(user)}
            onInputChange={(event, newSearchText, reason) => {
              if(reason === 'input')
                setSearchText(newSearchText);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Search users" fullWidth />
            )}
            renderOption={(props, option) => (
              <div {...props} key={option.id}>
                {getUserLabel(option)}
              </div>
            )}
          />

          <div className="flex w-full justify-end gap-2 mt-6">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="submit" variant="contained" onClick={handleAssign}>
              Assign
            </Button>
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
}
