import { MoreHoriz } from "@mui/icons-material";
import { Link, Typography } from "@mui/material";
import React from "react";
import link from "@images/resourceLink.svg";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import ResourceDate from "./ResourceDate";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
    borderColor: "#887E7E",
  },
  borderGreen: {
    borderWidth: 1,
    borderColor: "#51CDA0",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  bgGrey: {
    backgroundColor: "#efefef",
    color: "#000",
  },
}));

export default function TextResource({ resource, onMoreClick }) {
  const classes = useStyles();

  return (
    <div>
      <div className="flex justify-between">
        <div
          className={clsx(
            `leading-1.5 w-5/6 my-2 flex flex-col p-4 rounded-lg`,
            classes.bgGrey
          )}
        >
          <Typography className="font-semibold">{resource?.title}</Typography>
          <ResourceDate timestamp={resource?.createdAt} />
          <Typography className="font-normal mt-2">
            {resource?.description}
          </Typography>
          <div className="flex gap-2 items-center mt-1">
            <img src={link} className="mr-1 mt-1 self-start" alt="Link" />
            <Link
              fontSize={"14px"}
              href={
                resource?.link.includes("http")
                  ? resource?.link
                  : `//${resource?.link}`
              }
              target="_blank"
              color="primary"
              className="font-normal flex "
            >
              {resource?.link}
            </Link>
          </div>
        </div>
        <MoreHoriz
          className=" cursor-pointer"
          sx={{ color: "text.dark" }}
          onClick={() => onMoreClick()}
        />
      </div>
    </div>
  );
}
