import { CardMedia, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function ImageModal({ url, open, setOpen, type }) {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      style={{ backgroundColor: "black" }}
    >
      <div>
        <div
          style={{
            zIndex: 100,
            position: "absolute",
            left: "2%",
            top: "2%",
            backgroundColor: "#ffff",
          }}
          className="rounded-full"
        >
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon color="primary" />
          </IconButton>
        </div>
        {type === "video" ? (
          <CardMedia
            controlsList="nodownload"
            crossOrigin="anonymous"
            component={"video"}
            // className="object-contain w-1/2 h-1/2 mx-auto"
            image={url}
            alt="Stream Media"
            autoPlay
            controls
            muted
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "100vh",
              objectFit: "contain",
            }}
          />
        ) : (
          <img
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "100vh",
              objectFit: "contain",
            }}
            src={url}
            alt="images"
          />
        )}
      </div>
    </Modal>
  );
}
