import { useEffect, useState } from "react";
import axios from "../../MyAxios";
import Layout from "../../Layout";
import Title from "../../components/Title";
import useLoggedIn from "../../hooks/useLoggedIn";
import { GET_USER_POSTS } from "../../constants/api";
import Post from "@components/Post";
import { Typography } from "@mui/material";
import ShowMore from "@components/ShowMore";

const StreamManagement = () => {
  useLoggedIn();
  const [posts, setPosts] = useState([]);
  const [deletedId, setDeletedId] = useState();
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const removePost = (id) => {
    setPosts(posts.filter((post) => post.id !== id));
  };
  const getPosts = async () => {
    try {
      const res = await axios.get(`${GET_USER_POSTS}/${page}`);
      setPosts((prevPosts) => [...prevPosts, ...res.data.posts]);
      setTotalPages(res.data.totalPages);
    } catch (error) {}
  };

  useEffect(() => {
    getPosts();
  }, [deletedId, page]);

  return (
    <Layout>
      <Title back={"/"}>Stream Management</Title>
      {posts?.map((item, key) => (
        <div key={key}>
          <Post
            post={item}
            setDeletedId={setDeletedId}
            comment={false}
            removePost={() => removePost(item.id)}
          />
        </div>
      ))}
      {page < totalPages && (
        <div
          onClick={() => setPage(page + 1)}
          className="cursor-pointer my-2 text-center mx-4"
        >
          <ShowMore>Show More</ShowMore>
        </div>
      )}
    </Layout>
  );
};

export default StreamManagement;
