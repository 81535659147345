import { ArrowBack, Search } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";

export default function SearchBar({ value, setValue, onClick, onBack, placeholder = 'Search' }) {
  return (
    <div
      style={{ background: "white", position: "sticky", top: 0, zIndex: 100 }}
    >
      <TextField
        type="text"
        size="small"
        id="outlined-basic"
        fullWidth
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyUp={(e) => e.key === "Enter" && onClick()}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={onBack}>
                <ArrowBack />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onClick}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            borderRadius: 10,
            padding: 0.2,
          },
        }}
      />
    </div>
  );
}
