import axios from '@MyAxios'
import { updateToast } from '@helpers';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

export const getCommunity = createAsyncThunk(
  'community/getCommunity',
  async (communityId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/get-community/${communityId}`);
      return response.data;

    } catch (error) {
      return rejectWithValue(error);      
    }
  }
)

export const getCommunityMembers = createAsyncThunk(
  'community/getCommunityMembers',
  async ({ communityId, page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/get-community-members/${communityId}`, { params: { page } });
      return response.data;

    } catch (error) {
      return rejectWithValue(error);      
    }
  }
)

export const getCommunityGroups = createAsyncThunk(
  'community/getCommunityGroups',
  async ({ communityId, page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/get-community-groups/${communityId}`, { params: { page } });
      return response.data;

    } catch (error) {
      return rejectWithValue(error);      
    }
  }
)

export const joinCommunity = createAsyncThunk(
  'community/joinCommunity',
  async ({ communityId, onSuccess }, { rejectWithValue }) => {
    try {
      toast.loading('Joining community...', { toastId: 'join-community' });
      const response = await axios.get(`/join-community/${communityId}`);
      onSuccess?.();
      return response.data;

    } catch (error) {
      return rejectWithValue(error);      
    }
  }
)

export const getGlobalComAdminAccessLevels = createAsyncThunk(
  'community/getGlobalComAdminAccessLevels',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/communities/global-admin-access-levels`);
      return response.data;

    } catch (error) {
      return rejectWithValue(error);      
    }
  }
)

export const updateGlobalComAdminAccessLevels = createAsyncThunk(
  'community/updateGlobalComAdminAccessLevels',
  async (data, { rejectWithValue }) => {
    try {
      toast.loading('Updating access levels...', { toastId: 'update-global-admin-access-levels' });
      const response = await axios.post(`/admin/communities/update-global-admin-access-levels`, data);
      return response.data;

    } catch (error) {
      return rejectWithValue(error);      
    }
  }
)

export const removeCommunityUser = createAsyncThunk(
  'community/removeCommunityUser',
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      toast.loading('Removing member...', { toastId: 'remove-community-user' });
      const response = await axios.get(`/community/remove-user`, { params: data });
      onSuccess?.();
      return response.data;

    } catch (error) {
      return rejectWithValue(error);      
    }
  }
)

export const removeCommunityGroup = createAsyncThunk(
  'community/removeCommunityGroup',
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      toast.loading('Removing group...', { toastId: 'remove-community-group' });
      const response = await axios.get(`/community/remove-group`, { params: data });
      onSuccess?.();
      return response.data;

    } catch (error) {
      return rejectWithValue(error);      
    }
  }
)

export const removeCommunityGroupPost = createAsyncThunk(
  'community/removeCommunityGroupPost',
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      toast.loading('Removing group post...', { toastId: 'remove-community-group-post' });
      const response = await axios.get(`/community/remove-group-post`, { params: data });
      onSuccess?.();
      return response.data;

    } catch (error) {
      return rejectWithValue(error);      
    }
  }
)

export const CommunitySlice = createSlice({
  name: 'community',
  initialState: {
    community: null,
    communityMembers: null,
    communityGroups: null,
    maxPage: 0,
    globalCommunityAdminAccessLevels: null,
  },
  reducers: {
    setCommunity: (state, action) => {
      state.community = action.payload;
    },
    setCommunityMembers: (state, action) => {
      state.communityMembers = action.payload;
    },
    setCommunityGroups: (state, action) => {
      state.communityGroups = action.payload;
    },
    setGlobalCommunityAdminAccessLevels: (state, action) => {
      state.globalCommunityAdminAccessLevels = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getCommunity.fulfilled, (state, action) => {
      state.community = action.payload;
    });
    builder.addCase(getCommunity.rejected, (state, action) => {
      // toast.error(action.payload?.response?.data?.message ?? 'Error fetching community');
    });

    builder.addCase(getCommunityMembers.fulfilled, (state, action) => {
      state.maxPage = action.payload?.maxPage;

      if(!state.communityMembers?.length)
        state.communityMembers = action.payload?.communityUsers;
      else
        state.communityMembers = state.communityMembers.concat(action.payload?.communityUsers);
    });
    builder.addCase(getCommunityMembers.rejected, (state, action) => {
      // toast.error(action.payload?.response?.data?.message ?? 'Error fetching community members');
    });

    builder.addCase(getCommunityGroups.fulfilled, (state, action) => {
      state.maxPage = action.payload?.maxPage;

      if(!state.communityGroups?.length)
        state.communityGroups = action.payload?.communityGroups;
      else
        state.communityGroups = state.communityGroups.concat(action.payload?.communityGroups);
    });
    builder.addCase(getCommunityGroups.rejected, (state, action) => {
      // toast.error(action.payload?.response?.data?.message ?? 'Error fetching community groups');
    });

    builder.addCase(joinCommunity.fulfilled, (state, action) => {
      updateToast(action.payload?.message ?? 'Community successfully joined', 'success', 'join-community');
    });
    builder.addCase(joinCommunity.rejected, (state, action) => {
      updateToast(action.payload?.message ?? 'Error joining community', 'error', 'join-community');
    });

    builder.addCase(getGlobalComAdminAccessLevels.fulfilled, (state, action) => {
      state.globalCommunityAdminAccessLevels = action.payload;
    });
    builder.addCase(getGlobalComAdminAccessLevels.rejected, (state, action) => {
      // toast.error(action.payload?.message ?? 'Error retrieving admin access levels');
    });

    builder.addCase(updateGlobalComAdminAccessLevels.fulfilled, (state, action) => {
      updateToast(action.payload?.message ?? 'Access levels updated successfully', 'success', 'update-global-admin-access-levels');
    });
    builder.addCase(updateGlobalComAdminAccessLevels.rejected, (state, action) => {
      toast.dismiss('update-global-admin-access-levels');
    });

    builder.addCase(removeCommunityUser.fulfilled, (state, action) => {
      updateToast(action.payload?.message ?? 'Community user removed successfully', 'success', 'remove-community-user');
    });
    builder.addCase(removeCommunityUser.rejected, (state, action) => {
      toast.dismiss('remove-community-user');
    });

    builder.addCase(removeCommunityGroup.fulfilled, (state, action) => {
      updateToast(action.payload?.message ?? 'Community group removed successfully', 'success', 'remove-community-group');
    });
    builder.addCase(removeCommunityGroup.rejected, (state, action) => {
      toast.dismiss('remove-community-group');
    });

    builder.addCase(removeCommunityGroupPost.fulfilled, (state, action) => {
      updateToast(action.payload?.message ?? 'Group post removed successfully', 'success', 'remove-community-group-post');
    });
    builder.addCase(removeCommunityGroupPost.rejected, (state, action) => {
      toast.dismiss('remove-community-group-post');
    });
  }
})

// Action creators are generated for each case reducer function
export const { setCommunity, setCommunityMembers, setCommunityGroups, setGlobalCommunityAdminAccessLevels } = CommunitySlice.actions

export default CommunitySlice.reducer;