import api from "@apiClient";
import MyAvatar from "@components/admin/MyAvatar";
import { ArrowBack, MoreHoriz } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@images/edit.svg";
import Table from "@components/admin/Table";
import useTable from "@hooks/useTable";
import useActionFilters from "@hooks/useActionFilters";
import { getSelectedItems } from "@helpers";
import SearchBar from "@components/admin/SearchBar";
import PageNavigator from "@components/admin/PageNavigator";
import GroupDetailModal from "@components/admin/GroupDetailModal";
import clsx from "clsx";
import PopperList from "@components/admin/PopperList";
import PopperWrapper from "@components/admin/PopperWrapper";
import CommunityUpdateForm from "@components/admin/CommunityUpdateForm";

export default function CommunityGroupsTab({ id, setId, community, tabs, communityRefresh }) {
  const [openUpdateForm, setOpenUpdateForm] = useState(false);

  const {
    setReload,
    reload,
    setPage,
    page,
    totalPages,
    query,
    setQuery,
    data,
    checked,
    setChecked,
    setLimit,
    limit,
    setSortColumns,
    sortColumns,
    loading,
    setFilters,
  } = useTable({
    getData: api.getGroups,
    pageSize: 10,
    initialFilters: { community: id },
  });

  const [openGroupDetails, setOpenGroupDetails] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");

  const { getActions, getActionButtons, getConfirmationModals } =
    useActionFilters({
      apiDelete: api.deleteGroups,
      apiDisable: api.disableGroups,
      apiReinstate: api.reinstateGroups,
      setFilters,
      getSelectedItems: () => getSelectedItems(checked),
      setReload,
      customActions: [
        {
          label: "View Details",
          generateClickHandler: (item) => {
            return () => {
              setSelectedGroup(item);
              setOpenGroupDetails(true);
            };
          },
        },
      ],
    });

  // PROPS FOR TABLE
  const fields = [
    {
      columnName: "Name",
      field: "name",
      render: (item) => (
        <MyAvatar src={item.icon?.avatarUrl} name={item.name} />
      ),
    },
    {
      columnName: "Admin",
      render: (item) => item.admin?.firstName + " " + item.admin?.lastName,
    },
    {
      columnName: "Admin Username",
      render: (item) => item.admin?.userName,
    },
    {
      columnName: "Community",
      render: (item) => item.community?.name,
    },
    {
      columnName: "Members",
      render: (item) => item.members?.length,
    },
    {
      columnName: "Created At",
      field: "createdAt",
      render: (item) => new Date(item.createdAt).toLocaleDateString("en-GB"),
    },
    {
      columnName: "Actions",
      render: (item) => (
        <PopperWrapper id={item.id}>
          <IconButton>
            <MoreHoriz />
          </IconButton>
          <PopperList items={getActions(item)} />
        </PopperWrapper>
      ),
    },
  ];

  if (loading) return <CircularProgress />;
  return (
    <div>
      <div className="flex items-center justify-between py-4">
        <div className="flex gap-2">
          <IconButton className="self-center" onClick={() => setId(null)}>
            <ArrowBack />
          </IconButton>
          <MyAvatar
            name={community.name}
            subtext={`${community?.groups?.length} groups`}
            src={community?.icon?.avatarUrl}
            subtextProps={{ style: { marginLeft: 5 } }}
            textProps={{
              className: "font-medium text-lg",
              style: { marginLeft: 5 },
            }}
            sx={{ height: 60, width: 60 }}
          />
          <IconButton
            className="self-end"
            onClick={() => setOpenUpdateForm(true)}
          >
            <img src={EditIcon} alt="edit" />
          </IconButton>

        </div>

        <SearchBar setQuery={setQuery} query={query} />

        {tabs}

        <div
          className={clsx(
            Object.values(checked).filter((val) => val).length === 0 &&
              "hidden",
            "ml-auto flex items-center gap-2"
          )}
        >
          {getActionButtons()}
        </div>
      </div>
      <Table
        data={data}
        fields={fields}
        checked={checked}
        setChecked={setChecked}
        setSortColumns={setSortColumns}
        sortColumns={sortColumns}
      />
      <PageNavigator
        totalPages={totalPages}
        setPage={setPage}
        page={page}
        setLimit={setLimit}
        limit={limit}
      />
      {/* Confirmation Modals */}
      {getConfirmationModals()}
      <GroupDetailModal
        open={openGroupDetails}
        setOpen={setOpenGroupDetails}
        group={selectedGroup}
        setReload={setReload}
      />
      <CommunityUpdateForm
        open={openUpdateForm}
        setOpen={setOpenUpdateForm}
        community={community}
        setReload={setReload}
        communityRefresh={communityRefresh}
      />
    </div>
  );
}
