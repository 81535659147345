import { IconButton, Modal } from "@mui/material";
import Button from "./Button";

import { ModalBox } from "@components/admin/StyledComponents";
import MyAvatar from "../MyAvatar";
import { formatDate } from "@helpers";
import DetailRow from "../DetailRow";
import { useEffect, useState } from "react";
import ReportCount from "./ReportCount";
import { Close as CloseIcon } from "@mui/icons-material";

export default function GroupReportDetail({
  setGroupReport,
  groupReport,
  actionButtons,
}) {
  const [open, setOpen] = useState(false);
  const admin = groupReport?.groupReported?.admin;
  const reporter = groupReport?.reportedBy;
  const group = groupReport?.groupReported;
  useEffect(() => {
    if (groupReport) setOpen(true);
  }, [groupReport]);
  if (!groupReport) return <div></div>;

  const hanldeDialogClose = () => {
    setGroupReport(null);
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={hanldeDialogClose}
    >
      <ModalBox className=" shadow-md overflow-auto">
        <div className="flex justify-between items-start">
          <div className="flex gap-6">
            <MyAvatar
              src={group?.icon?.avatarUrl}
              name={group?.name}
              subtext={`${group.memberCount} group members`}
              textProps={{ className: "font-bold text-gray-600" }}
            />
            <ReportCount count={groupReport?.reportCount} />
          </div>

          <IconButton onClick={hanldeDialogClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div>
          <table className="w-full text-left text-sm mt-5">
            <tbody>
              <DetailRow
                label="Admin:"
                value={admin?.firstName + " " + admin?.lastName}
              />
              <DetailRow label="Admin Username:" value={admin?.userName} />

              <DetailRow label="Admin Email:" value={admin?.emailAddress} />
              <DetailRow label="Community:" value={group?.community?.name} />
              <DetailRow
                label="Reporter:"
                value={reporter?.emailAddress}
              />
              <DetailRow
                label="Reporter Username:"
                value={reporter?.userName}
              />
              <DetailRow
                label="Reported On:"
                value={formatDate(groupReport?.createdAt)}
              />
              <DetailRow
                label="Report Description:"
                value={groupReport?.reason}
              />
            </tbody>
          </table>
          <div className="flex gap-2 mt-4 justify-end">
            {actionButtons?.map((button, index) => (
              <Button variant="contained" key={index} {...button} />
            ))}
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
}
