import axios from '@MyAxios';
import { updateToast } from '@helpers';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

export const getGlobalGroupModeratorAccessLevels = createAsyncThunk(
  'community/getGlobalGroupModeratorAccessLevels',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/groups/global-moderator-access-levels`);
      return response.data;

    } catch (error) {
      return rejectWithValue(error);      
    }
  }
)

export const updateGlobalGroupModeratorAccessLevels = createAsyncThunk(
  'community/updateGlobalGroupModeratorAccessLevels',
  async (data, { rejectWithValue }) => {
    try {
      toast.loading('Updating access levels...', { toastId: 'update-global-group-moderator-access-levels' });
      const response = await axios.post(`/admin/groups/update-global-moderator-access-levels`, data);
      return response.data;

    } catch (error) {
      return rejectWithValue(error);      
    }
  }
)

export const GroupSlice = createSlice({
  name: 'group',
  initialState: {
    community: '',
    globalGroupModeratorAccessLevels: null,
  },
  reducers: {
    setCommunity: (state, action) => {
      state.community = action.payload
    },
    setGlobalGroupModeratorAccessLevels: (state, action) => {
      state.globalGroupModeratorAccessLevels = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getGlobalGroupModeratorAccessLevels.fulfilled, (state, action) => {
      state.globalGroupModeratorAccessLevels = action.payload;
    });
    builder.addCase(getGlobalGroupModeratorAccessLevels.rejected, (state, action) => {
      // toast.error(action.payload?.message ?? 'Error retrieving admin access levels');
    });


    builder.addCase(updateGlobalGroupModeratorAccessLevels.fulfilled, (state, action) => {
      updateToast(action.payload?.message ?? 'Access levels updated successfully', 'success', 'update-global-group-moderator-access-levels');
    });
    builder.addCase(updateGlobalGroupModeratorAccessLevels.rejected, (state, action) => {
      toast.dismiss('update-global-group-moderator-access-levels');
    });
  }
})

// Action creators are generated for each case reducer function
export const { setCommunity, setGlobalGroupModeratorAccessLevels } = GroupSlice.actions

export default GroupSlice.reducer
