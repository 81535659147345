import React, { useState } from 'react'
import { IconButton, Modal, Typography } from '@mui/material'
import { ModalBox } from "@components/admin/StyledComponents";
import { Close as CloseIcon } from '@mui/icons-material';
import { formatDate } from '@helpers';
import MyAvatar from './MyAvatar';
import DetailRow from './DetailRow';
import { toast } from 'react-toastify';
import Button from './Reports/Button';
import axios from '@MyAxios';

export default function BanUserDetails({
  open,
  onClose,
  user,
  onSuccess
}) {
  const [reason, setReason] = useState('');

  const handleSubmit = () => {
    if(!reason)
      return toast.error('You need to provide a reason');

    axios.get('/admin/users/ban-globally', { params: {
      id: user.id,
      reason,
    }})
      .then(res => {
        toast.success(res.data?.message ?? 'User banned successfully');
        onSuccess();
      })
      .catch(() => {});
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <ModalBox className="shadow-md overflow-auto">
        <div className="flex justify-between items-start">
          <div className="flex gap-6">
            <MyAvatar
              src={user?.media?.avatarUrl}
              name={`${user?.firstName} ${user?.lastName}`}
              subtext={`@${user?.userName ?? `[Deleted Post]`}`}
              textProps={{ className: "font-bold text-gray-600" }}
            />
          </div>

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className='mt-4'>
          <Typography className='text-md'>
            Enter reason for banning:
          </Typography>

          <textarea
            rows={3}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="border-gray-200 border-2 rounded-md mt-5 w-full p-2"
          />

          <div className='flex justify-end mt-4'>
            <Button variant="contained" color="error" onClick={handleSubmit}>
              Ban User
            </Button>
          </div>
        </div>
      </ModalBox>
    </Modal>
  )
}