import { IconButton, CircularProgress } from "@mui/material";
import { ArrowBack, MoreHoriz } from "@mui/icons-material";
import Table from "@components/admin/Table";
import PageNavigator from "@components/admin/PageNavigator";
import SearchBar from "@components/admin/SearchBar";
import apiClient from "@apiClient";
import useSetActiveLink from "@hooks/useSetActiveLink";
import useTable from "@hooks/useTable";
import MyAvatar from "@components/admin/MyAvatar";
import DateFilter from "@components/admin/DateFilter";
import clsx from "clsx";
import PopperWrapper from "@components/admin/PopperWrapper";
import PopperList from "@components/admin/PopperList";
import { useState } from "react";
import SideOptions from "@components/admin/SideOptions";
import useAdminLoggedIn from "@hooks/useAdminLoggedIn";
import MailAvatar from "@images/mailAvatar.svg";
import GoogleAvatar from "@images/googleAvatar.svg";
import FacebookAvatar from "@images/facebookAvatar.svg";
import AppleAvatar from "@images/appleAvatar.svg";
import FeedBackIcon from "@images/feedback.svg";
import UsersIcon from "@images/users.svg";
import useDateFilters from "@hooks/useDateFilters";
import useActionFilters from "@hooks/useActionFilters";
import { createDateColumn, getSelectedItems } from "@helpers";
import CommunityUpdateForm from "@components/admin/CommunityUpdateForm";
import EditIcon from "@images/edit.svg";
import ConfirmationModal from "@components/admin/ConfirmationModal";
import { toast } from "react-toastify";

const providerAvatars = {
  MAIL: MailAvatar,
  GOOGLE: GoogleAvatar,
  FACEBOOK: FacebookAvatar,
  APPLE: AppleAvatar,
};

function CommunityMembersTab({ id, setId, community, tabs, communityRefresh, setCommunitiesReload }) {
  const [openUpdateForm, setOpenUpdateForm] = useState(false);

  const {
    setReload,
    setPage,
    page,
    totalPages,
    count,
    query,
    setQuery,
    data,
    checked,
    setChecked,
    setLimit,
    limit,
    setSortColumns,
    sortColumns,
    loading,
    setDateFilters,
    setFilters,
  } = useTable({
    getData: (defaultParams) => apiClient.getCommunityUsers({ ...defaultParams, communityId: community.id }),
    pageSize: 10,
  });
  const [showFeedback, setShowFeedBack] = useState(false);
  const [userToRemove, setUserToRemove] = useState();

  const {
    activeFilter,
    getActions,
    getActionLinks,
    setActiveFilter,
    getActionButtons,
    getConfirmationModals,
  } = useActionFilters({
    apiDelete: apiClient.deleteUsers,
    apiDisable: apiClient.disableUsers,
    apiReinstate: apiClient.reinstateUsers,
    setFilters,
    onLinkClick: () => {
      setShowFeedBack(false);
    },
    getSelectedItems: () => getSelectedItems(checked),
    setReload,
  });

  // PROPS FOR FILTERS
  const { dateFilters, activeDateFilter } = useDateFilters({
    setDateFilters,
    prefix: "Joined",
    activeFilter: activeFilter,
  });

  const handleRemoveFromCommunityClick = (user) => {
    setUserToRemove(user);
  }

  const handleRemoveFromCommunity = () => {
    if(!userToRemove)
      return toast.error('No member selected');

    apiClient.removeMemberFromCommunity({ userId: userToRemove.id, communityId: community?.id })
      .then(response => {
        toast.success(response.data?.message ?? 'Member removed successfully');
        setReload(r => r + 1);
        setCommunitiesReload?.();
        setUserToRemove();
      })
      .catch(error => {
        console.error(error);
      })
  }

  const handleCloseDialog = () => {
    setUserToRemove();
  }

  // PROPS FOR TABLE
  const fields = [
    {
      columnName: "Name",
      field: "firstName",
      render: (item) => (
        <MyAvatar
          src={item.media?.avatarUrl}
          name={`${item.firstName} ${item.lastName}`}
        />
      ),
    },
    {
      columnName: "Username",
      field: "userName",
      render: (item) => item.userName,
    },
    {
      columnName: "Email",
      field: "emailAddress",
      render: (item) => item.emailAddress,
    },
    {
      columnName: "Provider",
      render: (item) => (
        <img
          src={providerAvatars[item.provider] || providerAvatars["MAIL"]}
          alt="provider"
        />
      ),
    },
    createDateColumn(activeFilter),
    {
      columnName: "Actions",
      render: (item) => (
        <PopperWrapper id={item.id}>
          <IconButton>
            <MoreHoriz />
          </IconButton>
          <PopperList
            items={[
              {
                label: "Remove from community",
                onClick: () => handleRemoveFromCommunityClick(item),
              }
            ]}
          />
        </PopperWrapper>
      ),
    },
  ];

  // Side Options Links
  const links = [
    ...getActionLinks(),
    {
      text: "Feedback",
      icon: <img src={FeedBackIcon} alt="feedback" />,
      onClick: () => {
        setShowFeedBack(true);
        setActiveFilter("Feedback");
      },
    },
  ];

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="grow py-4">
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <IconButton className="self-center" onClick={() => setId(null)}>
            <ArrowBack />
          </IconButton>
          <MyAvatar
            name={community.name}
            subtext={`${community?.users?.length} users`}
            src={community?.icon?.avatarUrl}
            subtextProps={{ style: { marginLeft: 5 } }}
            textProps={{
              className: "font-medium text-lg",
              style: { marginLeft: 5 },
            }}
            sx={{ height: 60, width: 60 }}
          />
          <IconButton
            className="self-end"
            onClick={() => setOpenUpdateForm(true)}
          >
            <img src={EditIcon} alt="edit" />
          </IconButton>
        </div>

        <SearchBar setQuery={setQuery} query={query} />

        {tabs}
      </div>
      <Table
        data={data}
        fields={fields}
        checked={checked}
        setChecked={setChecked}
        setSortColumns={setSortColumns}
        sortColumns={sortColumns}
      />

      <CommunityUpdateForm
        open={openUpdateForm}
        setOpen={setOpenUpdateForm}
        community={community}
        setReload={setReload}
        communityRefresh={communityRefresh}
      />

      {data?.length > 0 && (
        <PageNavigator
          totalPages={totalPages}
          setPage={setPage}
          page={page}
          setLimit={setLimit}
          limit={limit}
        />
      )}

      <ConfirmationModal
        open={Boolean(userToRemove)}
        setOpen={handleCloseDialog}
        buttons={[
          {
            variant: "contained",
            onClick: handleRemoveFromCommunity,
            children: "Delete",
            color: "error",
          },
        ]}
      >
        Are you sure you want to remove this member from community?
      </ConfirmationModal>
    </div>
  );
}

export default CommunityMembersTab;
