import clsx from "clsx";
import React, { useRef, useState } from "react";
import AddPlus from "@images/addPlus.svg";
import { toast } from "react-toastify";
import AttachementIcon from "@images/attachment.svg";
import FileIcon from "@images/fileIcon.svg";
import { Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { trunc } from "@helpers";
export default function TagsForm({ setTags, tags }) {
  const [tag, setTag] = useState();
  const [file, setFile] = useState(null);
  const fileInput = useRef(null);

  const onAddTag = () => {
    if (file) {
      getTagsFromCSV(file);
      setFile(null);
      return;
    }
    if (tags.find((t) => t.subcategory === tag)) {
      toast.error("Tag already exists");
      return;
    }
    setTags((tags) => [...tags, { subcategory: tag }]);
    setTag("");
  };

  const onFileChange = async (e) => {
    console.log("file change");
    const file = e.target.files[0];
    if (!file) return;
    setFile(file);
  };

  const onFileRemove = () => {
    setFile(null);
    fileInput.current.value = null;
  };

  const getTagsFromCSV = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      const tags = content
        .split(",")
        .map((tag) => ({ subcategory: tag.trim() }));
      setTags([...tags]);
    };
    reader.readAsText(file);
  };

  return (
    <div className="input-wrapper flex flex-col">
      <label className="mb-2 font-medium">Tags:</label>
      <div className="flex gap-2 items-center relative">
        <input
          disabled={file}
          style={{
            borderWidth: 2,
          }}
          className={clsx("rounded-md py-3 px-8", file && "pt-20")}
          placeholder="Tag"
          type="text"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        />
        {file && (
          <div
            style={{
              backgroundColor: "rgba(98, 98, 98, 0.1)",
            }}
            className="absolute left-2 top-2 p-2 rounded-md flex gap-2 items-center"
          >
            <img src={FileIcon} alt="file" />
            <Tooltip title={file.name} arrow>
              <div>{trunc(file.name, 13)}</div>
            </Tooltip>
            <div onClick={onFileRemove} className="cursor-pointer">
              <Close sx={{ fontSize: "20px" }} color="primary" />
            </div>
          </div>
        )}
        <img
          onClick={() => fileInput.current.click()}
          className={clsx("absolute left-2 cursor-pointer", file && "top-20")}
          src={AttachementIcon}
          alt="attachment"
        />
        <input
          type="file"
          accept=".csv"
          className={clsx("hidden")}
          multiple={false}
          ref={fileInput}
          onChange={onFileChange}
        />
        <div onClick={onAddTag} className="cursor-pointer">
          <img src={AddPlus} alt="add" />
        </div>
      </div>
    </div>
  );
}
