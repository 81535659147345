import React, { useEffect, useState } from "react";
import { Checkbox, Radio, TextField } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { addToGroups } from "../../redux/postSlice";

import useLoggedIn from "../../hooks/useLoggedIn";
import MyAvatar from "@components/admin/MyAvatar";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  bgGrey: {
    backgroundColor: "#efefef",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function SelectGroup({ groups }) {
  useLoggedIn();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedGroups } = useSelector((state) => state.post);

  const [searchText, setSearchText] = useState('');
  const [groupsToShow, setGroupsToShow] = useState([]);

  const isSelected = (id) => {
    return selectedGroups.includes(id);
  };

  useEffect(() => {
    if(groups) {
      let tempGroups = [ ...groups ];

      if(searchText) {
        let smallCaseSearch = searchText.toLowerCase();
        tempGroups = tempGroups.filter(group => group.name?.toLowerCase()?.includes( smallCaseSearch ));
      }

      setGroupsToShow(tempGroups);
    }
  }, [groups, searchText])

  return (
    <div>
      <div className="flex flex-col mt-2 mx-6 gap-2">
        <TextField
          size="small"
          label="Search"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          InputProps={{ sx: { borderRadius: 9999 } }}
        />

        <div className="max-h-[150px] overflow-y-auto flex flex-col gap-2">
          {groupsToShow?.map((group, key) => (
            <div key={key} className="flex justify-between">
              <MyAvatar
                src={group.icon?.avatarUrl}
                name={group.name}
                avatarSx={{ width: 30, height: 30, fontSize: 16 }}
                textProps={{ style: {fontSize: 14} }}
              />
              <label className={clsx(classes.textGrey)}>
                <Radio
                  className="py-1"
                  sx={{ color: "green" }}
                  checked={isSelected(group.id)}
                  onChange={(e) => dispatch(addToGroups(group.id))}
                />
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SelectGroup;
