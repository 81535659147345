import socketIOClient from "socket.io-client";
import sailsIOClient from "sails.io.js";

const URL = process.env.REACT_APP_URL ?? "http://localhost:1337";

console.log("creating socket client");
let io = sailsIOClient(socketIOClient);
io.sails.url = URL;
io.socket.on("connect", () => console.log("socket connected"));

io.socket.on("disconnect", () => console.log("socket disconnected"));

io.socket.on("reconnecting", function () {
  console.log("socket reconnecting!");
});

io.socket.on("reconnect", function () {
  console.log("socket reconnect!");
});
export default io;
