import React from "react";
import { Typography } from "@mui/material";

export default function ResourceDate({ timestamp }) {
  const formatDate = (timestamp) => {
    var options = { day: "2-digit", month: "long", year: "numeric" };
    return new Date(timestamp).toLocaleDateString("en-US", options);
  };
  return (
    <Typography fontSize={"12px"} sx={{ opacity: 0.7 }} className="mb-2 ">
      {formatDate(timestamp)}
    </Typography>
  );
}
