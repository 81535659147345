import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  activeLink: {
    color: theme.palette.primary.main,
    backgroundColor: "#F6FCFA",
  },
}));

function Link({ text, icon, onClick, active }) {
  const classes = useStyles();
  return (
    <div>
      <div
        onClick={onClick}
        className={clsx(
          active && classes.activeLink,
          "flex cursor-pointer items-center pl-10 py-4 relative pr-20"
        )}
      >
        {active && (
          <div
            style={{
              borderLeft: `6px solid #51CDA0`,
              position: "absolute",
              left: 0,
              height: "100%",
            }}
          ></div>
        )}
        <div className="mr-4">{icon}</div>
        <Typography className="text-base font-medium">{text}</Typography>
      </div>
    </div>
  );
}

export default function SideOptions({
  headerText,
  headerIcon,
  links,
  activeFilter,
}) {
  return (
    <div className="flex h-full flex-col border-r-2 pt-6 ">
      <div className="flex items-center justify-center px-6 pt-1">
        {headerIcon && <div className="mr-4">{headerIcon}</div>}
        <Typography className={clsx("text-xl font-medium")}>
          {headerText}
        </Typography>
      </div>
      <div className="mt-20">
        <div className="flex flex-col gap-8 mt-20">
          {links.map((link, key) => (
            <div>
              <Link
                key={key}
                text={link.text}
                icon={link.icon}
                onClick={link.onClick}
                active={activeFilter === link.text}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
