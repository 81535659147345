import Post from "@components/Post";
import { Typography } from "@mui/material";
import React from "react";
import ShowMore from "./ShowMore";

export default function PostsList({ posts, page, totalPages, onShowMore }) {
  return (
    <div>
      {posts?.length === 0 && (
        <Typography className="mt-2 text-center py-8">
          No posts found
        </Typography>
      )}
      {posts?.length > 0 && (
        <div>
          {posts?.map((item) => (
            <div key={item?.id} className=" border-b-2">
              <Post post={item} comment={true} />
            </div>
          ))}
          <ShowMore page={page} totalPages={totalPages} onClick={onShowMore} />
        </div>
      )}
    </div>
  );
}
