import axios from '@MyAxios';
import { updateToast } from '@helpers';
import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    expandedNotification: null,
  },
  reducers: {
    setExpandedNotification: (state, action) => {
      state.expandedNotification = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setExpandedNotification } = notificationsSlice.actions

export default notificationsSlice.reducer
