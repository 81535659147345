import React from 'react'
import MyAvatar from './admin/MyAvatar';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function GroupListingItem({ group, setSelectedGroup, isAdmin, showOptions }) {
  return (
    <div
      key={group?.id}
      className="flex mt-2 items-center mr-5"
    >
      <div className="w-full flex items-center justify-between">
        <div className='flex'>
          <Link to={`/group/info/${group.id}`}>
            <MyAvatar
              textProps={{ className: "font-bold" }}
              name={group?.name}
              subtext={`${group?.membersCount} group members`}
              className="z-0 w-14 h-14 mr-3"
              src={group?.icon?.avatarUrl}
            />
          </Link>
          {group.visibility === 'Private' && (
            <div className="flex flex-col justify-center">
              <div className="flex items-center">
                <Typography
                  className="ml-4 p-2 rounded-lg bg-[#e5f8f1]"
                  color="primary.main"
                >
                  Private
                </Typography>
              </div>
            </div>
          )}
        </div>

        {showOptions && (
          <MoreHorizIcon
            onClick={() => setSelectedGroup(group)}
            className="cursor-pointer"
            fontSize="large"
          />
        )}
      </div>
    </div>
  )
}

export default GroupListingItem;