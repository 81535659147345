import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  CardMedia,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import ChatBox from "../../components/ChatBox";
import ChatOptions from "../../components/ChatOptions";
import axios from "../../MyAxios";
import { useSelector } from "react-redux";
import back from "../../assets/img/back.svg";
import send from "../../assets/img/send.svg";
import cameraChat from "../../assets/img/cameraChat.svg";
import { GET_MESSAGES, SEND_MESSAGE } from "../../constants/api";
import Layout from "../../Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";
import io from "../../Socket";
import { toast } from "react-toastify";
import ShowMore from "@components/ShowMore";
import Loader from "@components/Loader";
import MyAvatar from "@components/admin/MyAvatar";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  bgGreen: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
}));

const Messages = ({
  height,
  receiverId,
  userRecord,
  messages,
  setMessages,
}) => {
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [messagesEndReached, setMessagesEndReached] = useState(false);
  const [page, setPage] = useState(1);

  const messagesTopRef = useRef(null);
  const messagesEndRef = useRef(null);
  const ignoreScrollRef = useRef(false);
  const bottomReachedRef = useRef(false);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      // console.log("scrolling to bottom");
      messagesEndRef.current?.scrollIntoView();
      bottomReachedRef.current = true;
    }
  };

  useEffect(() => {
    if (page === 1) scrollToBottom();
  }, [messages, page]);

  const getMessages = useCallback(async () => {
    try {
      // console.log("fetching messages");
      setMessagesLoading(true);
      const res = await axios.get(`${GET_MESSAGES}/${receiverId}/${page}`);
      if (res.data.messages.length === 0) setMessagesEndReached(true);
      setMessages((prevMessages) => [...res.data.messages, ...prevMessages]);
    } catch (error) {
    } finally {
      setMessagesLoading(false);
      ignoreScrollRef.current = false;
    }
  }, [page, receiverId, setMessages]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const onChatScroll = (e) => {
    if (
      messagesTopRef.current &&
      messagesTopRef.current.getBoundingClientRect().top > 0 &&
      !messagesLoading &&
      !messagesEndReached &&
      !ignoreScrollRef.current &&
      bottomReachedRef.current
    ) {
      // console.log("top reached");
      ignoreScrollRef.current = true;
      setPage((prev) => prev + 1);
    }
  };

  return (
    <div
      className=" flex flex-col overflow-y-auto px-5 py-3"
      style={{ height: height - 200 }}
      onScroll={onChatScroll}
    >
      {messagesLoading && <Loader />}
      <div ref={messagesTopRef} />
      {messages.map((msg, index) => (
        <div
          key={msg?.id}
          className={clsx(
            userRecord?.id === msg?.user?.id ? "flex-row-reverse" : "flex-row",
            "flex items-center"
          )}
        >
          <ChatBox
            key={index}
            sender={userRecord?.id === msg?.user?.id ? true : false}
            img={msg?.media}
            createdAt={msg?.createdAt}
          >
            {msg.message}
          </ChatBox>
          <ChatOptions msg={msg} />
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

function UserChat() {
  useLoggedIn();
  const classes = useStyles();
  const { receiverId } = useParams();
  const theme = useTheme();
  const navigation = useNavigate();
  const fileInput = useRef(null);
  const [messagesArr, setMessagesArr] = useState([]);
  const { userRecord } = useSelector((state) => state.user);
  const [roomId, setRoomId] = useState();
  const [receiverRec, setReceiverRec] = useState();
  const [height, setHeight] = useState(window.innerHeight);
  const [message, setMessage] = useState("");
  const [deletedMsg, setDeletedMsgId] = useState("");
  const [prevFile, setPrevFile] = useState();
  const [prevFileType, setPrevFileType] = useState();
  const [file, setFile] = useState("");
  const [sendMessageLoading, setSendMessageLoading] = useState(false);

  const sendMessage = async (e) => {
    setSendMessageLoading(true);
    e.preventDefault();
    if (!message && !file) {
      toast.error("Please type something  or upload a file!");
      setSendMessageLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("message", message);
    formData.append("roomId", roomId);
    formData.append("file", file);
    try {
      await axios.post(SEND_MESSAGE, formData).then((res) => {
        setSendMessageLoading(false);
        setMessage("");
        setFile(null);
      });
    } catch (error) {
      setSendMessageLoading(false);
    }
  };

  useEffect(() => {
    const getRoomId = async () => {
      try {
        const res = await axios.get(`/user/chat/${receiverId}`);
        setReceiverRec(res.data.receiverRec);
        setRoomId(res.data.roomId);
      } catch (error) {
        navigation("/chats");
      }
    };
    getRoomId();
  }, []);

  useEffect(() => {
    if (deletedMsg) {
      const updatedMessages = messagesArr.filter(
        (message) => message?.id !== deletedMsg
      );
      setMessagesArr(updatedMessages);
    }
  }, [deletedMsg]);

  // useEffect(() => {
  //   getMoreMessages();
  // }, [receiverId]);

  useEffect(() => {
    if (roomId) {
      io.socket.get(
        "/subscribe",
        { roomId },
        function serverResponded(body, JWR) {
          io.socket.on("message", function ({ id, message, user, media }) {
            setMessagesArr((prevMessages) => [
              ...prevMessages,
              { id, message, user, media },
            ]);
            setPrevFile("");
            setPrevFileType("");
          });
          io.socket.on("delete-message", function ({ messageId }) {
            setDeletedMsgId(messageId);
          });
        }
      );
    }
  }, [roomId]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
    });
  }, []);

  const onChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 3 * 1024 * 1024) {
        toast.error("File size should be less than 3MB");
        return;
      }
      setFile(e.target.files[0]);
      setPrevFile(URL.createObjectURL(e.target.files[0]));
      setPrevFileType(e.target.files[0].type.split("/")[0]);
    }
    if (e.target.value) e.target.value = null;
  };

  if (!receiverRec) return <Loader />;
  return (
    <Layout>
      <div
        className={clsx(
          classes.colorGreen,
          "shadow-black-100 flex justify-between py-2 shadow-md"
        )}
      >
        <div className=" ml-5 flex gap-3 items-center">
          <img
            onClick={() => navigation(-1)}
            className="h-5 w-5 cursor-pointer"
            src={back}
            alt="Back"
          />
          {receiverRec && (
            <MyAvatar
              src={receiverRec?.media?.avatarUrl}
              name={receiverRec?.userName}
              textProps={{ className: "font-semibold" }}
              subtext={receiverRec?.firstName + " " + receiverRec?.lastName}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <Messages
          userRecord={userRecord}
          height={height}
          receiverId={receiverId}
          messages={messagesArr}
          setMessages={setMessagesArr}
        />
        <div className="fixed bottom-0 flex flex-col w-full items-center justify-center md:w-6/12">
          {prevFile && (
            <div
              className={clsx(
                `my-2 flex flex-col relative rounded-xl p-6`,
                classes.bgGreen
              )}
            >
              <Typography
                color={"red"}
                onClick={() => {
                  setFile("");
                  setPrevFile("");
                  setPrevFileType("");
                }}
                className="cursor-pointer absolute top-3 left-3"
              >
                X
              </Typography>
              <div>
                {prevFileType === "video" ? (
                  <CardMedia
                    controlsList="nodownload"
                    crossOrigin="anonymous"
                    component={"video"}
                    controls
                    className="w-52 h-40 object-contain"
                    image={prevFile}
                    alt="Stream Media"
                    autoPlay
                    loop
                    muted
                  />
                ) : (
                  <div>
                    <img
                      src={prevFile}
                      className="w-52 h-40 object-contain"
                      alt="Camera Chat"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <form onSubmit={sendMessage} className="flex items-center p-4">
            <input
              className={clsx("hidden")}
              multiple={true}
              type="file"
              accept="image/*,video/*"
              ref={fileInput}
              onChange={(e) => onChange(e)}
            />
            <img
              src={cameraChat}
              alt="Camera Chat"
              onClick={() => fileInput.current.click()}
            />
            <TextField
              type="text"
              size="small"
              id="outlined-basic"
              fullWidth
              value={message}
              placeholder="Message"
              InputProps={{
                sx: {
                  borderRadius: 10,
                  borderColor: theme.palette.primary.main,
                  borderWidth: 1,
                },
              }}
              className="mx-2 w-72"
              onChange={(e) => setMessage(e.target.value)}
            />
            {sendMessageLoading ? (
              <CircularProgress />
            ) : (
              <button type="submit">
                <img src={send} alt="Send" />
              </button>
            )}
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default UserChat;
