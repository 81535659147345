import React from "react";
import Layout from "../../Layout";
import MyGroups from "../../components/MyGroups";
import TabPane from "../../components/TabPane";
import Title from "../../components/Title";
import {
  CREATE_GROUP,
  GET_GROUPS_BY_ADMIN,
  GET_GROUPS_BY_MODERATOR,
  GROUP_SELECT_COMMUNITY,
  ROOT,
} from "../../constants/api";
import useLoggedIn from "../../hooks/useLoggedIn";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import CreateGroupIcon from "@images/createGroup.svg";
function GroupManagement() {
  useLoggedIn();
  const tabs = ["My Groups", "Moderated Groups"];
  return (
    <Layout>
      <Title back={ROOT}>Group Management</Title>
      <TabPane tabs={tabs}>
        <div>
          <MyGroups noPending get_url={GET_GROUPS_BY_ADMIN} />
        </div>
        <div>
          <MyGroups noPending get_url={GET_GROUPS_BY_MODERATOR} />
        </div>
      </TabPane>
      <div className="fixed bottom-10 right-4">
        <Link to={GROUP_SELECT_COMMUNITY}>
          <Avatar
            className=" h-12 w-12 md:h-20 md:w-20"
            src={CreateGroupIcon}
          />
        </Link>
      </div>
    </Layout>
  );
}

export default GroupManagement;
