import { GET_COMMUNITIES } from "../constants/api";
import axios from "@MyAxios";
import React, { useEffect, useState } from "react";
import MyAvatar from "./admin/MyAvatar";
import { useNavigate } from "react-router-dom";

function MyCommunities({ get_url }) {
  const navigate = useNavigate();

  const [communities, setCommunities] = useState([]);

  const viewCommunity = (communityId) => {
    navigate(`/community/${communityId}`);
  }

  useEffect(() => {
    axios
      .get(get_url || GET_COMMUNITIES)
      .then((res) => setCommunities(res.data))
      .catch(() => {}, []);
  }, [setCommunities, get_url]);

  return (
    <div className="p-2 border-b-2 border-gray-100">
      {communities?.map((com, key) => (
        <div className="cursor-pointer mb-2" onClick={() => viewCommunity(com.id)}>
          <MyAvatar key={key} src={com?.icon?.avatarUrl} name={com.name} />
        </div>
      ))}
    </div>
  );
}

export default MyCommunities;
