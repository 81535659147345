import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.lightGreen.main,
    color: theme.palette.primary.main,
  },
}));

function PostButton({ children }) {
  const classes = useStyles();
  return (
    <div>
      <p
        className={clsx(
          classes.button,
          "rounded-3xl p-2 py-3 px-7 text-xs font-bold"
        )}
      >
        {children}
      </p>
    </div>
  );
}

export default PostButton;
