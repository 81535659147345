import React from 'react'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}))

function Button({ onClick, children }) {
  const classes = useStyles()
  return (
    <div>
      <button
        type="submit"
        onClick={onClick}
        className={clsx(
          classes.button,
          'mt-4 w-full rounded-md p-2 py-3 text-lg font-bold'
        )}
      >
        {children}
      </button>
    </div>
  )
}

export default Button
