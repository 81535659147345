import { useEffect, useState } from "react";
import axios from "../../MyAxios";
import {
  GROUP_RESOURCES,
  SAVE_RESOURCE,
  UNSAVE_RESOURCE,
} from "../../constants/api";
import { useNavigate } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";
import GroupModal from "@components/GroupModal";
import save from "@images/saveGreen.svg";
import share from "@images/shareGreen.svg";

import { toast } from "react-toastify";
import MediaResource from "@components/resource/MediaResource";
import EditIcon from "@images/edit.svg";
import DeleteIcon from "@images/delete.svg";
import TextResource from "@components/resource/TextResource";
import { Typography } from "@mui/material";

const Resources = ({ groupId, allowEdit, readOnly = false }) => {
  useLoggedIn();
  const navigation = useNavigate();
  const [resources, setResources] = useState([]);
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState(false);

  const getResources = async () => {
    try {
      const res = await axios.get(`${GROUP_RESOURCES}/${groupId}`);
      setResources(res.data);
    } catch (error) {}
  };
  const delteResource = async (id) => {
    try {
      await axios.post("/resource/delete", { id });
      toast.success("Resource Deleted Successfully");
      setOpen(false);
      setResources(resources.filter((r) => r.id !== id));
    } catch (error) {}
  };
  let options = [
    {
      id: 1,
      name: "Edit",
      img: EditIcon,
      action: () => {
        if (selected) navigation(`/group/resources/edit/${selected?.id}`);
      },
      show: allowEdit && !readOnly,
    },
    {
      id: 1,
      name: "Delete",
      img: DeleteIcon,
      action: () => {
        delteResource(selected?.id);
      },
      show: allowEdit && !readOnly,
    },
    {
      id: 1,
      name: "Share",
      img: share,
      action: () => {
        if (selected.isMedia) {
          navigator.share({
            title: selected?.title,
            url: selected?.media?.avatarUrl,
          });
        } else if (
          selected?.link.includes("http") ||
          selected?.link.includes("https") ||
          selected?.link.includes("www")
        ) {
          navigator.share({
            title: selected?.title,
            text: selected?.description,
            url: selected?.link.includes("http")
              ? selected?.link
              : `//${selected?.link}`,
          });
        } else {
          navigator.share({
            title: selected?.title,
            text: selected?.description,
          });
        }
      },
      show: Boolean(navigator?.share) && !readOnly,
    },
    {
      id: 2,
      name: "Save",
      img: save,
      show: true,
      action: async () => {
        try {
          await axios.post(SAVE_RESOURCE, { id: selected?.id });
          toast.success("Saved Successfully");
          setOpen(false);
        } catch (error) {}
      },
    },
    {
      id: 3,
      name: "Unsave",
      img: save,
      show: true,
      action: async () => {
        try {
          await axios.post(UNSAVE_RESOURCE, { id: selected?.id });
          toast.success("Unsaved Successfully");
          setOpen(false);
        } catch (error) {}
      },
    },
  ];

  useEffect(() => {
    getResources();
  }, [groupId]);

  options = options.filter((o) => o.show);
  return (
    <div className="px-4 pt-2 flex flex-col gap-4">
      <Typography className="font-semibold">
        Resources {"(" + resources.length + ")"}
      </Typography>
      {resources?.map((resource) => {
        if (resource?.isMedia)
          return (
            <MediaResource
              resource={resource}
              onMoreClick={() => {
                setSelected(resource);
                setOpen(true);
              }}
            />
          );
        else
          return (
            <TextResource
              resource={resource}
              onMoreClick={() => {
                setSelected(resource);
                setOpen(true);
              }}
            />
          );
      })}

      <GroupModal open={open} setOpen={setOpen} options={options} />
    </div>
  );
};

export default Resources;
