import Title from "../../components/Title";
import { useLocation } from "react-router-dom";
import Layout from "../../Layout";
import useLoggedIn from "../../hooks/useLoggedIn";
import TabPane from "@components/TabPane";
import AddMediaResource from "../../components/resource/AddMediaResource";
import AddTextResource from "@components/resource/AddTextResource";

const AddResources = (res) => {
  useLoggedIn();
  const { state } = useLocation();
  const { groupId } = state;

  const tabs = ["Media", "Text & Links"];

  return (
    <Layout>
      <Title>Add Resources</Title>
      <TabPane tabs={tabs}>
        <AddMediaResource groupId={groupId} />
        <AddTextResource groupId={groupId} />
      </TabPane>
    </Layout>
  );
};

export default AddResources;
