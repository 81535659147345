import { Select, outlinedInputClasses, selectClasses, svgIconClasses } from "@mui/material"
import { styled } from "@mui/styles"

export default styled(Select)(({ theme }) => ({
  borderRadius: '9999px !important',
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: 0
  },
  [`& .${selectClasses.iconOpen}`]: {
    transform: 'none'
  },
  [`& .${svgIconClasses.root}`]: {
    color: theme.palette.primary.main
  },
}));